<svelte:options tag="edi-app-bar-secondary-nav" />

<script lang="ts">
	import { readable } from 'svelte/store'
	import _ from 'lodash'
	import { writable } from 'svelte/store'
	import { APP_BAR_STATE_ITEMS } from './constants'
	import { injectable } from '../../common/injectable'
	import type { Item, ItemState } from './utils'
	import './AppBarMenuSearch.svelte'
	import { onMount, tick } from 'svelte'
	import dispatch from '../../common/dispatch'
	import { get_current_component } from 'svelte/internal'

	let component = get_current_component()

	let itemState: ItemState
	let content: HTMLDivElement
	let links: HTMLUListElement
	let width = 0
	let contentWidth = 0
	let scrollLeft = 0

	onMount(async () => {
		await tick()

		// event listener for when the element scrolls
		const scroll = () => (scrollLeft = links.scrollLeft)

		// watch links for scrolling
		links.addEventListener('scroll', scroll)

		// set the initial widths
		// this allows the proper arrows to show up on page load
		width = links.clientWidth
		contentWidth = content.offsetWidth

		return () => {
			links.removeEventListener('scroll', scroll)
		}
	})

	// injected states
	$: items = itemState?.items || writable<Item[]>([])
	$: selectedKey = itemState?.selectedKey || writable<any>()
	$: favoriteItem = itemState?.favoriteItem || readable<Item | null>(null)

	// show right arrow if content has overflown to the right
	$: showOverflowRight = width - contentWidth + scrollLeft < 0
	// show left arrow if user has scrolled
	$: showOverflowLeft = scrollLeft > 0

	/**
	 * handles clicking a trigger item
	 * @param item item that was clicked
	 */
	const handleTriggerClicked = (item: Item) => () => {
		selectedKey?.set(item.key)
		if (item?.isNewMTSSEnabled) {
			dispatch(component, 'edi-app-bar:navigate_mtss')
		}
	}
</script>

<div
	use:injectable={APP_BAR_STATE_ITEMS}
	on:injected={({ detail: { instance } }) => (itemState = instance)}
	class="secondary-navigation"
>
	<div class="secondary-navigation__links-wrapper">
		<div class="overflow overflow--left" class:overflow--show={showOverflowLeft}>
			<mwc-icon>arrow_back_ios</mwc-icon>
		</div>

		<ul bind:this={links} bind:offsetWidth={width} class="secondary-navigation__links">
			<div bind:this={content} bind:offsetWidth={contentWidth} class="content">
				{#if $favoriteItem}
					<edi-app-bar-navigation-trigger
						label={$favoriteItem.label}
						active={_.isEqual($favoriteItem.key, $selectedKey)}
						on:click={handleTriggerClicked($favoriteItem)}
					/>
				{/if}

				{#each $items as item}
					<edi-app-bar-navigation-trigger
						on:click={handleTriggerClicked(item)}
						active={_.isEqual(item.key, $selectedKey)}
						label={item.label}
					/>
				{/each}
			</div>
		</ul>

		<div class="overflow overflow--right" class:overflow--show={showOverflowRight}>
			<mwc-icon>arrow_forward_ios</mwc-icon>
		</div>
	</div>

	<edi-app-bar-menu-search on:edi-app-bar:navigated class="secondary-navigation__search" />
</div>

<style lang="scss">
	* {
		box-sizing: border-box;
	}

	.content {
		display: flex;
		padding: 0 50px 0 0;
	}

	.overflow {
		display: flex;
		align-items: center;
		height: 50px; // hard-coded height on purpose
		position: absolute;
		width: 50px;
		top: 0;
		z-index: 50;
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.25s ease-in;
		font-size: 16px;

		mwc-icon {
			font-size: 16px;
		}

		&--show {
			transition: opacity 0.25s ease-out;
			visibility: visible;
			opacity: 1;
		}

		&--left {
			left: 0;
			justify-content: flex-start;
			background: linear-gradient(90deg, #ffffff 25.58%, rgba(255, 255, 255, 0) 80.33%);
		}

		&--right {
			right: 0;
			justify-content: flex-end;
			background: linear-gradient(270deg, #ffffff 25.58%, rgba(255, 255, 255, 0) 80.33%);
		}
	}

	.secondary-navigation {
		display: flex;
		align-items: center;
		background: #fff;

		&__links-wrapper {
			position: relative;
			flex-grow: 1;
			width: 100%;
			overflow: hidden;
			margin: 0 0.5rem;
		}

		&__links {
			scroll-snap-type: x mandatory;
			position: relative;
			overflow-x: auto;
			overflow-y: hidden;
			padding: 0;
			margin: 0;
			max-width: 100%;
			list-style-type: none;
			display: flex;
			white-space: nowrap;

			edi-navigation-trigger {
				scroll-snap-align: start;
			}
		}
	}

	::-webkit-scrollbar {
		height: 7px;
	}

	::-webkit-scrollbar-thumb {
		background: var(--edi-gray-light);
		border-radius: 7px;
		position: relative;

		&:hover {
			background: var(--edi-gray-medium);
		}
	}
</style>
