<svelte:options tag="edi-account-type-icon" />

<script lang="ts">
	export let is_primary: boolean = false
</script>

{#if is_primary}
	<div>
		<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M1.41671 2.75855L6.41671 0.883551C6.79171 0.741884 7.20837 0.741884 7.58337 0.891884L12.5834 2.76688C12.901 2.88557 13.1749 3.09835 13.3685 3.37679C13.5621 3.65523 13.6661 3.9861 13.6667 4.32522V8.24188C13.6667 12.2752 11.0584 16.0502 7.47504 17.2002C7.16671 17.3002 6.83337 17.3002 6.52504 17.2002C2.94171 16.0502 0.333374 12.2669 0.333374 8.24188V4.32522C0.333374 3.62522 0.766707 3.00855 1.41671 2.75855ZM6.53104 11.9372C6.57904 11.9792 6.63304 12.0002 6.69304 12.0002H7.86304C7.92904 12.0002 7.98304 11.9792 8.02504 11.9372C8.06704 11.8952 8.08804 11.8412 8.08804 11.7752V5.92522C8.08804 5.85922 8.06704 5.80522 8.02504 5.76322C7.98304 5.72122 7.92904 5.70022 7.86304 5.70022H6.73804C6.65404 5.70022 6.58204 5.72122 6.52204 5.76322L4.45204 7.36522C4.39204 7.40122 4.35604 7.45222 4.34404 7.51822C4.33804 7.57822 4.35304 7.63522 4.38904 7.68922L4.92004 8.38222C4.96204 8.43622 5.01004 8.46922 5.06404 8.48122C5.12404 8.49322 5.18104 8.47822 5.23504 8.43622L6.46804 7.49122V11.7752C6.46804 11.8412 6.48904 11.8952 6.53104 11.9372Z" fill="#89CAF5"/>
		</svg>
	</div>
{:else}
	<div>
		<svg class="icon" width="14" height="18" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M15.5 9.16634C15.5 13.7913 12.3 18.1163 8 19.1663C3.7 18.1163 0.5 13.7913 0.5 9.16634V4.16634L8 0.833008L15.5 4.16634V9.16634ZM8 17.4997C11.125 16.6663 13.8333 12.9497 13.8333 9.34967V5.24967L8 2.64967L2.16667 5.24967V9.34967C2.16667 12.9497 4.875 16.6663 8 17.4997Z" fill="#C9CED4"/>
		</svg>
		<span>2</span>
	</div>
{/if}


<style lang="scss">
	span {
		font-size: .5rem;
	}

	svg {
		position: absolute;
		top: 0;
		left: 0;
	}

	div {
		font-family: Roboto;
		width: 14px;
		height: 18px;
		position: relative;
		display: inline-flex;
		align-items: center;
		justify-content: center;
	}
</style>
