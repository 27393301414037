<svelte:options tag="edi-login-option" />

<script lang="ts">
	import type Option from './dto/LoginOption'

	export let value: string
	export let data: Option
	export let selected: boolean
</script>

<div class:selected>
	{#if data?.title}
		<span class="title">
			{data.title}
		</span>
	{/if}
</div>

<style lang="scss">
	@import '../../styles/variables';
	@import '@material/elevation/mdc-elevation';

	.selected {
		background: var(--edi-dark-gray);
		color: white;
	}

	.icon {
		margin-right: 0.5rem;
	}

	.title {
		font-weight: bold;
	}

	div {
		display: flex;
		align-items: center;
		color: #c5ccd3;
		padding: 0.5rem;
		font-size: 14px;
		border-radius: 4px;
		white-space: nowrap;
		letter-spacing: -0.005em;
		text-transform: capitalize;
		overflow-x: hidden;
		text-overflow: ellipsis;

		&:hover {
			@extend .selected;
			cursor: pointer;
		}
	}
</style>
