<svelte:options tag="edi-rti-summary" />

<script lang="ts">
	export let items = []

	export let total: any
</script>

<div class="container">
	<table>
		<tr id="heading">
			<th>Tier</th>
			<th>
				<table>
					<tr>
						<td style="width: 50%;">Area of Need</td>
						<td style="width: 25%;"># of Students</td>
						<td style="width: 25%;">% of Population</td>
					</tr>
				</table>
			</th>
		</tr>
		{#if items.length > 0}
			{#each items as data}
				<tr id="data">
					<td>{data?.tier}</td>
					<td>
						<table>
							{#each data?.innercont as inner}
								<tr id="inner">
									<td style="width: 50%;">{inner?.area}</td>
									<td style="width: 25%;">
										<a href={inner?.link}>{inner?.students}</a>
									</td>
									<td style="width: 25%;">{inner?.population}</td>
								</tr>
							{/each}
							<tr id="tier_total">
								<td style="width: 50%;">{data?.tier_total?.area}</td>
								<td style="width: 25%;">
									<a href={data?.tier_total?.link}>{data?.tier_total?.students}</a>
								</td>
								<td style="width: 25%;">{data?.tier_total?.population}</td>
							</tr>
						</table>
					</td>
				</tr>
			{/each}
			<tr>
				<td />
				<td>
					<table>
						<tr id="total">
							<td style="width: 50%;">{total?.area}</td>
							<td style="width: 25%;">
								<a href={total?.link}>{total?.students}</a>
							</td>
							<td style="width: 25%;">{total?.population}</td>
						</tr>
					</table>
				</td>
			</tr>
		{/if}
	</table>

	{#if items.length === 0}
		<div class="default">No Data Available</div>
	{/if}
</div>

<style lang="scss">
	.container {
		padding: 10px 10px 10px 10px;
	}

	table {
		width: 100%;
		font-family: 'Roboto';
		border-collapse: collapse;
	}

	table tr td {
		border-bottom: 1px solid #f3f3f3;
	}

	table table tr td {
		border-bottom: none;
	}

	table th:first-child {
		border-radius: 7px 0 0 7px;
	}

	table th:last-child {
		border-radius: 0 7px 7px 0;
	}

	td,
	th {
		text-align: center;
	}

	th {
		font-weight: bold;
		font-size: 15px;
		padding-top: 5px;
		padding-bottom: 5px;
		background: #f5f5f5;
	}

	#data > td,
	#inner > td,
	#tier_total > td,
	#total > td {
		padding-top: 7px;
		padding-bottom: 7px;
		font-size: 14px;
	}

	#tier_total > td,
	#total > td {
		font-weight: 600;
	}

	a {
		text-decoration: none;
		color: #2757f2;
	}

	a:hover {
		color: #017698;
	}

	.default {
		text-align: center;
		font-family: 'Roboto';
		font-size: 15px;
	}
</style>
