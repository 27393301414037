<svelte:options tag="edi-todays-lesson" />

<script lang="ts">
	import dispatch from '../../../common/dispatch'
	import { get_current_component } from 'svelte/internal'
	import dayjs from 'dayjs'

	const component = get_current_component()

	$: currentDate = new Date()

	export let id: any
	export let user_id: any
	export let title: any
	export let griddata: any = []

	// Dropdown prop
	export let timeslots: any = []

	export let showtimeline: boolean

	let selected: any

	let now = new Date()

	let dateString = new Date().toDateString()

	export let format = 'YYYY-MM-DD'

	let internal

	export let scheduler1: any = []
	export let scheduler2: any = []

	export let current_container: any

	// Field to hold values of dropdown details for the api side
	let selectedDropdown: string
	let ddlList: any[] = []

	const input = (x) => (internal = dayjs(x).format(format))
	const output = (x) => (now = dayjs(x, format).toDate())

	$: input(now)
	$: output(internal)

	document.addEventListener('todayslessondatachanged', (e: any) => {
		griddata = e.detail.datachanged

		scheduler1 = e?.detail?.scheduler1 ? e?.detail?.scheduler1 : []
		scheduler2 = e?.detail?.scheduler2 ? e?.detail?.scheduler2 : []
	})

	const handlechange = (value) => {
		timeslots.forEach((item) => {
			if (item.value === value) {
				selectedDropdown = item.value
			}
		})

		ddlList = []

		ddlList = [...ddlList, { selectedDropdown }]

		dispatch(component, 'edi-todays-lesson:dropdownchange', {
			id,
			user_id,
			widgettype: 'grid',
			widgettitle: title,
			event: 'slotchange',
			selected,
			selectedDate: new Date(now).toDateString(),
			ddlList,
			currentContainer: current_container,
			showTimeLine: showtimeline,
		})
	}

	const toggleWidgetView = () => {
		showtimeline = !showtimeline

		ddlList = []

		ddlList = [...ddlList, { selectedDropdown: selected }]

		dispatch(component, 'edi-todays-lesson:viewchange', {
			id,
			user_id,
			widgettype: 'grid',
			widgettitle: title,
			selectedDate: dateString,
			ddlList,
			currentContainer: current_container,
			showTimeLine: showtimeline,
		})
	}

	const handleDateChange = (isDatePick = false) => {
		if (isDatePick) {
			now = dayjs(internal, format).toDate()
		}

		dateString = new Date(now).toDateString()

		ddlList = []

		ddlList = [...ddlList, { selectedDropdown: selected }]

		dispatch(component, 'edi-todays-lesson:datechange', {
			id,
			user_id,
			widgettype: 'grid',
			widgettitle: title,
			event: 'datechange',
			selectedDate: dateString,
			ddlList,
			currentContainer: current_container,
			showTimeLine: showtimeline,
		})
	}

	const gotoPrev = () => {
		now.setDate(now.getDate() - 1)
		now = new Date(now)
		handleDateChange()
	}

	const gotoNext = () => {
		now.setDate(now.getDate() + 1)
		now = new Date(now)
		handleDateChange()
	}

	const gotoToday = () => {
		now = new Date()
		handleDateChange()
	}

	const handleClick = (link: any) => {
		if (link !== '') {
			window.location.href = link
		}
	}
</script>

<div class="select">
	<span>Schedule Time Slot Configuration :</span>
	<select bind:value={selected} on:change={() => handlechange(selected)}>
		{#each timeslots as item}
			<option selected={item?.selected} value={item?.value}>
				{item?.value}
			</option>
		{/each}
	</select>
</div>

<div class="container">
	<div class="date-picker">
		<div style="margin-left: 3px; margin-top: 3px;">
			<button id="btn-date-nav" style="float: left;" on:click={gotoPrev}>
				<edi-svg-left-fill width="16" height="16" />
			</button>
			<button id="btn-date-nav" style="float: left;" on:click={gotoNext}>
				<edi-svg-right-fill width="16" height="16" />
			</button>
			<button id="btn-date-nav" style="float: left;" on:click={gotoToday}>Today</button>
		</div>
		<input type="date" bind:value={internal} on:change={() => handleDateChange(true)} />
	</div>

	<br />

	<span class="toggle-section">
		<button
			style="float: right; margin-right: 3px;"
			class="btn-toggle"
			disabled={!showtimeline}
			on:click={toggleWidgetView}>Agenda</button
		>
		<button style="float: right;" class="btn-toggle" disabled={showtimeline} on:click={toggleWidgetView}
			>TimeLine</button
		>
	</span>

	<div class="grid-container">
		<div class="dateString">{dateString}</div>
		{#if scheduler1.length > 0}
			{#if showtimeline && scheduler1.length > 0}
				<div>
					<table>
						{#each scheduler1 as schedulerLeft}
							<tr>
								<td id="data-div" style="background: #F0F0F0;">
									{schedulerLeft?.period_desc}
								</td>
								<td>
									{#if scheduler2.length > 0}
										<table>
											{#each scheduler2 as schedulerRight}
												{#if schedulerRight?.period_desc === schedulerLeft?.period_desc}
													<tr id="inner" style="background: white;">
														<td style="background-color: {schedulerRight?.color} ;">
															<span
																style="float: left; cursor: pointer;"
																on:click={gotoPrev}
															>
																<edi-svg-left-fill width="10" height="10" />
															</span>
															<span
																style="cursor: {schedulerRight?.link !== ''
																	? 'pointer'
																	: 'default'};"
																on:click={() => handleClick(schedulerRight?.link)}
															>
																{schedulerRight?.lesson_name}
															</span>
															<span
																style="float: right; cursor: pointer;"
																on:click={gotoNext}
															>
																<edi-svg-right-fill width="10" height="10" />
															</span>
														</td>
													</tr>
												{/if}
											{/each}
										</table>
									{:else}
										<div style="margin-left: 50px;" />
									{/if}
								</td>
							</tr>
						{/each}
					</table>
				</div>
			{:else if !showtimeline && scheduler1.length > 0}
				<div>
					{#each scheduler1 as schedulerLeft}
						<div class="agenda-left">
							<span>{schedulerLeft?.period_desc}</span>
							{#each scheduler2 as schedulerRight}
								<div class="agenda-right">
									{#if schedulerRight?.period_desc === schedulerLeft?.period_desc}
										<span
											style="cursor: {schedulerRight?.link !== '' ? 'pointer' : 'default'};"
											on:click={() => handleClick(schedulerRight?.link)}
										>
											{schedulerRight?.lesson_name}
										</span>
									{/if}
								</div>
							{/each}
						</div>
					{/each}
				</div>
			{/if}
		{:else}
			<div class="default">No Data Available</div>
		{/if}
	</div>
</div>

<style lang="scss">
	select {
		height: 20px;
		background: #f5f5f5;
		border: none;
		border-radius: 5px;
		border-color: #f5f5f5;

		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
	}
	.select {
		text-align: center;

		font-family: 'Roboto';
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		line-height: 19px;
	}

	.container {
		margin: 10px 3px 3px 10px;
		border: 1px solid black;
		border-color: #aabfd6;
	}

	.btn-toggle {
		height: 25px;
		cursor: pointer;
		margin-left: 2px;
		font-family: 'Roboto';
		color: black;
		border: none;
		text-align: center;
		font-size: 13px;
	}

	button:disabled {
		cursor: not-allowed;
		pointer-events: all !important;
	}

	.btn-toggle:focus {
		border: 2px solid #aabfd6;
	}

	.data-agenda {
		grid-area: data;
		display: grid;
		grid-template-columns: 2fr 4fr;
		grid-gap: 1px;
		padding-left: 10px;
	}

	.grid-container {
		width: 100%;
		display: grid;
		grid-template-areas:
			'heading'
			'data';
		grid-gap: 1px;

		.dateString {
			padding: 3px;
			margin-top: 2px;
			background: whitesmoke;
		}

		.default {
			text-align: center;
			font-family: 'Roboto';
			font-size: 15px;
			padding: 15px 0px;
		}
	}

	.grid-container > div {
		text-align: center;
		padding: 1px;
		font-family: 'Roboto';
		font-size: 15px;
	}

	.lesson-btn {
		border: none;
		padding-left: 1px;
		padding-right: 1px;
		width: 100%;
		text-align: left;
	}

	#data-div {
		padding: 5px 0px 5px 0px;
	}

	.date-picker {
		& button {
			font-family: 'Roboto';
			background-color: #e7e7e7;
			color: black;
			border: none;
			padding: 3px 5px;
			margin-right: 2px;
			text-align: center;
			font-size: 13px;
		}

		input {
			border: 0;
			padding: 6px;
			margin-left: 10px;
			font-family: 'Roboto';
			font-size: 13px;
		}
	}

	#btn-date-nav {
		height: 25px;
		cursor: pointer;
	}

	table {
		width: 100%;
		font-family: 'Roboto';
		border-collapse: collapse;
	}

	tr {
		border-bottom: 1px solid white;
	}

	#inner > td {
		padding: 5px 0px;
		border-radius: 5px;
	}

	#table-agenda-inner > tr {
		text-align: left;
	}

	.agenda-left {
		text-align: left;
		padding-top: 5px;
	}

	.agenda-right {
		padding-left: 15%;
	}

	a {
		text-decoration: none;
		color: rgba(79, 165, 236, 0.65);
	}
</style>
