<svelte:options tag="edi-sdd-menu" />

<script lang="ts">
	import { onMount } from 'svelte'
	import { get_current_component } from 'svelte/internal'

	import { handleRemoveFavorite, sortFolders, handleClose } from '../utils'

	/* --- Props Starts --- */

	export let current_folders: any = []
	export let favorites: any = []

	export let sdd_config_params: any // For the button to navigate to the config page

	/* --- Props Ends --- */

	/* --- Variables Starts --- */

	const component = get_current_component()

	let showSubmenuAndAssessments = false

	let folder_id: any
	let subfolders: any = []
	let assessments: any = []

	let sortOption = 'Name A-Z'
	let sortOptions = ['Name A-Z', 'Name Z-A', 'Created Date', 'Last Updated']

	let folders_names = []
	let selected_folder = ''

	let current_folders_temp = []
	let subfolders_temp = []

	let searchKey = ''

	let is_all_assessments = false // Flag to identify whether is the 'All Assessments' tile is clicked
	let allAssessments: any[] = []

	let filteredFavorites = []

	/* --- Variables Ends --- */

	/* --- Methods Starts --- */

	onMount(() => {
		current_folders = sortFolders(current_folders, sortOption)

		current_folders_temp = current_folders

		// createAllAssessmentsList(current_folders)
		// createAllAvailableAssessmentsList(current_folders)
	})

	$: if (favorites) {
		favorites = filterFavoritesList(favorites)
	}

	$: if (current_folders?.length > 0) {
		createAllAvailableAssessmentsList(current_folders)
	}

	// Creating a list of available assessment details from the 'current_folders' list
	const createAllAvailableAssessmentsList = (currentFolders) => {
		allAssessments = []
		if (currentFolders[0].assessments) {
			currentFolders[0].assessments.availableAssessments.forEach((assessment: any) => {
				const favoriteAssessment = favorites.find(
					(favorite) => favorite.assessmentId === assessment?.assessmentId
				)
				const isFavorite = favoriteAssessment ? favoriteAssessment.isFavorite : false

				allAssessments.push({
					assessmentId: assessment?.assessmentId,
					assessmentName: assessment?.assessmentName,
					isFavorite: isFavorite,
					folderId: currentFolders[0]?.folderId,
					subfolderId: undefined,
					link: assessment?.link,
				})
			})
		}
	}

	const handleSort = () => {
		current_folders = sortFolders(current_folders, sortOption)

		subfolders = sortFolders(subfolders, sortOption)
	}

	// Method to show all the added assessmets for the user
	const handleAllAssessmentsSelect = () => {
		showSubmenuAndAssessments = true
		is_all_assessments = true // All Assessment tile is selected from SDD menu
		subfolders = []
		assessments = allAssessments ?? []

		searchKey = ''

		folders_names = current_folders
			.filter((folder) => !folder?.isArchived && folder?.folderName)
			.map((folder) => folder?.folderName)

		selected_folder = 'All'
	}

	const folderSelected = (folderDetails: any) => {
		showSubmenuAndAssessments = true
		is_all_assessments = false
		searchKey = ''
		folder_id = folderDetails?.folderId
		subfolders = folderDetails?.subFolders ? sortFolders(folderDetails?.subFolders, sortOption) : []
		assessments = folderDetails?.assessments?.selectedAssessments ?? []

		subfolders_temp = subfolders
		folders_names = current_folders.filter((folder) => !folder?.isArchived).map((folder) => folder?.folderName)

		selected_folder = folderDetails?.folderName
	}

	const handleBack = (e: CustomEvent) => {
		searchKey = ''
		if (e?.detail?.redirectToMainMenu) {
			showSubmenuAndAssessments = false
			subfolders = []
			searchKey = ''
		}
	}

	/* Recieving event from server side to update subfolders_temp array when the folder name dropdown changes. */
	document.addEventListener('update_selected_folder', (e: any) => {
		subfolders = e?.detail?.subFolders ?? []

		subfolders_temp = subfolders
	})

	const handleSearch = () => {
		filteredFolders()
	}

	$: filteredFolders = () => {
		if (!showSubmenuAndAssessments && subfolders?.length === 0) {
			return current_folders?.filter((folder) => {
				return (
					folder?.folderName?.toLowerCase()?.includes(searchKey?.toLowerCase()) ||
					folder?.assessments?.selectedAssessments?.some((assessment) =>
						assessment?.assessmentName?.toLowerCase()?.includes(searchKey?.toLowerCase())
					)
				)
			})
		} else {
			return subfolders?.filter((folder) => {
				return (
					folder?.folderName?.toLowerCase()?.includes(searchKey?.toLowerCase()) ||
					folder?.assessments?.selectedAssessments?.some((assessment) =>
						assessment?.assessmentName?.toLowerCase()?.includes(searchKey?.toLowerCase())
					)
				)
			})
		}
	}

	// Method to  handle folder selection(dropdown/bread crumbs)
	const handleFolderSelect = (e: CustomEvent) => {
		let selectedFolderDetails: any = []
		let subFolders: any = []
		let assessments: any = []
		let folder_id
		let subfolder_id

		if (e?.detail?.selected_folder !== '' && e?.detail?.selected_subfolder === '') {
			if (e?.detail?.selected_folder === 'All') {
				subFolders = []
				assessments = allAssessments

				sortOptions = ['Name A-Z', 'Name Z-A']
			} else {
				selectedFolderDetails = current_folders?.filter(
					(folder) => folder?.folderName === e?.detail?.selected_folder
				)
				subFolders = selectedFolderDetails[0]?.subFolders
				assessments = selectedFolderDetails[0]?.assessments?.selectedAssessments

				folder_id = selectedFolderDetails[0].folderId
				subfolder_id = ''

				sortOptions = ['Name A-Z', 'Name Z-A', 'Created Date', 'Last Updated']
			}
		}

		if (e?.detail?.selected_folder !== '' && e?.detail?.selected_subfolder !== '') {
			selectedFolderDetails = current_folders?.filter(
				(folder) => folder?.folderName === e?.detail?.selected_folder
			)
			let subFoldersFiltered = selectedFolderDetails[0]?.subFolders?.filter(
				(subFolder) => subFolder?.folderName === e?.detail?.selected_subfolder
			)
			assessments = subFoldersFiltered[0]?.assessments?.selectedAssessments

			folder_id = selectedFolderDetails[0].folderId
			subfolder_id = subFoldersFiltered[0].folderId
		}

		const event = new CustomEvent('update_selected_folder', {
			detail: {
				subFolders,
				assessments,
				folder_id,
				subfolder_id,
			},
		})
		document.dispatchEvent(event)
	}

	// Method to handle click event on assessment name
	const handleClick = (details: any) => () => {
		window.location = details?.link
	}

	// Method to filter favorite list with unique assessmentId
	const filterFavoritesList = (favorites: any) => {
		filteredFavorites = []
		let uniqueAssessmentIds = new Set()

		favorites?.forEach((item) => {
			if (!uniqueAssessmentIds.has(item?.assessmentId)) {
				filteredFavorites.push(item)
				uniqueAssessmentIds.add(item?.assessmentId)
			}
		})

		return filteredFavorites
	}

	$: if (!is_all_assessments) {
		sortOptions = ['Name A-Z', 'Name Z-A', 'Created Date', 'Last Updated']
	} else {
		sortOptions = ['Name A-Z', 'Name Z-A']
	}

	/* --- Methods Ends --- */
</script>

<div class="main-menu">
	<div on:click={handleClose(component)} class="close">
		<mwc-icon>close</mwc-icon>
	</div>
	<div class="header">
		<h3>Student Data Depot</h3>

		<div class="header-right">
			<span>
				<label for="">Sort By</label>

				<select bind:value={sortOption} on:change={() => handleSort()}>
					{#each sortOptions as option}
						<option value={option}>
							{option}
						</option>
					{/each}
				</select>
			</span>

			<span class="search">
				<input
					type="text"
					placeholder="Search Student Data Depot"
					bind:value={searchKey}
					on:input={handleSearch}
				/>
			</span>
		</div>
	</div>
	<div class="content">
		<div class="favorites">
			<h4><edi-sdd-favorite-star variant={'filled'} /> Favorites</h4>

			{#each favorites as item}
				<div on:click={handleClick(item)}>
					{item?.assessmentName}
					<span>
						<edi-sdd-favorite-star
							on:click|stopPropagation={handleRemoveFavorite(component, item)}
							class="star"
							variant={'filled'}
						/>
					</span>
				</div>
			{/each}
		</div>

		<div class="folders">
			{#if !showSubmenuAndAssessments}
				<h3>Folders</h3>

				<div class="folders-in">
					<div class="menu-box" on:click={() => handleAllAssessmentsSelect()}>
						<span><edi-svg-all /></span>
						<strong>
							<h4>All</h4>
						</strong>

						All Assessments
					</div>

					{#each filteredFolders() as folder}
						{#if !folder?.isArchived}
							<div class="menu-box" on:click={() => folderSelected(folder)}>
								<span><edi-icon-picker icon_name={folder?.folderIcon} /></span>
								<strong>
									<h4>
										{#if folder?.folderName.length > 15}
											{folder?.folderName.substring(0, 15) + '...'}
										{:else}
											{folder?.folderName}
										{/if}
									</h4>
								</strong>
								{#if folder?.folderDescription}
									{#if folder?.folderDescription.length > 20}
										{folder?.folderDescription.substring(0, 20) + '...'}
									{:else}
										{folder?.folderDescription}
									{/if}
								{/if}
							</div>
						{/if}
					{/each}
				</div>
			{:else}
				<edi-sdd-submenu
					{folder_id}
					subfolders={filteredFolders()}
					{assessments}
					{folders_names}
					{selected_folder}
					{is_all_assessments}
					search_key={searchKey}
					sort_option={sortOption}
					on:edi-sdd-submenu:handle_back={handleBack}
					on:edi-sdd-submenu:handlefolderselect={handleFolderSelect}
				/>
			{/if}
		</div>
	</div>
	{#if sdd_config_params?.isEnabled}
		<div class="config">
			<button on:click={handleClick(sdd_config_params)}>Student Data Depot Configuration</button>
		</div>
	{/if}
</div>

<style lang="scss">
	@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');

	* {
		box-sizing: border-box;
	}
	.main-menu {
		font-family: 'Poppins', sans-serif;
		font-size: 15px;
		padding: 30px;
		background: linear-gradient(180deg, #e9eef5 0%, #ffffff 74.75%);
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

		position: absolute;
		z-index: 99;
		width: 100%;
	}

	.close {
		display: inline-flex;
		cursor: pointer;
		border-radius: 50%;
		padding: 0.25rem;
		float: right;

		&:hover {
			background: var(--edi-gray-light);
		}
	}
	.header {
		display: flex;
		flex-wrap: wrap;
		gap: 15px;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #b9c3ca;
		padding-bottom: 20px;
		margin-bottom: 25px;
	}
	.header h3 {
		font-size: 22px;
		font-weight: 700;
		color: #263b54;
		margin: 0;
	}
	.header-right {
		display: flex;
		align-items: center;
		gap: 15px;
	}
	.header-right label {
		font-size: 15px;
		font-weight: 400;
		color: #435765;
		margin: 0 10px 0 0;
	}
	.header-right input,
	.header-right select {
		width: 160px;
		height: 45px;
		padding: 10px;
		font-size: 15px;
		background: rgba(255, 255, 255, 0.8);
		border-radius: 7px;
		color: #435765;
		border: none;
		outline: none;
	}
	.search {
		position: relative;
	}
	.search img {
		position: absolute;
		top: 14px;
		left: 12px;
		width: 16px;
	}
	.header-right input {
		width: 300px;
		padding-left: 40px;
	}
	.content {
		display: flex;
		align-items: flex-start;
	}
	.favorites {
		width: 280px;
		flex-shrink: 0;
		padding: 20px;
		background: #ffffff;
		box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.03);
		border-radius: 10px;
	}
	.favorites h4 {
		font-size: 17px;
		font-weight: 700;
		color: #317fb4;
		margin: 0 0 10px 0;
		padding-bottom: 10px;
		border-bottom: 1px solid #e9eef5;
		display: flex;
	}

	.favorites > div {
		font-size: 15px;
		font-weight: 400;
		color: #435765;
		position: relative;
		padding: 6px 25px 6px 0;
		-webkit-transition: 0.4s;
		-moz-transition: 0.4s;
		transition: 0.4s;
	}
	.favorites > div:hover {
		color: #317fb4;
		cursor: pointer;
	}
	.favorites > div span {
		position: absolute;
		top: 4px;
		right: 0;
		width: 12px;
		opacity: 0;
		-webkit-transition: 0.4s;
		-moz-transition: 0.4s;
		transition: 0.4s;
	}

	.favorites > div:hover span {
		opacity: 1;
	}
	.folders {
		padding-left: 30px;
		width: 100%;
	}
	.folders h3 {
		font-weight: 700;
		font-size: 17px;
		line-height: 22px;
		color: #263b54;
		margin: 0 0 15px 0;
	}
	.folders-in {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: 15px;
		height: 250px;
		overflow-y: auto;
	}
	.menu-box {
		cursor: pointer;
		border: 1px solid #e2e9f3;
		border-radius: 10px;
		padding: 15px;
		font-size: 13px;
		line-height: 24px;
		color: #727c89;
		display: flex;
		text-align: center;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		-webkit-transition: 0.4s;
		-moz-transition: 0.4s;
		transition: 0.4s;
	}
	.menu-box:hover {
		border-color: #317fb4;
	}
	.menu-box h4 {
		display: block;
		color: #263b54;
		font-size: 15px;
		font-weight: 500;
		margin: 0 0 5px 0;
		-webkit-transition: 0.4s;
		-moz-transition: 0.4s;
		transition: 0.4s;
	}
	.menu-box:hover h4 {
		color: #317fb4;
	}
	.menu-box span {
		background: rgba(226, 233, 243, 0.6);
		border-radius: 5px;
		width: 40px;
		height: 40px;
		font-size: 20px;
		margin-bottom: 10px;
		color: #a2b7d4;
		padding: 12px;
		display: flex;
		justify-content: center;
		align-items: center;
		-webkit-transition: 0.4s;
		-moz-transition: 0.4s;
		transition: 0.4s;
	}
	.menu-box span img {
		width: 100%;
	}
	.menu-box:hover span {
		background: #317fb4;
	}
	.menu-box:hover span img {
		filter: brightness(0) invert(1);
	}

	.config {
		float: right;
		margin-top: 15px;
	}

	.config > button {
		cursor: pointer;
		font-family: Roboto;
		font-size: 15px;
		font-weight: 400;
		color: #707070;

		width: 240px;
		height: 40px;
		border: none;
		border-radius: 50px;
	}

	@media (max-width: 1400px) {
		.folders-in {
			grid-template-columns: repeat(4, 1fr);
		}
	}
	@media (max-width: 1200px) {
		.folders-in {
			grid-template-columns: repeat(3, 1fr);
		}
	}
	@media (max-width: 992px) {
		.folders-in {
			grid-template-columns: repeat(2, 1fr);
		}
		.favorites {
			width: 240px;
		}
	}
	@media (max-width: 768px) {
		.content {
			flex-direction: column;
		}
		.favorites {
			width: 100%;
		}
		.folders {
			padding: 30px 0 0 0;
		}
		.header-right select {
			width: 140px;
		}
		.header-right input {
			width: 260px;
		}
	}
	@media (max-width: 576px) {
		.header,
		.header-right {
			width: 100%;
			flex-direction: column;
			align-items: flex-start;
		}
		.header-right label {
			margin: 0 0 10px 0;
			display: block;
		}
		.header-right span {
			width: 100%;
		}
		.header-right select,
		.header-right input {
			width: 100%;
		}
		.folders-in {
			grid-template-columns: repeat(1, 1fr);
		}
	}
</style>
