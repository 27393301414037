<svelte:options tag="edi-icon" />

<script lang="ts">
    import '@material/mwc-icon'

    export let icon: string;
</script>

<mwc-icon>{icon}</mwc-icon>

<style lang="scss">
    :host {
        --edi-icon-color: white;
        --edi-icon-font-size: 24px;
    }

    mwc-icon {
        color: var(--edi-icon-color);
        font-size: var(--edi-icon-font-size);
    }
</style>