<svelte:options tag="edi-student-search" />

<script lang="ts">
	import type { Writable } from 'svelte/store'
	import { get_current_component } from 'svelte/internal'
	import { injectable } from '../../common/injectable'
	import dispatch from '../../common/dispatch'
	import './Spinner.svelte'
	import '../Overlay'

	/**
	 * The debounce time before sending the search_changed event
	 */
	export let debounce: number = 250

	/**
	 * The input placeholder text
	 */
	export let placeholder: string = "Search Students"

	/**
	 * Flag indicating loading state
	 */
	export let loading: boolean = false

	/**
	 * Items to display as search results
	 */
	export let results: any[] = []

	/**
	 * The label on the results to display as the text
	 */
	export let text_label: string

	/**
	 * The label on the results to display as the student ID
	 */
	export let value_label: string

	const SEARCH_CHANGED = 'edi-student-search:search-changed'
	const SEARCH_CLOSED = 'edi-student-search:search-closed'
	const SELECTION_CHANGED = 'edi-student-search:selection-changed'

	const component = get_current_component()
	let appBarStudentSearchState: Writable<{ open: boolean }>
	let open: boolean = false
	let value: string
	let timer: any

	// this will debounce the user typing
	// this prevents the search_changed from being sent on every key press
	// the caller can specify how long the debounce should be
	$: if (value) {
		clearTimeout(timer)
		timer = setTimeout(() => {
			dispatch(component, SEARCH_CHANGED, { value })
		}, debounce)
	}

	// events

	/**
	 * Handles closing the component
	 */
	const handleClose = () => {
		// don't submit anything
		// clear the value
		// blur
		value = null
		open = false
		dispatch(component, SEARCH_CLOSED)
	}

	/**
	 * Handles when the user selects a result
	 * @param item the result the user selected
	 */
	const handleSelection = (item: any) => {
		// send the selection_changed event
		dispatch(component, SELECTION_CHANGED, item)
		// close the component
		handleClose()
	}

	// reactive 
	$: appBarStudentSearchState?.set({ open })
</script>

<div 
	use:injectable={'edi-app-bar:state:studentSearch'}
	on:injected={({ detail: { instance }}) => appBarStudentSearchState = instance}
/>

<edi-overlay on:click={handleClose} {open} />

<div
	class="student-search"
	class:student-search--open={open}
>
	<label>
		<mwc-icon class="input-icon input-icon--left">search</mwc-icon>
		<input
			on:click={() => open = true}
			bind:value={value}
			type="text"
			{placeholder}
		/>

		{#if value}
			<mwc-icon
				class="input-icon input-icon--right input-icon--action"
				on:click|preventDefault={handleClose}
			>close</mwc-icon>
		{/if}
	</label>

	{#if loading || value}
		<div class="results mdc-elevation--z4">
			{#if loading}
				<span class="results__item results__item--loader">
					<edi-spinner />
					<span>Loading...</span>
				</span>
			{:else}
				{#each results as result}
					<span class="results__item" on:click={() => handleSelection(result)}>
						<mwc-icon>person</mwc-icon>
						<div>
							<span>{result[text_label]},</span>
							<span class="student-id">ID {result[value_label]}</span>
						</div>
					</span>
				{:else}
					<span class="results__item results__item--empty">
						<span>No Results</span>
					</span>
				{/each}
			{/if}
		</div>
	{/if}
</div>


<style lang="scss">
	@import '../../styles/variables';
	@import '@material/elevation/mdc-elevation';

	:host {
		--edi-student-search-font-color: var(--edi-black);
		--edi-student-search-placeholder-font-color: var(--edi-dark-gray);
		--edi-student-search-icon-color: var(--edi-gray-medium);
	}

	edi-overlay {
		--edi-overlay-z-index: 550;
	}

	.student-id {
		font-weight: bold;
	}

	.student-search {
		position: relative;

		&--open {
			z-index: 550;

			.results {
				display: block;
			}

			label input[type='text'] {
				border: 2px solid #50b8ff;
				box-shadow: 0px 0px 6px 4px rgba(80, 184, 255, 0.55);
			}
		}
	}

	.results {
		--border-radius: 8px;

		display: none;
		width: 100%;
		border-radius: var(--border-radius);
		background: white;
		position: absolute;
		font-family: 'Roboto';
		font-size: 14px;
		padding: .75rem 0;

		&__item {
			display: flex;
			padding: .75rem;
			align-items: center;

			mwc-icon {
				color: var(--edi-student-search-icon-color);
				margin-right: 1rem;
			}

			&:hover {
				background: rgba(196, 196, 196, 0.19);
				cursor: pointer;
			}

			&--loader {
				justify-content: center;
				edi-spinner {
					width: 35px;
					margin-right: 1rem;
				}

				&:hover {
					cursor: initial;
					background: white;
				}
			}

			&--empty {
				justify-content: center;

				&:hover {
					cursor: initial;
					background: white;
				}
			}
		}
	}

	label {
		display: inline-block;
		position: relative;
		width: 100%;

		input[type='text'] {
			color: var(--edi-student-search-font-color);
			appearance: none;
			outline: none;
			font-size: 16px;
			line-height: 16px;
			border-radius: 24px;
			box-sizing: border-box;
			border: 2px solid white;
			width: 100%;
			padding: 12px 48px;
			margin: 4px 0;

			&::placeholder {
				color: var(--edi-student-search-placeholder-font-color);
				font-size: 16px;
				line-height: 16px;
			}

			&:hover {
				box-shadow: 0px 0px 6px 4px rgba(80, 184, 255, 0.55);
				cursor: text;
			}
		}

		.input-icon {
			position: absolute;
			color: var(--edi-student-search-icon-color);
			transform: translateY(-50%);
			top: 50%;
			/*z-index: 130; was added here. Removed to avoid the search icon in dashboard full-screen mode for widgets*/ 

			&--left {
				left: 16px;
			}

			&--right {
				right: 16px;
			}

			&--action {
				cursor: pointer;
				border-radius: 50%;
				padding: .25rem;

				&:hover {
					background: var(--edi-gray-ultralight);
				}
			}
		}
	}
</style>
