<svelte:options tag="edi-skeleton-loader" />

<template>
	<div class="timeline-item">
		<div class="animated-background" />
		<div class="background-masker content-user" />
	</div>
</template>

<style lang="scss">
	.timeline-item {
		background: #fff;
		border: 1px solid #eeeeee;
		border-radius: 3px;
		padding: 5px;

		margin: 0 auto;
		max-width: 100%;
	}

	@keyframes placeHolderShimmer {
		0% {
			background-position: -20px 0;
		}
		100% {
			background-position: 468px 0;
		}
	}

	.animated-background {
		animation-duration: 1.5s;
		animation-fill-mode: forwards;
		animation-iteration-count: infinite;
		animation-name: placeHolderShimmer;
		animation-timing-function: linear;
		background: #ccd1d9;
		background: linear-gradient(to right, #f5f5f4 8%, #e7e6e6 18%, #f5f5f4 33%);
		background-size: 800px 104px;
		height: 300px;
		position: relative;
	}

	.background-masker {
		background: #fff;
		position: absolute;
	}

</style>
