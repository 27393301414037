<svelte:options tag="edi-school-performance-score" />

<script lang="ts">
	export let items: any = []
</script>

<div class="container">
	{#if items.length > 0}
		{#each items as data}
			<h3 class="title">
				{data?.title}
			</h3>
			<div class="grid-container">
				<div class="data">
					<div>{data?.score}</div>
				</div>
			</div>
			<div class="profile-nav">
				<a href={data?.link} target="_blank">Pennsylvania School Performance Profile</a>
			</div>
		{/each}
	{:else}
		<div class="default">
			<h4>School Performance Scores are not available at the District Level.</h4>
			<h4>Please select a building.</h4>
		</div>
	{/if}
</div>

<style lang="scss">
	.container {
		padding: 10px 10px 10px 10px;
		text-align: center;
	}

	.data {
		grid-area: data;
		display: grid;
		grid-template-columns: 1fr;
	}

	.data > div {
		padding-top: 5px;
		padding-bottom: 5px;
		color: skyblue;
		background-color: #e4e5e7;
		font-family: Verdana;
		font-size: 50pt;
	}

	.grid-container {
		width: 100%;
		display: grid;
		grid-template-areas: 'data';
	}

	.grid-container > div {
		background-color: rgba(255, 255, 255, 0.8);
		text-align: center;
		padding: 1px;
		font-family: 'Roboto';
		padding: 0px 50px 0px 50px;
	}

	a {
		text-decoration: none;
		color: rgba(79, 165, 236, 0.65);
	}

	.profile-nav {
		padding: 5px;
		margin: 10px 30px 10px 30px;
		border-color: #0097e5;
		border-style: solid;
		border-width: thin;
	}

	.default {
		text-align: center;
		font-family: 'Roboto';
		font-size: 15px;
		color: #e94d4d;
	}
</style>
