<svelte:options tag="edi-account-select-option" />

<script lang="ts">
	import type Option from './dto/option'
	import './AccountTypeIcon.svelte'

	export let value: string
	export let data: Option
	export let selected: boolean
</script>


<div
	class:selected={selected}
>
	<edi-account-type-icon class="icon" is_primary={data?.isPrimary} />

	{#if data?.title}
		<span class="title">
			{data.title}
		</span>
	{/if}

	{#if data?.title && data?.subtext}
		&nbsp;-&nbsp;
	{/if}

	{#if data?.subtext}
		{data.subtext}
	{/if}

</div>

<style lang="scss">
	@import '../../styles/variables';
	@import '@material/elevation/mdc-elevation';

	.selected {
		background: var(--edi-dark-gray);
		color: white;
	}

	.icon {
		margin-right: .5rem;
	}

	.title {
		font-weight: bold;
	}

	div {
		display: flex;
		align-items: center;
		color: #C5CCD3;
		padding: .5rem;
		font-size: 14px;
		border-radius: 4px;
		white-space: nowrap;
		letter-spacing: -0.005em;
		text-transform: capitalize;
		overflow-x: hidden;
		text-overflow: ellipsis;

		&:hover {
			@extend .selected;
			cursor: pointer;
		}
	}
</style>
