import dispatch from '../../common/dispatch';
// Method to remove assessments from favorite list
export const handleRemoveFavorite = (element, item) => () => {
    return dispatch(element, 'edi-sdd-menu:remove_favorited', { item });
};
// Method to  add/remove assessments into favorite list
export const handleToggleFavorite = (element, item, folder_id, subfolder_id) => () => {
    dispatch(element, 'edi-sdd-menu:toggle_favorite', { item, folder_id, subfolder_id });
};
// Method to handle sorting on folders list according to 'sortOption'
export const sortFolders = (folders, sortOption) => {
    switch (sortOption) {
        case 'Name A-Z':
            folders.sort((a, b) => a.folderName.localeCompare(b.folderName));
            break;
        case 'Name Z-A':
            folders.sort((a, b) => b.folderName.localeCompare(a.folderName));
            break;
        case 'Created Date':
            folders.sort((a, b) => +new Date(a.createdDate) - +new Date(b.createdDate));
            break;
        case 'Last Updated':
            // Spliting 'folders' array into lastUpdated is null and lastUpdated is not null
            const lastUpdatedIsNull = folders.filter((folder) => folder.lastUpdated === null);
            const lastUpdatedIsNotNull = folders.filter((folder) => folder.lastUpdated !== null);
            // Sorting according to lastUpdated value
            lastUpdatedIsNotNull.sort((a, b) => +new Date(a === null || a === void 0 ? void 0 : a.lastUpdated) - +new Date(b === null || b === void 0 ? void 0 : b.lastUpdated));
            // merging the splitted array
            folders = [...lastUpdatedIsNotNull, ...lastUpdatedIsNull];
            break;
        default:
            break;
    }
    return folders;
};
// Method to close the SDD menu drawer
export const handleClose = (element) => () => {
    dispatch(element, 'edi-sdd-menu:closed');
};
// Method to handle sorting on assessment list according to 'sortOption'
export const sortAssessments = (assessments, sortOption) => {
    switch (sortOption) {
        case 'Name A-Z':
            assessments.sort((a, b) => a.assessmentName.localeCompare(b.assessmentName));
            break;
        case 'Name Z-A':
            assessments.sort((a, b) => b.assessmentName.localeCompare(a.assessmentName));
            break;
        case 'Created Date':
            assessments.sort((a, b) => +new Date(a.createdDate) - +new Date(b.createdDate));
            break;
        case 'Last Updated':
            // Spliting 'assessments' array into lastUpdated is null and lastUpdated is not null
            const lastUpdatedIsNull = assessments.filter((folder) => folder.lastUpdated === null);
            const lastUpdatedIsNotNull = assessments.filter((folder) => folder.lastUpdated !== null);
            // Sorting according to lastUpdated value
            lastUpdatedIsNotNull.sort((a, b) => +new Date(a === null || a === void 0 ? void 0 : a.lastUpdated) - +new Date(b === null || b === void 0 ? void 0 : b.lastUpdated));
            // merging the splitted array
            assessments = [...lastUpdatedIsNotNull, ...lastUpdatedIsNull];
            break;
        default:
            break;
    }
    return assessments;
};
