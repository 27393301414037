<svelte:options tag="edi-welcome-message" />

<script lang="ts">
	export let message: any
</script>

<div>
	<p>{message}</p>
</div>

<style lang="scss">
	p {
		padding: 0px 5px;
		text-align: justify;
		font-family: 'Roboto';
		font-size: 15px;
	}
</style>
