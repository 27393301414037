<svelte:options tag="edi-ytd-attendance" />

<script lang="ts">
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../../common/dispatch'

	const component = get_current_component()

	export let id: any // Widget ID
	export let title: any // Widget Title
	export let user_id: any

	// Headings prop
	export let headings: any = []

	export let items: any = []

	// Dropdown props
	export let dropdownitems: any = []

	// Binding values to this variable when selectiing an item from dropdown
	let selected: any = dropdownitems.map((x) => x.dropdown.filter((a) => a.selected))

	// Pagination props
	export let current_page: any
	export let from: any
	export let to: any
	export let per_page: any
	export let last_page: any
	export let total: any

	export let page_sizes: any = []

	export let max_left: any // For the left most button for the pagination
	export let max_right: any // For the right most button for the pagination
	export let max_buttons: any // For the maximum number pagination buttons at a time

	// Field to hold values of dropdown details for the api side
	let label: any
	let selectedDropdown: any
	let dropdownID: any
	let ddlList: any[] = []

	function changePage(params) {
		per_page = params.per_page || 2
		const page = params.page || 1
		from = (page - 1) * per_page
		to = page * per_page
		current_page = page

		ddlList = []
		selected.forEach((element, index) => {
			label = dropdownitems[index].label
			selectedDropdown = element.value
			dropdownID = element.id
			ddlList = [...ddlList, { label, selectedDropdown, dropdownID }]
		})

		dispatch(component, 'edi-ytd-attendance:pagechange', { id, from, to, ddlList, user_id, widgettitle: title })
	}

	// Method create the buttons for the pagination according to per_page size and current page
	function buildPagination() {
		var pages = Math.round(total / per_page)

		max_left = current_page - Math.floor(max_buttons / 2)
		max_right = current_page + Math.floor(max_buttons / 2)

		if (max_left < 1) {
			max_left = 1
			max_right = max_buttons
		}

		if (max_right > pages) {
			max_left = pages - (max_buttons - 1)

			if (max_left < 1) {
				max_left = 1
			}
			max_right = pages
		}
	}

	document.addEventListener('ytd_attendance_page_change', (e: any) => {
		items = e?.detail?.datachanged
		total = e?.detail?.total

		last_page = Math.ceil(total / per_page)

		buildPagination() //Calling the method to build the pagination buttons
	})
</script>

<div class="container">
	<div class="select">
		{#each dropdownitems as item, index}
			<div>
				<span>{item.label}:</span>
				<select
					bind:value={selected[index]}
					on:change={() => changePage({ selected, per_page, page: current_page })}
				>
					{#each item.dropdown as dropdown}
						<option selected={dropdown?.selected} value={dropdown}>
							{dropdown.value}
						</option>
					{/each}
				</select>
			</div>
			<br />
		{/each}
	</div>

	<div class="grid-container">
		<div class="heading" id="heading">
			{#each headings as heading}
				<div>{heading}</div>
			{/each}
		</div>

		{#if items.length > 0}
			<div class="data">
				{#each items as data}
					<div><a href={data?.link}>{data?.name} </a></div>
					<div>{data?.absenceCount}</div>
				{/each}
			</div>
		{:else}
			<div class="default">No Data Available</div>
		{/if}
	</div>

	{#if total > per_page && items.length > 0}
		<div style="padding-top: 5px;">
			<edi-pagination
				{current_page}
				{last_page}
				{from}
				{to}
				{total}
				{per_page}
				{page_sizes}
				{max_left}
				{max_right}
				on:edi-pagination:change={(ev) => changePage(ev.detail)}
			/>
		</div>
	{/if}
</div>

<style lang="scss">
	.container {
		padding: 10px 10px 10px 10px;
	}

	select {
		height: 20px;
		width: 55%;
		background: #f5f5f5;
		border: none;
		border-radius: 5px;
		border-color: #f5f5f5;
		color: #424040;

		font-family: 'Roboto';
		font-style: normal;
		font-size: 13px;
		line-height: 18px;
	}
	.select {
		text-align: center;

		font-family: 'Roboto';
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		line-height: 19px;

		color: #424040;
	}

	.data {
		border-bottom: 1px solid #f3f3f3;
	}

	a {
		text-decoration: none;
		color: #2757f2;
	}

	a:hover {
		color: #017698;
	}

	.heading {
		grid-area: heading;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 1px;
	}

	.heading > div {
		padding-top: 5px;
		padding-bottom: 5px;
	}

	#heading {
		font-weight: bold;

		background: #f5f5f5;
		border-radius: 7px;
	}

	.data {
		grid-area: data;
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.data > div {
		padding-top: 5px;
		padding-bottom: 5px;
		border-bottom: 1px solid #f3f3f3;
	}

	.grid-container {
		width: 100%;
		display: grid;
		grid-template-areas:
			'heading'
			'data';
		max-height: 200px;
		overflow: auto;
	}

	.grid-container > div {
		background-color: rgba(255, 255, 255, 0.8);
		text-align: center;
		padding: 1px;
		font-family: 'Roboto';
		font-size: 15px;
	}

	.default {
		text-align: center;
		font-family: 'Roboto';
		font-size: 15px;
	}
</style>
