<svelte:options tag="edi-student-groups" />

<script lang="ts">
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../../common/dispatch'

	const component = get_current_component()

	export let id: any

	export let title: any

	export let user_id: any

	// Headings prop
	export let headings = []

	export let items = []

	// Dropdown props
	export let studentlist = []

	export let selected_list: any

	// Field to hold values of dropdown details for the api side
	let dropdownID: any
	let ddlList: any[] = []

	let selectedHeader = 'lastname'

	// Holds table sort state.  Initialized to reflect table sorted by id column ascending.
	let sortBy = { col: 'lastname', ascending: true }

	const handlechange = () => {
		studentlist.forEach((item) => {
			if (item.id === dropdownID) {
				selected_list = item.value
			}
		})

		ddlList = []

		ddlList = [...ddlList, { dropdownID }]

		dispatch(component, 'edi-student-groups:dropdownchange', {
			id,
			widgettype: 'grid',
			user_id,
			ddlList,
			widgettitle: title,
		})
	}

	document.addEventListener('studentgroups_changed', (e: any) => {
		items = e.detail.datachanged
	})

	$: sort = (column) => {
		if (sortBy.col == column) {
			sortBy.ascending = !sortBy.ascending
		} else {
			sortBy.col = column
			sortBy.ascending = true
		}

		// Modifier to sorting function for ascending or descending
		let sortModifier = sortBy.ascending ? 1 : -1

		let sort = (a, b) => (a[column] < b[column] ? -1 * sortModifier : a[column] > b[column] ? 1 * sortModifier : 0)

		items = items.sort(sort)

		selectedHeader = column
	}
</script>

<div class="container" style="max-height: 600px; overflow:auto;">
	<div class="select">
		<span>Student List :</span>
		<span>
			<select bind:value={dropdownID} on:change={() => handlechange()}>
				{#each studentlist as item}
					<option selected={item?.selected} value={item?.id}>
						{item?.value}
					</option>
				{/each}
			</select>
		</span>
	</div>

	<div class="selected_list">
		<span>{selected_list}</span>
	</div>
	<table>
		<tr>
			{#each headings as heading}
				{#if heading.key === 'lastname' || heading.key === 'firstname' || heading.key === 'grade'}
					<th
						id="clickable"
						class:highlighted={selectedHeader === heading.key && items.length > 0}
						on:click={() => {
							sort(heading.key)
						}}
						>{heading.value}
						{#if heading.key === selectedHeader && items.length > 0}
							<span class="order-icon">
								{@html sortBy.ascending ? '&#9661;' : '&#9651;'}
							</span>
						{/if}
					</th>
				{:else}
					<th style="font-weight: bold;">{heading.value}</th>
				{/if}
			{/each}
		</tr>
		{#if items.length > 0}
			{#each items as data}
				<tr>
					<td>
						<a href={data?.link}>{data?.id}</a>
					</td>
					<td>{data?.lastname}</td>
					<td>{data?.firstname}</td>
					<td>{data?.grade}</td>
					<td>{data?.iep}</td>
					<td>{data?.ell}</td>
				</tr>
			{/each}
		{/if}
	</table>

	{#if items.length === 0}
		<div class="default">No Data Available</div>
	{/if}
</div>

<style lang="scss">
	.container {
		width: 100%;
	}

	.select {
		text-align: left;
		padding: 10px;

		font-family: 'Roboto';
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		line-height: 19px;
	}

	select {
		height: 20px;
		width: 70%;
		background: #f5f5f5;
		border: none;
		border-radius: 5px;
		border-color: #f5f5f5;

		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 18px;
	}

	.selected_list {
		text-align: left;
		padding: 10px;
		font-family: 'Roboto';
		font-size: 14px;
		font-weight: bold;
		color: #ff9000;
	}

	table {
		width: 100%;
		font-family: 'Roboto';
		border-collapse: collapse;
	}

	table th:first-child {
		border-radius: 7px 0 0 7px;
	}

	table th:last-child {
		border-radius: 0 7px 7px 0;
	}

	th {
		background: #f5f5f5;
		padding-top: 8px;
		padding-bottom: 7px;
		font-size: 15px;
		font-weight: 500;
	}

	td {
		padding-top: 7px;
		padding-bottom: 7px;
		font-size: 14px;
		border-bottom: 1px solid #f3f3f3;
	}

	td,
	th {
		text-align: left;
		font-family: 'Roboto';
	}

	a {
		text-decoration: none;
		color: #2757f2;
	}

	a:hover {
		color: #017698;
	}

	.default {
		text-align: center;
		font-family: 'Roboto';
		font-size: 15px;
	}

	.highlighted {
		color: hsl(15, 100%, 45%);
	}

	#clickable {
		font-weight: bold;
		cursor: pointer;
	}
</style>
