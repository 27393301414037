<svelte:options tag="edi-svg-up-fill" />

<script lang="ts">
	export let width: string
	export let height: string
</script>

<svg {width} {height} viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path opacity="1" d="M18 10L9.5 0.999999L1 10" stroke="#555555" />
</svg>
