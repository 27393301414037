<svelte:options tag="edi-sub-folder-config" />

<script lang="ts">
	// not using this comp anymore. will delete later
	export let icons: any = []
	export let parent_folder: any

	let selectedParent: any
	let selectedIcon: any
	let folderName: any = ''
	let folderDescription: any = ''

	const handleSubmit = () => {}

	const handleChange = (selected: any) => {}

	const handleCancel = () => {}

</script>

<div class="folder-create">
	<div class="preview">
		<label for="">Menu Preview</label>
		<div class="preview-box">
			<span>{selectedIcon?.value}</span> <br />
			<label for="">{folderName}</label><br />
			<label for="">{folderDescription}</label>
		</div>
	</div>
	<form on:submit|preventDefault={handleSubmit}>
		<div class="first-row">
			<span>
				<div>Parent Folder(Optional)</div>
				<div>
					<input type="text" bind:value={parent_folder} readonly />
				</div>
			</span>
			<span style="padding-left: 15%;">
				<div>Folder Name</div>
				<div>
					<input type="text" bind:value={folderName} />
				</div>
			</span>
		</div>
		<br />
		<div class="second-row">
			<span>
				<div>Folder Icon</div>
				<div>
					<select bind:value={selectedIcon} on:change={() => handleChange(selectedIcon)}>
						{#each icons as icon}
							<option value={icon}>
								{icon.value}
							</option>
						{/each}
					</select>
				</div>
			</span>
			<span style="padding-left: 20%;">
				<div>Folder Description</div>
				<div>
					<input type="text" bind:value={folderDescription} />
				</div>
			</span>
		</div>
		<br />
		<div style="padding-left: 25%;">
			<span><button type="submit">Create</button></span>
			<span><button on:click={() => handleCancel()}>Cancel</button></span>
		</div>
	</form>
</div>

<style lang="scss">
	.folder-create {
		display: flex;
        margin: 10px;
		padding: 15px;
        background: #ffffff;
		border: 1px solid #e2e9f3;
		border-radius: 5px;
	}

	.preview-box {
		box-sizing: border-box;

		position: absolute;

		background: #ffffff;
		border: 1px solid #e2e9f3;
		border-radius: 5px;
	}

	.first-row {
		display: flex;
	}

	.second-row {
		display: flex;
	}
</style>
