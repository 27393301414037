<svelte:options tag="edi-app-bar-action"></svelte:options>

<script lang="ts">
    import '../IconButton'

    export let icon: string
    export let text: string = null
</script>

{#if text}
    <edi-tooltip {text}>
        <edi-icon-button {icon}></edi-icon-button>
    </edi-tooltip>
{:else}
    <edi-icon-button {icon}></edi-icon-button>
{/if}
