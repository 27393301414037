<svelte:options tag="edi-account-select" />

<script lang="ts">
	import { get_current_component } from 'svelte/internal';
	import dispatch from '../../common/dispatch';
	import type ValueOption from './dto/value-option'
	import './Option.svelte'

	export let options: ValueOption[] = []
	export let selected: ValueOption

	const component = get_current_component();

	let selectRef
	let open: boolean = false
	let secondaryText: string
	let primaryText: string

	$: primaryText = selected?.isPrimary ? "Primary Account" : "Secondary Account"

	$: if (selected) {
		const parts = [
			selected.title,
			selected.subtext
		]

		secondaryText = parts
			.filter(x => x)
			.join(' - ')
	}

	// events
	const handleSelection = (option: ValueOption) => {
		selectRef.blur();
		dispatch(component, 'edi-account-select:selection-changed', { option })
	}

	const handleTriggerClick = () => {
		open = !open

		if (open) {
			selectRef.focus()
		}
		else {
			selectRef.blur()
		}
	}
</script>

<div class="container">
	<div
		bind:this={selectRef}
		on:blur={() => open = false}
		tabindex="-1"
		class="select"
		class:select--open={open}
	>
		<div
			on:click={handleTriggerClick}
			class="trigger"
			class:mdc-elevation--z4={open}
		>
			<div class="trigger__icon-left">
				<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M6.41671 0.883551L1.41671 2.75855C0.766708 3.00855 0.333374 3.62522 0.333374 4.32522V8.25022C0.333374 12.4586 3.17504 16.3836 7.00004 17.3419C10.825 16.3836 13.6667 12.4586 13.6667 8.25022V4.32522C13.6661 3.9861 13.5621 3.65523 13.3685 3.37679C13.1749 3.09835 12.901 2.88558 12.5834 2.76688L7.58337 0.891884C7.20837 0.741884 6.79171 0.741884 6.41671 0.883551ZM5.52504 11.3586L3.75837 9.59188C3.68122 9.51473 3.62002 9.42314 3.57827 9.32234C3.53651 9.22153 3.51502 9.11349 3.51502 9.00438C3.51502 8.89528 3.53651 8.78724 3.57827 8.68643C3.62002 8.58563 3.68122 8.49404 3.75837 8.41688C3.83553 8.33973 3.92712 8.27853 4.02792 8.23678C4.12872 8.19502 4.23676 8.17353 4.34587 8.17353C4.45498 8.17353 4.56302 8.19502 4.66383 8.23678C4.76463 8.27853 4.85622 8.33973 4.93337 8.41688L6.10837 9.59188L9.05837 6.64188C9.13552 6.56473 9.22712 6.50353 9.32792 6.46178C9.42872 6.42002 9.53676 6.39853 9.64587 6.39853C9.75498 6.39853 9.86302 6.42002 9.96383 6.46178C10.0646 6.50353 10.1562 6.56473 10.2334 6.64188C10.3105 6.71904 10.3717 6.81063 10.4135 6.91143C10.4552 7.01224 10.4767 7.12028 10.4767 7.22938C10.4767 7.33849 10.4552 7.44653 10.4135 7.54734C10.3717 7.64814 10.3105 7.73973 10.2334 7.81688L6.70004 11.3502C6.38337 11.6836 5.85004 11.6836 5.52504 11.3586Z"
						fill="#9FAAB6"
					/>
				</svg>
			</div>

			<div class="trigger__body">
				<span class="trigger__primary-text">{primaryText}</span>
				<span class="trigger__secondary-text">{secondaryText}</span>
			</div>

			<div class="trigger__icon">
				<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M1.00002 0.601562C0.868187 0.601587 0.739322 0.635333 0.629715 0.698534C0.520109 0.761735 0.434682 0.851555 0.384236 0.956637C0.333789 1.06172 0.320587 1.17735 0.346298 1.2889C0.37201 1.40046 0.435481 1.50293 0.528688 1.58337L4.52869 5.03435C4.65371 5.14217 4.82324 5.20275 5.00002 5.20275C5.1768 5.20275 5.34634 5.14217 5.47135 5.03435L9.47136 1.58337C9.56456 1.50293 9.62803 1.40046 9.65374 1.2889C9.67945 1.17735 9.66625 1.06172 9.61581 0.956637C9.56536 0.851555 9.47993 0.761735 9.37033 0.698534C9.26072 0.635333 9.13186 0.601587 9.00002 0.601562H1.00002Z"
						fill="#9FAAB6"
					/>
				</svg>
			</div>
		</div>

		<div class="options mdc-elevation--z4">
			{#each options as option}
				<edi-account-select-option data={option} value={option.value} selected={option.value === selected?.value} on:click={() => handleSelection(option)} />
			{/each}
		</div>
	</div>
</div>

<style lang="scss">
	@import '../../styles/variables';
	@import '../../styles/mixins';
	@import '@material/elevation/mdc-elevation';

	:host {
		--edi-account-select-background-color: var(--edi-dark);
	}

	.container {
		position: relative;
		user-select: none;
		font-family: 'Roboto';
		display: block;
		width: 224px;

		@include lg {
			width: 278px;
		}
	}

	.select {
		position: relative;
		user-select: none;

		&--open {
			.options {
				display: inline-block;
			}
		}
	}

	.options {
		background: var(--edi-account-select-background-color);
		list-style: none;
		padding: 0;
		margin: .25rem 0 0 0;
		padding: .5rem;
		display: none;
		border-radius: 8px;
		position: absolute;
		top: 42px;
		left: 50%;
		z-index: 510;
		transform: translate(-50%, 0);
	}

	.trigger {
		display: flex;
		align-items: center;
		background: var(--edi-account-select-background-color);
		height: 42px;
		color: white;
		border-radius: 24px;
		padding: 0 36px;
		box-sizing: border-box;
		position: relative;

		&:hover {
			@extend .mdc-elevation--z4;
			cursor: pointer;
		}

		&__body {
			display: grid;
		}

		&__icon {
			display: inline-block;
			position: absolute;
			top: 9px;
			right: 12px;
		}

		&__icon-left {
			display: inline-block;
			position: absolute;
			top: 13px;
			left: 12px;
		}

		&__primary-text {
			display: block;
			font-size: 14px;
			font-weight: bold;
		}

		&__secondary-text {
			color: var(--edi-gray-light);
			display: block;
			font-size: 12px;
			overflow-x: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
</style>
