<svelte:options tag="edi-date-picker" />

<script lang="ts">
	import { createEventDispatcher } from 'svelte'
	import Calender from './Calender.svelte'
	import { getMonthName } from './date-time.js'

	import { get_current_component } from 'svelte/internal'
	const component = get_current_component()

	import dispatch1 from '../../../common/dispatch'

	const dispatch = createEventDispatcher()

	// props
	export let selected = new Date()

	// state
	let date, month, year, showDatePicker

	// so that these change with props
	$: {
		date = selected.getDate()
		month = selected.getMonth()
		year = selected.getFullYear()
	}

	// handlers
	const onFocus = () => {
		showDatePicker = true
	}

	const next = () => {
		if (month === 11) {
			month = 0
			year = year + 1
			return
		}
		month = month + 1
	}

	const prev = () => {
		if (month === 0) {
			month = 11
			year -= 1
			return
		}
		month -= 1
	}

	const onDateChange = (d) => {
		showDatePicker = false
		dispatch('datechange', d.detail)
	}

	const gotoPrev = () => {
		selected.setDate(selected.getDate() - 1)
		selected = new Date(selected)
		dispatch1(component, 'datechange', selected)
	}

	const gotoNext = () => {
		selected.setDate(selected.getDate() + 1)
		selected = new Date(selected)
		dispatch1(component, 'datechange', selected)
	}

	const gotoToday = () => {
		selected = new Date()
		dispatch1(component, 'datechange', selected)
	}
</script>

<div class="relative">
	<div style="margin-left: 3px; margin-top: 3px;">
		<button id="btn-date-nav" style="float: left;" on:click={gotoPrev}>
			<edi-svg-left-fill width="16" height="16" />
		</button>
		<button id="btn-date-nav" style="float: left;" on:click={gotoNext}>
			<edi-svg-right-fill width="16" height="16" />
		</button>
		<button id="btn-date-nav" style="float: left;" on:click={gotoToday}>Today</button>
	</div>

	<!-- <input type="text" on:focus={onFocus} value={selected.toDateString()} readonly /> -->
	<!-- <div on:click={onFocus}>{selected.toDateString()}</div> -->
	<span style="margin-left: 10px; display: inline-flex; margin-top: 6px;" on:click={onFocus}>
		<edi-svg-calender width="16" height="16" />

		<button style="border: none; background: white; cursor: pointer;">{selected.toDateString()}</button>
	</span>

	{#if showDatePicker}
		<div class="box">
			<div class="month-name">
				<div class="center">
					<button on:click={prev}>
						<edi-svg-left-fill width="16" height="16" />
					</button>
				</div>
				<div class="center">{getMonthName(month)} {year}</div>
				<div class="center">
					<button on:click={next}>
						<edi-svg-right-fill width="16" height="16" />
					</button>
				</div>
			</div>
			<edi-calender {month} {year} date={selected} on:datechange={onDateChange} />
		</div>
	{/if}
</div>

<style lang="scss">
	.relative {
		position: relative;
	}

	#btn-date-nav {
		height: 25px;
		cursor: pointer;
	}

	.box {
		position: relative;
		left: 0px;
		border: 1px solid green;
		display: inline-block;
		margin-top: 3px;
	}

	.month-name {
		display: flex;
		justify-content: space-around;
		align-items: center;
		margin: 6px 0;
	}

	.center {
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
