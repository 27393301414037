<svelte:options tag="edi-this-years-roster" />

<script lang="ts">
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../../common/dispatch'

	const component = get_current_component()

	export let id: any

	export let title: any

	export let user_id: any

	export let grid_data: any = []

	// Dropdown props
	export let studentlist = []

	// Headings prop
	export let headings = []

	export let dropdowntype: any

	export let selected_list: any

	// Field to hold values of dropdown details for the api side
	let dropdownID: any
	let ddlList: any[] = []

	// Prop to check whether to show the current groups or not
	export let show_current_groups: any
	// Prop to display current groups
	export let current_groups: any = []

	let dropdownType: any

	let label: string

	let selectedDropdown: string

	let selectedHeader = 'lastname'

	// Holds table sort state.  Initialized to reflect table sorted by id column ascending.
	let sortBy = { col: 'lastname', ascending: true }

	const handlechange = () => {
		studentlist.forEach((item) => {
			if (item.id === dropdownID) {
				dropdownType = item.type
				label = item.id
				selectedDropdown = item.value
			}
		})

		ddlList = []

		ddlList = [...ddlList, { label, dropdownType, selectedDropdown }]

		dispatch(component, 'edi-this-years-roster:dropdownchange', {
			id,
			widgettype: 'grid',
			user_id,
			ddlList,
			widgettitle: title,
		})
	}

	document.addEventListener('this_yearsroster_changed', (e: any) => {
		dropdowntype = e?.detail?.dropdowntype
		grid_data = e?.detail?.datachanged ? e?.detail?.datachanged : []
		selected_list = e?.detail?.selected_list
		show_current_groups = e?.detail?.show_current_groups
	})

	$: sort = (column) => {
		if (sortBy.col == column) {
			sortBy.ascending = !sortBy.ascending
		} else {
			sortBy.col = column
			sortBy.ascending = true
		}

		// Modifier to sorting function for ascending or descending
		let sortModifier = sortBy.ascending ? 1 : -1

		let sort = (a, b) => (a[column] < b[column] ? -1 * sortModifier : a[column] > b[column] ? 1 * sortModifier : 0)

		grid_data[0].items = grid_data[0].items.sort(sort)

		selectedHeader = column
	}
</script>

<div class="container">
	<div class="select">
		<span>Student List :</span>
		<span>
			<select bind:value={dropdownID} on:change={() => handlechange()}>
				{#each studentlist as item}
					<option value={item?.id}>
						{item?.value}
					</option>
				{/each}
			</select>
		</span>
	</div>

	<div class="selected_list">
		<span>{selected_list}</span>
	</div>

	{#if dropdowntype === '1'}
		{#if grid_data.length !== 0}
			<div class="table-container">
				{#each grid_data as data}
					<div>
						<span class="course-name">
							<a href={data?.link}>{data?.coursename}</a>
						</span>
						&nbsp;
						<span>
							| [ {data?.section} ] | [ {data?.period} ] | [ Room #: {data?.room} ]
						</span>
					</div>
					<div class="table">
						<table>
							{#each data?.items as item}
								<tr>
									<td>
										<a href={item?.link}>{item?.displaystudentid}</a>
									</td>
									<td>{item?.lastname}</td>
									<td>{item?.firstname}</td>
									<td>{item?.title1}</td>
									<td>{item?.title3}</td>
									<td>{item?.gifted}</td>
									<td>{item?.periodcode}</td>
								</tr>
							{/each}
						</table>
					</div>
					<br />
				{/each}
			</div>
		{:else}
			{#if selected_list === "Today's Classes"}
				<p>
					There are no classes listed today. This could be because your master schedule timeslot have not been
					loaded into EdInsight.
				</p>
			{/if}

			<h3>No records to display</h3>
		{/if}

		{#if show_current_groups}
			<div class="current_groups">
				<span>Current Groups</span>
			</div>
			{#if current_groups.length !== 0}
				<div class="table-container">
					{#each current_groups as data}
						<div>
							<span class="student-group-name">{data?.studentgroupname}</span>
						</div>
						<div class="table">
							<table>
								{#each data?.items as item}
									<tr>
										<td>
											<a href={item?.link}>{item?.displaystudentid}</a>
										</td>
										<td>{item?.lastname}</td>
										<td>{item?.firstname}</td>
										<td>{item?.title1}</td>
										<td>{item?.title3}</td>
										<td>{item?.gifted}</td>
										<td>{item?.periodcode}</td>
									</tr>
								{/each}
							</table>
						</div>
						<br />
					{/each}
				</div>
			{:else}
				<h3>No records to display</h3>
			{/if}
		{/if}
	{/if}

	{#if dropdowntype === '3'}
		<div class="table-detailed">
			<table>
				<tr>
					{#each headings as heading}
						{#if heading.key === 'lastname' || heading.key === 'firstname' || heading.key === 'grade'}
							<th
								id="clickable"
								class:highlighted={selectedHeader === heading.key && grid_data.length > 0}
								on:click={() => {
									sort(heading.key)
								}}
								>{heading.value}
								{#if heading.key === selectedHeader && grid_data.length > 0}
									<span class="order-icon">
										{@html sortBy.ascending ? '&#9661;' : '&#9651;'}
									</span>
								{/if}
							</th>
						{:else}
							<th style="font-weight: bold;">{heading.value}</th>
						{/if}
					{/each}
				</tr>
				{#if grid_data.length > 0}
					{#each grid_data[0].items as item}
						<tr>
							<td>
								<a href={item?.link}>{item?.displaystudentid}</a>
							</td>
							<td>{item?.lastname}</td>
							<td>{item?.firstname}</td>
							<td>{item?.grade}</td>
							<td>{item?.iep}</td>
							<td>{item?.ell}</td>
						</tr>
					{/each}
				{/if}
			</table>
			{#if grid_data.length === 0}
				<div class="default">No Data Available</div>
			{/if}
		</div>
	{/if}
</div>

<style lang="scss">
	.container {
		width: 100%;
	}

	.select {
		text-align: left;
		padding: 5px 0px 5px 0px;

		font-family: 'Roboto';
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		line-height: 19px;
	}

	select {
		height: 20px;
		width: 75%;
		background: #f5f5f5;
		border: none;
		border-radius: 5px;
		border-color: #f5f5f5;

		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 18px;
	}

	.table {
		margin-top: 5px;
	}

	table {
		width: 100%;
		font-family: 'Roboto';
		border-collapse: collapse;
	}

	table th:first-child {
		border-radius: 7px 0 0 7px;
	}

	table th:last-child {
		border-radius: 0 7px 7px 0;
	}

	th {
		font-weight: bold;
		background: #f5f5f5;
		padding-top: 8px;
		padding-bottom: 8px;
		font-size: 15px;
		font-weight: 500;
	}

	td {
		padding-top: 7px;
		padding-bottom: 7px;
		font-size: 14px;
		border-bottom: 1px solid #f3f3f3;
	}

	td,
	th {
		text-align: left;
		font-family: 'Roboto';
	}

	a {
		text-decoration: none;
		color: #2757f2;
	}

	a:hover {
		color: #017698;
	}

	.table-container,
	.table-detailed {
		max-height: 500px;
		overflow: auto;
	}

	.course-name,
	.student-group-name {
		font-weight: bold;
		font-family: 'Roboto';
		color: rgba(79, 165, 236, 0.65);
		text-decoration: underline;
	}

	.selected_list,
	.current_groups {
		text-align: left;
		padding: 5px 0px 5px 0px;
		margin-top: 10px;
		font-family: 'Roboto';
		font-size: 14px;
		font-weight: bold;
		color: #ff9000;
	}

	p {
		font-family: 'Roboto';
		color: #3970a6;
	}

	h3 {
		font-family: 'Roboto';
		text-align: center;
		color: #3970a6;
	}

	.highlighted {
		color: hsl(15, 100%, 45%);
	}

	#clickable {
		font-weight: bold;
		cursor: pointer;
	}

	.default {
		text-align: center;
		font-family: 'Roboto';
		font-size: 15px;
	}
</style>
