<svelte:options tag="edi-badge" />

<script lang="ts">
	export let badgecontent: number
</script>

{#if badgecontent > 0}
	<div class="badge">
		{badgecontent}
	</div>
{/if}
<slot />

<style lang="scss">
	.badge {
		height: 25px;
		width: 25px;
		background-color: rgba(252, 6, 6, 0.774);
		border-radius: 50%;
		display: flex;
		position: absolute;
		align-items: center;
		box-sizing: border-box;
		padding: 0px 8px;
		z-index: 1;
		color: #fff;
		transform: scale(1) translate(70%, -50%);
	}
</style>
