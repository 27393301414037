<svelte:options tag="edi-logoicon" />

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 147.97 42.13">
	<g id="Layer_2" data-name="Layer 2"
		><g id="Layer_1-2" data-name="Layer 1"
			><path
				class="cls-1"
				d="M7.61,19.83l15.89,9.5,4.62,12.8h2.1L25.34,28.62l9.71-3.51V23.68l-7.32,2.65,7.32-8.06V15.58l-11,12.06L8.21,18.2c2.3-3.95,6-6.39,10-5.77l0,0h0l0,0h0c4.23-4.59,11.37-3.73,16.74.61V4.4A4.4,4.4,0,0,0,30.65,0H4.4A4.4,4.4,0,0,0,0,4.4V37.73a4.4,4.4,0,0,0,4.4,4.4h8.9C8.16,37.05,3.52,27.82,7.61,19.83Zm12-16.6,3.09,1.41a24.65,24.65,0,0,0-3.65,5.51,9.86,9.86,0,0,0-1.27,1.07h-.09A23.81,23.81,0,0,1,19.64,3.23ZM2.83,4.91c5-1.79,8.63-1.7,11.13-.84a6.73,6.73,0,0,1,3.28,2.67,20.87,20.87,0,0,0-.67,4.46,9.09,9.09,0,0,0-2.75.55l-.07,0A21,21,0,0,1,9,11.16C5.71,10.21,4.34,6.29,2.83,4.91Z"
			/></g
		></g
	></svg
>

<style lang="scss">
	:host {
		--edi-logo-fill: #ffffff;
	}

	svg {
		height: auto;
		width: 105px;

		fill: var(--edi-logo-fill);
	}
</style>
