<svelte:options tag="edi-rti-students-responsible" />

<script lang="ts">
	import dispatch from '../../../common/dispatch'
	import { get_current_component } from 'svelte/internal'

	export let items = []
	export let widget_details: any

	const component = get_current_component()

	/* Method to handle redirection to the new MTSS application if navigatemtss field is true inside the widget data 
	 Anandu 05/29/2024 */
	const handleClick = (studentId: any) => {
		dispatch(component, 'edi-rti-students-responsible:mtssnavigation', {
			studentId,
			user_id: widget_details?.userId,
			title: widget_details?.title,
		})
	}
</script>

<div class="container">
	<table>
		<tr id="heading">
			<th>Student Name</th>
			<th>
				<table>
					<tr>
						<td style="width: 40%;">Area of Need</td>
						<td style="width: 20%;">Tier</td>
						<td style="width: 40%;">Curriculum Materials</td>
					</tr>
				</table>
			</th>
		</tr>
		{#if items.length > 0}
			{#each items as data}
				<tr id="data">
					{#if data?.navigatemtss}
						<td style="cursor:pointer; color: #2757f2;" on:click={() => handleClick(data?.studentId)}
							>{data?.name}</td
						>
					{:else}
						<td>
							<a href={data?.link}>{data?.name}</a>
						</td>
					{/if}

					<td>
						<table>
							{#each data?.innercont as inner}
								<tr id="inner">
									<td style="width: 40%;">{inner?.area}</td>
									<td style="width: 20%;">{inner?.tier}</td>
									<td style="width: 40%;">{inner?.material}</td>
								</tr>
							{/each}
						</table>
					</td>
				</tr>
			{/each}
		{/if}
	</table>

	{#if items.length === 0}
		<div class="default">No Data Available</div>
	{/if}
</div>

<style lang="scss">
	.container {
		padding: 10px 10px 10px 10px;
	}

	table {
		width: 100%;
		font-family: 'Roboto';
		border-collapse: collapse;
	}

	table tr td {
		border-bottom: 1px solid #f3f3f3;
	}

	table table tr td {
		border-bottom: none;
	}

	table th:first-child {
		border-radius: 7px 0 0 7px;
	}

	table th:last-child {
		border-radius: 0 7px 7px 0;
	}

	td,
	th {
		text-align: center;
	}

	th {
		font-weight: bold;
		font-size: 15px;
		padding-top: 5px;
		padding-bottom: 5px;
		background: #f5f5f5;
	}

	#data > td,
	#inner > td {
		padding-top: 5px;
		padding-bottom: 5px;
		font-size: 14px;
	}

	a {
		text-decoration: none;
		color: #2757f2;
	}

	a:hover {
		color: #017698;
	}

	.default {
		text-align: center;
		font-family: 'Roboto';
		font-size: 15px;
	}
</style>
