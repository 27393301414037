<svelte:options tag="edi-lesson-plan-teacher-list" />

<script>
	export let items = []

	let selectedHeader = 'name'

	// Holds table sort state.  Initialized to reflect table sorted by name column ascending.
	let sortBy = { col: 'name', ascending: true }

	$: sort = (column) => {
		if (sortBy.col == column) {
			sortBy.ascending = !sortBy.ascending
		} else {
			sortBy.col = column
			sortBy.ascending = true
		}

		// Modifier to sorting function for ascending or descending
		let sortModifier = sortBy.ascending ? 1 : -1

		let sort = (a, b) => (a[column] < b[column] ? -1 * sortModifier : a[column] > b[column] ? 1 * sortModifier : 0)

		items = items.sort(sort)

		selectedHeader = column
	}
</script>

<div class="container">
	<table>
		<thead>
			<tr>
				<th
					rowspan="2"
					id="clickable"
					class:highlighted={selectedHeader === 'name' && items.length > 0}
					on:click={sort('name')}
					>Teacher Name
					{#if selectedHeader === 'name' && items.length > 0}
						<span class="order-icon">
							{@html sortBy.ascending ? '&#9661;' : '&#9651;'}
						</span>
					{/if}
				</th>
				<th colspan="2" style="font-weight: bold;">Scheduled Lessons</th>
			</tr>
			<tr>
				<th
					id="clickable"
					class:highlighted={selectedHeader === 'this_week' && items.length > 0}
					on:click={sort('this_week')}
					>This Week
					{#if selectedHeader === 'this_week' && items.length > 0}
						<span class="order-icon">
							{@html sortBy.ascending ? '&#9661;' : '&#9651;'}
						</span>
					{/if}
				</th>
				<th
					id="clickable"
					class:highlighted={selectedHeader === 'next_week' && items.length > 0}
					on:click={sort('next_week')}
					>Next Week
					{#if selectedHeader === 'next_week' && items.length > 0}
						<span class="order-icon">
							{@html sortBy.ascending ? '&#9661;' : '&#9651;'}
						</span>
					{/if}</th
				>
			</tr>
		</thead>
		<tbody>
			{#if items.length > 0}
				{#each items as data}
					<tr id="data">
						<td>
							<a href={data?.link1}>{data?.name}</a>
						</td>
						<td>
							<a href={data?.link2}>{data.this_week}</a>
						</td>
						<td>
							<a href={data?.link3}>{data.next_week}</a>
						</td>
					</tr>
				{/each}
			{/if}
		</tbody>
	</table>

	{#if items.length === 0}
		<div class="default">Please select a Building to see the list of Teachers</div>
	{/if}
</div>

<style lang="scss">
	.container {
		width: 100%;
		max-height: 500px;
		overflow: auto;
	}

	table {
		width: 100%;
		font-family: 'Roboto';
		border-collapse: collapse;
	}

	table tr td {
		border-bottom: 1px solid #f3f3f3;
	}

	table table tr td {
		border-bottom: none;
	}

	td,
	th {
		text-align: center;
	}

	th {
		font-weight: normal;
		font-size: 15px;
		padding-top: 5px;
		padding-bottom: 5px;

		background: #f5f5f5;
	}

	#data > td {
		padding-top: 5px;
		padding-bottom: 5px;
		font-size: 14px;
	}

	a {
		text-decoration: none;
		color: #2757f2;
	}

	a:hover {
		color: #017698;
	}

	.default {
		text-align: center;
		font-family: 'Roboto';
		font-size: 15px;
		padding: 15px 0px;
	}

	.highlighted {
		color: hsl(15, 100%, 45%);
	}

	#clickable {
		font-weight: bold;
		cursor: pointer;
	}
</style>
