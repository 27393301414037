<svelte:options tag="edi-footer-menu" />

<script lang="ts">
	//export let menulink: string
	export let text: string
</script>

<div class="menutext">
	<span>{text}</span>
</div>

<style lang="scss">
	.menutext {
		&:hover {
			cursor: var(--edi-footer-cursor);
		}
	}
</style>
