<svelte:options tag="edi-folder-details-form" />

<script lang="ts">
	import { onMount } from 'svelte'
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../common/dispatch'

	/* --- Props Starts --- */

	export let parent_folders: any = []
	export let selected_icon: any
	export let form_type: any
	export let folder_name: any = ''
	export let folder_description: any = ''
	export let selected_parent = ''

	// For archive button
	export let show_archive_button = false
	export let checked = false

	export let new_folder_id: any // To recieve the newly created parent folder id
	export let new_sub_folder_id: any // To recieve the newly created parent folder id
	export let new_folder_name = ''

	export let sub_folder_names: any = [] // List of all subfolder names

	export let disable_dropdown = false // To disable the parent folder dropdown select

	export let is_edit_parent_folder_clicked = false // To idenify if the parent folder edit button is clicked

	export let is_edit_subfolder_clicked = false

	/* --- Props Ends --- */

	/* --- Variables Starts --- */

	const component = get_current_component()

	let defaultParent = 'Default'

	let showIconList = false

	let folderNameBackup = ''

	let isFolderNameChanged = false

	/* --- Variables Ends --- */

	/* --- Methods Starts --- */

	onMount(() => {
		folderNameBackup = folder_name
	})

	/* Part of enabling Parent Folder dropdown
	 */
	$: if (is_edit_parent_folder_clicked) {
		disable_dropdown = true
	}

	$: if (is_edit_subfolder_clicked) {
		disable_dropdown = false
	}

	const handleChange = (selected: any) => {
		if (selected_parent === 'Default') {
			form_type = 'folder_create'
		} else if (selected_parent !== 'Default' && !is_edit_subfolder_clicked) {
			parent_folders?.forEach((item) => {
				if (item?.folderName === selected_parent) {
					new_folder_id = item?.folderId
				}
			})
			form_type = 'sub_folder_create'
		} else if (selected_parent === 'Default' && (is_edit_parent_folder_clicked || is_edit_subfolder_clicked)) {
			parent_folders?.forEach((item) => {
				if (item?.folderName === selected_parent) {
					new_folder_id = item?.folderId
				}
			})
			form_type = 'edit_folder_details'
		} else if (selected_parent !== 'Default' && (is_edit_parent_folder_clicked || is_edit_subfolder_clicked)) {
			parent_folders?.forEach((item) => {
				if (item?.folderName === selected_parent) {
					new_folder_id = item?.folderId
				}
			})
			form_type = 'edit_folder_details'
		}
	}

	const handleCreate = (form_type: any) => {
		let proceed = true

		parent_folders?.forEach((element) => {
			if (
				form_type !== 'edit_folder_details' &&
				element?.folderName?.toLowerCase() === folder_name?.toLowerCase() &&
				!is_edit_subfolder_clicked
			) {
				alert('Folder name ' + folder_name + ' already exists!')
				proceed = false
			}
		})

		sub_folder_names?.forEach((element) => {
			if (
				form_type !== 'edit_folder_details' &&
				element?.folderName?.toLowerCase() === folder_name?.toLowerCase() &&
				!is_edit_subfolder_clicked
			) {
				alert('Folder name ' + folder_name + ' already exists!')
				proceed = false
			}
		})

		if (form_type === 'folder_create') {
			new_folder_id = null
		}

		if (form_type === 'sub_folder_create') {
			new_sub_folder_id = null
		}

		if (folder_name.toLowerCase() !== folderNameBackup.toLowerCase()) {
			isFolderNameChanged = true
		} else {
			isFolderNameChanged = false
		}

		if (proceed && isFolderNameChanged && (form_type === 'folder_create' || form_type === 'edit_folder_details')) {
			parent_folders?.forEach((element) => {
				if (element?.folderName?.toLowerCase() === folder_name?.toLowerCase()) {
					alert('Folder name ' + folder_name + ' already exists!')
					proceed = false
				}
			})

			sub_folder_names?.forEach((element) => {
				if (element?.folderName?.toLowerCase() === folder_name?.toLowerCase()) {
					alert('Folder name ' + folder_name + ' already exists!')
					proceed = false
				}
			})
		}

		if (proceed) {
			dispatch(component, 'edi-folder-details-form:handle_create', {
				form_type,
				selected_parent,
				folder_name,
				selected_icon,
				folder_description,
				isArchived: checked,
				folder_id: new_folder_id ?? null,
				sub_folder_id: new_sub_folder_id ?? null,
			})
		}
	}

	const handleCancel = (form_type: any) => {
		if (form_type !== 'edit_folder_details') {
			folder_name = ''
			folder_description = ''
			selected_icon = 'Folder'
		}

		dispatch(component, 'edi-folder-details-form:handle_cancel', {
			form_type,
		})
	}

	const openIconList = () => {
		showIconList = !showIconList
	}

	const handleIconSelection = (e: CustomEvent) => {
		selected_icon = e?.detail?.selectedIcon
		showIconList = false
	}

	const handleArchive = () => {
		if (checked) {
			if (
				confirm(
					'Assessments and subfolders under ' +
						folder_name +
						'  will be archived and will no longer be available on the Data Depot menu.'
				)
			) {
				return
			} else {
				checked = false
			}
		}
	}

	/* --- Methods Ends --- */
</script>

<div class="create-folder">
	<div>
		{#if form_type === 'sub_folder_create'}
			<h3>Create Subfolder</h3>
		{:else if form_type === 'edit_folder_details'}
			<h3>Edit Folder Details</h3>
		{:else}
			<h3 />
		{/if}
	</div>
	<div class="create-folder-in">
		<div class="create-folder-left">
			<div class="form-group">
				<label for="menu-preview">Menu Preview</label>
				<div class="menu-preview">
					<span><edi-icon-picker icon_name={selected_icon} /></span>
					<strong>
						<h3>
							{#if folder_name}
								{folder_name}
							{:else}
								Folder Name
							{/if}
						</h3>
					</strong>
					{#if folder_description}
						{folder_description}
					{:else}
						Folder Description
					{/if}
				</div>
			</div>
		</div>

		<div class="create-folder-right">
			<div class="form-group width-50">
				<label for="">Parent Folder <small>(Optional)</small></label>
				<select
					bind:value={selected_parent}
					on:change={() => handleChange(selected_parent)}
					disabled={disable_dropdown}
				>
					<option value={defaultParent}>No Parent</option>
					{#each parent_folders as parent}
						<option value={parent?.folderName}>
							{parent.folderName}
						</option>
					{/each}
				</select>
			</div>
			<div class="form-group width-50">
				<label for="">Folder Name <small>(Required)</small></label>
				<input
					type="text"
					placeholder="Maximum 25 characters allowed"
					maxlength="25"
					bind:value={folder_name}
					required
				/>
			</div>
			<div class="form-group width-50">
				<label for="">Folder Icon</label>
				<div class="dropdown-box">
					<span class="dropdown-menu" on:click={openIconList}
						><edi-icon-picker icon_name={selected_icon} /> {selected_icon}</span
					>
					{#if showIconList}
						<div class="dropdown-content">
							<edi-icon-list on:edi-icon-list:icon_selected={handleIconSelection} />
						</div>
					{/if}
				</div>
			</div>
			<div class="form-group width-50">
				<label for="">Folder Description</label>
				<input
					type="text"
					placeholder="Maximum 40 characters allowed"
					maxlength="40"
					bind:value={folder_description}
				/>
			</div>

			{#if show_archive_button}
				<div class="form-group width-50">
					<label for="">Archive Folder</label>
					<label class="switch">
						<input type="checkbox" bind:checked on:change={handleArchive} />
						<span class="slider" />
					</label>
				</div>
			{/if}
		</div>
	</div>

	<div class="btn-group">
		{#if form_type === 'folder_create'}
			<button
				class={folder_name !== '' ? 'btn-1' : 'btn-1-disabled'}
				disabled={folder_name === ''}
				on:click={() => handleCreate(form_type)}
				>Create Folder
			</button>
		{/if}

		{#if form_type === 'sub_folder_create'}
			<span>
				<button class="btn-cancel" on:click={() => handleCancel(form_type)}>Cancel</button>
			</span>
			<span>
				<button
					class={folder_name !== '' ? 'btn-1' : 'btn-1-disabled'}
					disabled={folder_name === ''}
					on:click={() => handleCreate(form_type)}>Create</button
				>
			</span>
		{/if}

		{#if form_type === 'edit_folder_details'}
			<span>
				<button class="btn-cancel" on:click={() => handleCancel(form_type)}>Cancel</button>
			</span>
			<span>
				<button
					class={folder_name !== '' ? 'btn-1' : 'btn-1-disabled'}
					disabled={folder_name === ''}
					on:click={() => handleCreate(form_type)}>Update</button
				>
			</span>
		{/if}
	</div>
</div>

<style lang="scss">
	@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');
	* {
		box-sizing: border-box;
	}
	.create-folder {
		background: #ffffff;
		box-shadow: 0px 0px 20px rgba(62, 116, 152, 0.12);
		border-radius: 12px;
		padding: 25px;
		margin-bottom: 25px;
	}

	.create-folder-in {
		display: flex;
	}

	.create-folder-left {
		width: 30%;
		padding-right: 10px;
	}

	.form-group {
		margin-bottom: 16px;
	}
	.form-group label {
		color: #435765;
		margin-bottom: 8px;
		display: block;
		margin-bottom: 10px;
	}
	.form-group label small {
		color: #9e9e9e;
	}
	.form-group input,
	.form-group select,
	.form-group .dropdown-menu {
		width: 100%;
		height: 50px;
		padding: 12px;
		background: #eff4f9;
		border: none;
		border-radius: 7px;
		outline: none;
		display: block;
	}
	.form-group .dropdown-menu {
		display: flex;
		align-items: center;
		gap: 10px;
	}
	.dropdown-box {
		position: relative;
	}
	.dropdown-content {
		position: absolute;
		top: 100%;
		box-shadow: 0px 0px 20px rgb(62 116 152 / 12%);
		background: #fff;
		z-index: 9;
		border-radius: 12px;
		padding: 15px;
		width: 100%;
	}
	.menu-preview {
		border: 1px solid #e2e9f3;
		border-radius: 10px;
		padding: 15px;
		font-size: 13px;
		color: #727c89;
		height: 150px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.menu-preview strong {
		display: block;
		color: #263b54;
		font-weight: 500;
		margin-bottom: 5px;
	}
	.menu-preview span {
		padding: 5px;
		background: rgba(226, 233, 243, 0.6);
		border-radius: 5px;
		width: 40px;
		height: 40px;
		font-size: 20px;
		margin-bottom: 10px;
		color: #a2b7d4;
		padding: 12px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.width-50 {
		width: 50%;
	}
	.create-folder-right {
		display: flex;
		flex-wrap: wrap;
		width: 70%;
	}
	.create-folder-right .form-group {
		padding: 0 10px;
	}
	.btn-group {
		text-align: right;
	}
	.btn-1,
	.btn-cancel,
	.btn-1-disabled {
		padding: 0 25px;
		height: 45px;
		border-radius: 7px;
	}

	.btn-1 {
		color: #fff;
		border: 1px solid #566963;
		background: linear-gradient(180deg, #8aa39c 0%, #6c7c7c 66.98%);
	}

	.btn-1,
	.btn-cancel {
		cursor: pointer;
	}

	.btn-cancel {
		background: #f2f2f2;
		color: #767676;
		border: none;
	}

	.preview-box {
		box-sizing: border-box;

		position: absolute;

		background: #ffffff;
		border: 1px solid #e2e9f3;
		border-radius: 5px;
	}

	.first-row {
		display: flex;
	}

	.second-row {
		display: flex;
	}

	/* For archive button **/
	.switch {
		position: relative;
		display: inline-block;
		width: 60px;
		height: 34px;
	}

	.switch input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: 0.4s;
		transition: 0.4s;
		border-radius: 34px;
	}

	.slider:before {
		position: absolute;
		content: '';
		height: 26px;
		width: 26px;
		left: 4px;
		bottom: 4px;
		background-color: white;
		-webkit-transition: 0.4s;
		transition: 0.4s;
		border-radius: 50%;
	}

	input:checked + .slider {
		background-color: #2196f3;
	}

	input:checked + .slider {
		box-shadow: 0 0 1px #2196f3;
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(26px);
		-ms-transform: translateX(26px);
		transform: translateX(26px);
	}
</style>
