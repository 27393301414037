<svelte:options tag="edi-login" />

<script lang="ts">
	import '../Logo'
</script>

<svelte:head>
	<link
		href="https://fonts.googleapis.com/css2?family=Raleway:wght@100&family=Roboto:wght@100&display=swap"
		rel="stylesheet"
	/>
</svelte:head>

<div class="triangle" />
<div class="side-bar">
	<slot name="logo">
		<edi-logo />
	</slot>

	<div class="title">
		<div class="titlestart">
			<span class="titlestart__first">Welcome to </span>
			<span class="titlestart__second">EDInsight</span>
		</div>
		<div><span class="titlebottom">Analyics</span></div>
	</div>

	<div>
		<slot name="logindetails" />
	</div>
</div>

<style lang="scss">
	@import '@material/elevation/mdc-elevation';
	@import '../../styles/variables';
	@import '../../styles/mixins';

	:host {
		--edi-login-background-color: var(--edi-dark);
	}
	* {
		box-sizing: border-box;
	}

	.side-bar {
		padding: 2em;
		height: 100vh;
		width: 68vw;
		background-color: white;
		margin: 0 0 0 auto;
		background-color: #ecf0f1;
		box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		// justify-content: center;
		@include md {
			width: 43vw;
		}
	}

	.title {
		padding-top: 5rem;
		color: rgba(38, 59, 84, 1);
		font-weight: bold;
		position: relative;
	}
	.titlestart {
		font-size: 43px;
		&__first {
			font-weight: 100;
		}
		&__second {
			font-weight: bold;
		}
	}
	.titlebottom {
		//float: right;
		//padding-right: 2em;
		//font-size: 20px;

		font-weight: 300;
		color: #34495e;
		font-size: 1.4em;
		position: absolute;
		bottom: -23px;
		right: 0;
	}
	.triangle {
		z-index: -1;
		position: absolute;
		bottom: -10px;
		right: 0;
		border-color: transparent white;
		border-color: transparent #ecf0f1;
		border-style: solid;
		border-width: 60vh 60vw 0vw 0vw;
		height: 0px;
		width: 0px;
	}

	edi-logo {
		--edi-logo-fill: var(--edi-login-background-color);
		--edi-logo-width: 175px;
	}
</style>
