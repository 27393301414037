<svelte:options tag="edi-sdd-favorite-star" />

<script lang="ts">
	export let variant: 'filled' | 'empty' = 'empty'
</script>

<svg class="star" width="14" height="13" viewBox="0 0 14 13" xmlns="http://www.w3.org/2000/svg">
	{#if variant === 'filled'}
		<path
			d="M7.00065 10.5133L11.1207 12.9999L10.0273 8.31325L13.6673 5.15992L8.87398 4.74659L7.00065 0.333252L5.12732 4.74659L0.333984 5.15992L3.96732 8.31325L2.88065 12.9999L7.00065 10.5133Z"
			fill="#317FB4"
		/>
	{:else}
		<path
			d="M13.6666 5.15967L8.87325 4.74634L6.99992 0.333008L5.12658 4.75301L0.333252 5.15967L3.97325 8.31301L2.87992 12.9997L6.99992 10.513L11.1199 12.9997L10.0333 8.31301L13.6666 5.15967ZM6.99992 9.26634L4.49325 10.7797L5.15992 7.92634L2.94659 6.00634L5.86659 5.75301L6.99992 3.06634L8.13992 5.75967L11.0599 6.01301L8.84659 7.93301L9.51325 10.7863L6.99992 9.26634Z"
			fill="#112A3B"
		/>
	{/if}
</svg>

<style lang="scss">
	svg {
		padding: 0.25rem;
		cursor: pointer;
	}
</style>
