<svelte:options tag="edi-dashboard-button" />

<script lang="ts">
	export let buttontext: string
</script>

<div class="container">
	<div>
		<button class="btn">{buttontext}</button>
	</div>
</div>

<style lang="scss">
	@import '@material/elevation/mdc-elevation';
	@import '../../styles/variables';
	@import '../../styles/mixins';
	:host {
		--edi-btn-background-color: var(--edi-dark);
	}
	.container {
		box-sizing: border-box;
		margin-top: 2.5rem;

		color: var(--edi-btn-background-color);
		font-family: 'Roboto';
		padding: 0 1rem 1rem 0;
		@include md {
			margin-top: 2rem;
		}
	}

	.btn {
		background-color: var(--edi-btn-background-color);
		color: white;
		font-weight: 600;
		padding: 10px;
		border-style: none;
		cursor: pointer;
	}
</style>
