<svelte:options tag="edi-barchartm" />

<script lang="ts">
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../common/dispatch'
	import ChartDataLabels from 'chartjs-plugin-datalabels'

	const component = get_current_component()

	import { Chart, registerables } from 'chart.js'
	import { onMount } from 'svelte'
	Chart.register(...registerables)
	let canvas: HTMLCanvasElement

	export let id: any
	export let user_id: any
	export let title: any // Widget Title

	export let items: any
	export let charttype: string
	export let dropdownitems: any = []

	// Chart axes title props
	export let x_axis_title: any
	export let y_axis_title: any

	// Chart tooltip label prop
	export let tooltip_label: any

	export let button_text: any
	export let button_link: any

	export let show_legend: any
	export let widget_heading: any

	export let primary_heading: any // For Top 10 Infraction bar chart widget

	let myChart: any

	// Binding values to this variable when selectiing an item from dropdown
	let selected: any = dropdownitems?.map((x) => x?.dropdown?.filter((a) => a?.selected))

	// Field to hold values of dropdown details for the api side
	let label: any
	let selectedDropdown: any
	let dropdownID: any
	let ddlList: any[] = []
	let isValueChanged: any

	// Fields for selecting 'Number of Absence' with custom range for 'Students With Multiple Absences' widget
	let custom_start = 0 // Custom range starting value
	let custom_end = 0 // Custom range ending value
	let showCustomRange = false

	let changedDropdownLabel = ''

	$: createChart(canvas, items, charttype)
	const createChart = (node: HTMLCanvasElement, items, charttype) => {
		if (myChart) {
			myChart.destroy()
		}
		if (items && items.length !== 0) {
			myChart = new Chart(node, {
				type: charttype,
				data: items,

				options: {
					resposnsive: true,
					maintainAspectRatio: false,
					barPercentage: 1,
					categoryPercentage: 0.9,
					parsing: {
						xAxisKey: 'months',
						yAxisKey: 'sales',
					},

					scales: {
						yAxes: {
							suggestedMax: 10 /* The maximum value suggested for the y axis ticks. 
							If the dataset contains a larger value 'suggestedMax' will be ignored. */,
							// grace: '10%',  Add 10% space from top level
							title: {
								display: true,
								text: y_axis_title,
								font: {
									size: 15,
									weight: 'bold',
								},
							},
							ticks: {
								precision: 0,
								font: {
									weight: 'bold',
								},
							},
							grid: {
								color: 'rgba(242, 242, 242, 0.5)',
							},
						},
						xAxes: {
							title: {
								display: true,
								text: x_axis_title,
								font: {
									size: 15,
									weight: 'bold',
								},
							},
							ticks: {
								autoSkip: false,
								font: {
									weight: 'bold',
								},
							},
							grid: {
								color: 'rgba(242, 242, 242, 0.5)',
							},
						},
					},

					plugins: {
						legend: {
							display: show_legend ? true : false,
							position: 'bottom',
							onHover: (event) => {
								event.native.target.style.cursor = 'pointer'
							},

							onLeave: (event) => {
								event.native.target.style.cursor = 'default'
							},

							labels: {
								font: {
									size: 14,
									weight: 'bold',
								},
							},
						},

						tooltip: {
							displayColors: false, // To remove the color boxes inside the tooltip
							intersect: false, //  Set the intersect property to false to always get the tooltip without needing to intersect the bar exactly
							yAlign: 'top',
							callbacks: {
								label: function (context) {
									let label = ''

									if (context.parsed.y !== null) {
										let isIcludedBreakPoint = context?.raw?.tip?.includes('<br />') // Returs true if the tip field contains '<br />'.'
										if (isIcludedBreakPoint) {
											label = context?.raw?.tip?.split('<br />').map(String) // Spliting tip field and maping into a string array.
										} else {
											label = tooltip_label
											label += context?.parsed?.y

											// Some widgets show % value along with the count in the tooltip
											label = context?.raw?.tip ? context?.raw?.tip : label
										}
									}
									return label
								},
							},
						},

						// Some widgets show % values on top of the bar Eg:- PA Grad Pathways
						datalabels: {
							anchor: 'end',
							align: 'top',
							formatter: (value, context) => {
								let datavalue = value?.percentage ? value?.percentage : ''
								return datavalue
							},
							font: {
								weight: 'bold',
							},
							/* In some scenario the PA Grad Pathway widget's bar height is very low.
							   In that case drill-down over the that particular data item is not possible.
							   So we added 'listeners' option to register callbacks.
							*/
							listeners: {
								click: function (context) {
									const link = context?.dataset?.data[context?.dataIndex]?.link
									if (link) {
										window.location = link
									}
								},
							},
						},
					},

					layout: {
						padding: {
							top: 25,
						},
					},

					onClick: (e) => {
						const activePoints = myChart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true)
						if (activePoints.length) {
							const firstPoint = activePoints[0]

							const value = myChart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index]

							//window.open(value.link)
							window.location = value.link
						}
					},

					onHover: (event, chartElement) => {
						event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
					},
				},
				plugins: [ChartDataLabels],
			})

			genGradient()
		}
	}

	function genGradient() {
		const ctx = myChart.ctx

		var gradient = {}

		const bgColor = myChart?.config?.data?.datasets[0]?.backgroundColor

		bgColor?.map((itm, index) => {
			if (typeof itm !== 'object') {
				gradient[index] = ctx?.createLinearGradient(0, 0, 0, 600)

				gradient[index]?.addColorStop(0, itm)

				gradient[index]?.addColorStop(1, hexToRgbA(itm))

				if (gradient[index] !== undefined) {
					myChart.config.data.datasets[0].backgroundColor[index] = gradient[index]
					myChart.update()
				}
			}
		})
	}

	// Method to convert hex color code to rgba code
	function hexToRgbA(hex) {
		var c
		if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
			c = hex.substring(1).split('')
			if (c.length == 3) {
				c = [c[0], c[0], c[1], c[1], c[2], c[2]]
			}
			c = '0x' + c.join('')
			return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0)'
		}
		throw new Error('Bad Hex')
	}

	onMount(() => {
		createChart(canvas, items, charttype)
	})

	const handlechange = async (selectedItem: any, dropdownLabel: any) => {
		if (title === 'Students With Multiple Absences' && selectedItem[1]?.value === 'Custom Range') {
			showCustomRange = true
		} else {
			showCustomRange = false
			custom_start = 0
			custom_end = 0
		}
		changedDropdownLabel = dropdownLabel
		handleSubmit()
	}

	const handleClick = (url: any) => {
		window.location = url
	}

	document.addEventListener('bar_chart_data_changed', (e: any) => {
		if (e?.detail?.widgetID === id) {
			button_link = e?.detail?.newLink
			button_text = e?.detail?.newButtonText
			items = e?.detail?.datachanged
			dropdownitems = e?.detail?.dropdownchanged ? e?.detail?.dropdownchanged : []
			createChart(canvas, items, charttype)
		}
	})

	const handleSubmit = () => {
		ddlList = []

		selected?.forEach((element, index) => {
			label = dropdownitems[index]?.label

			if (label == changedDropdownLabel) {
				isValueChanged = true
			} else {
				isValueChanged = false
			}

			selectedDropdown = element?.value
			dropdownID = element?.id
			ddlList = [...ddlList, { label, selectedDropdown, dropdownID, isValueChanged }]
		})

		dispatch(component, 'edi-barchartm:dropdownchange', {
			id,
			myChart,
			ddlList,
			widgettype: 'bar',
			user_id,
			custom_start,
			custom_end,
		})
	}
</script>

{#if dropdownitems}
	<div class="select">
		{#each dropdownitems as item, index}
			<div>
				<span>{item.label}:</span>
				<select bind:value={selected[index]} on:change={() => handlechange(selected, item?.label)}>
					{#each item.dropdown as dropdown}
						<option selected={dropdown?.selected} value={dropdown}>
							{dropdown.value}
						</option>
					{/each}
				</select>
			</div>
			<br />
		{/each}
		{#if showCustomRange}
			<div class="custom-range">
				<span>Custom Range:</span>
				<input type="number" bind:value={custom_start} min="0" />
				<input type="number" bind:value={custom_end} min="0" />
				<button on:click={() => handleSubmit()}>GO</button>
			</div>
		{/if}
	</div>
{/if}

{#if items}
	{#if items?.length === 0}
		<div>
			{#if primary_heading !== ''}
				<h3>{primary_heading}</h3>
			{/if}
		</div>
		<div>
			{#if widget_heading !== ''}
				<h4>{widget_heading}</h4>
			{/if}
		</div>
		<div class="default">No Data Available</div>
	{:else}
		<div class="container">
			<div>
				{#if primary_heading !== ''}
					<h3>{primary_heading}</h3>
				{/if}
			</div>
			{#if widget_heading !== ''}
				<h4>{widget_heading}</h4>
			{/if}
			<div style="width: 100%; height: 400px;">
				<canvas bind:this={canvas} />
			</div>

			{#if button_link !== ''}
				<div class="nav">
					<button on:click={() => handleClick(button_link)}>{button_text}</button>
				</div>
			{/if}
		</div>
	{/if}
{/if}

<style lang="scss">
	@import '../../styles/variables';
	@import '../../styles/mixins';
	@import '@material/elevation/mdc-elevation';

	:host {
		--edi-chart-background-color: var(--edi-dark);
	}

	.container {
		width: 100%;
	}

	.container button {
		margin: auto;
		display: block;
		background-color: White;
		border: 1px solid black;
		cursor: pointer;
	}
	select {
		height: 20px;
		width: 55%;
		margin-right: 10%;
		border: none;
		border-radius: 5px;
		border-color: #f5f5f5;

		background: #f5f5f5;
		color: #424040;

		font-family: 'Roboto';
		font-style: normal;
	}
	.select {
		text-align: right;

		font-family: 'Roboto';
		font-style: normal;
		font-weight: bold;
		line-height: 19px;

		color: #424040;
	}

	.custom-range {
		margin-right: 10%;

		& button {
			font-family: 'Roboto';
			cursor: pointer;
			border: none;
			border-radius: 5px;
			color: black;
		}
	}

	input {
		width: 23%;
		border: none;
		border-radius: 5px;
		background: #f5f5f5;
		color: #424040;

		font-family: 'Roboto';
	}

	canvas {
		padding-right: 15px;
	}

	.nav {
		font-style: normal;
		text-align: center;
		margin-bottom: 5px;

		& button {
			font-family: 'Roboto';
			font-weight: bold;
			background-color: #e7e7e7;
			color: black;
			border: none;
			border-radius: 5px;
			padding: 3px 5px;
			text-align: center;
			font-size: 13px;
			cursor: pointer;
		}
	}

	h4 {
		font-family: 'Roboto';
		text-align: center;
	}

	h3 {
		font-family: 'Roboto';
	}

	.default {
		text-align: center;
		font-family: 'Roboto';
		font-size: 15px;
	}
</style>
