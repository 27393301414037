<svelte:options tag="edi-pagination" />

<script lang="ts">
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../../common/dispatch'

	const component = get_current_component()

	export let current_page
	export let last_page
	export let from
	export let to
	export let total
	export let per_page: any

	export let page_sizes: any = []

	export let max_left: any
	export let max_right: any

	// Returns an array of numbers starting from max_left and max_right(both are included)
	// Example - If the value max_left = 3 and max_right = 7, the method will return [3,4,5,6,7]
	function range(min, max) {
		return Array.from({ length: max - min + 1 }, (_, i) => min + i)
	}

	function changePage(page) {
		if (page !== current_page) {
			dispatch(component, 'edi-pagination:change', { page, per_page })
		}
	}

	const handlePageSize = async (per_page: any) => {
		dispatch(component, 'edi-pagination:change', { per_page })
	}
</script>

<nav class="pagination">
	<ul>
		{#if current_page !== 1}
			<li>
				<button on:click={() => changePage(1)}>
					<span aria-hidden="true">First</span>
				</button>
			</li>
		{/if}
		<li class={current_page === 1 ? 'disabled' : ''}>
			<button on:click={() => changePage(current_page - 1)}>
				<span aria-hidden="true">«</span>
			</button>
		</li>

		{#each range(max_left, max_right) as page}
			<li class={page === current_page ? 'active' : ''}>
				<button on:click={() => changePage(page)}>{page}</button>
			</li>
		{/each}
		<li class={current_page === last_page ? 'disabled' : ''}>
			<button on:click={() => changePage(current_page + 1)}>
				<span aria-hidden="true">»</span>
			</button>
		</li>
		{#if current_page !== last_page}
			<li>
				<button on:click={() => changePage(last_page)}>
					<span aria-hidden="true">Last</span>
				</button>
			</li>
		{/if}
	</ul>
</nav>

<div class="pagination_bottom">
	<span>
		Page Size:
		<select bind:value={per_page} on:change={() => handlePageSize(per_page)}>
			{#each page_sizes as size}
				<option value={size}>
					{size}
				</option>
			{/each}
		</select>
	</span>

	<span>
		<code><strong>{total}</strong></code> items in <code><strong>{last_page}</strong></code> pages
	</span>
</div>

<style lang="scss">
	.pagination {
		display: flex;
		justify-content: center;
	}
	.pagination ul {
		display: flex;
		padding-left: 0;
		list-style: none;
	}
	.pagination li button {
		position: relative;
		display: block;
		padding: 0.5rem 0.75rem;
		margin-left: -1px;
		line-height: 1.25;
		background-color: #fff;
		border: 1px solid #dee2e6;
	}

	.pagination li.active button {
		color: #fff;
		background-color: #007bff;
		border-color: #007bff;
	}

	.pagination li.disabled button {
		color: #6c757d;
		pointer-events: none;
		cursor: auto;
		border-color: #dee2e6;
	}

	.pagination li:not(.active) button:hover {background-color: #ddd;}

	.pagination_bottom {
		text-align: center;
	}

	.pagination_bottom select {
		height: 20px;
		border: none;
		border-radius: 3px;
	}
</style>
