<svelte:options tag="edi-overlay" />

<script lang="ts">
	export let open: boolean = false
</script>

<div
	class="overlay"
	class:overlay--open={open}
/>

<style lang="scss">
	:host {
		--edi-overlay-z-index: 500
	}

	.overlay {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: black;
		opacity: 0;
		visibility: hidden;
		z-index: var(--edi-overlay-z-index);
		transition: all 0.25s ease-in;

		&--open {
			opacity: 0.5;
			visibility: visible;
		}
	}
</style>
