<svelte:options tag="edi-icon-button" />

<script lang="ts">
	import '@material/mwc-icon'

	export let icon: string

</script>

<button>
	<mwc-icon>{icon}</mwc-icon>
</button>

<style lang="scss">
	@import '../../styles/variables';
	@import '../../styles/mixins';
	@import '@material/elevation/mdc-elevation';

	mwc-icon {
		color: white;
	}

	button {
		cursor: pointer;
		appearance: none;
		box-sizing: border-box;
		background: rgba(38, 59, 84, 0.5);
		height: 42px;
		width: 42px;
		border-radius: 100%;
		padding: 1rem;
		border: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: background-color .25s ease-in;

		&:hover {
			transition: background-color .25s ease-out;
			background: var(--edi-dark);
		}
	}
</style>
