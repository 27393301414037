<svelte:options tag="edi-logindetails" />

<script lang="ts">
	import '../Logo'
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../common/dispatch'

	let username: string
	let password: string
	const component = get_current_component()

	const handleClick = (username, password) => {
		dispatch(component, 'edi-logindetails:login-click', { username, password })
	}
</script>

<div class="container">
	<div class="container__title">
		<span>Harris School District</span>
	</div>
	<table>
		<tr>
			<td class="inputlabel">Username:</td>
			<td><input type="text" bind:value={username} /></td>
		</tr>
		<tr>
			<td class="inputlabel">Password:</td>
			<td><input type="password" bind:value={password} /></td>
		</tr>
	</table>
	<div class="container__btn">
		<button class="lgnbutton" on:click={() => handleClick(username, password)}>Login</button>
	</div>
	<div class="link">
		<slot name="links" />
		<!-- <span>Forgot your username/Password?</span>
		<span>Register Account For First Use</span> -->
	</div>

	<div>
		<slot name="login-account" />
	</div>
</div>

<style lang="scss">
	@import '@material/elevation/mdc-elevation';
	@import '../../styles/variables';
	@import '../../styles/mixins';
	:host {
		--edi-logindetail-background-color: var(--edi-dark);
	}
	.container {
		box-sizing: border-box;
		background: #f5f6f6;
		margin-top: 2.5rem;

		color: var(--edi-logindetail-background-color);
		font-family: 'Roboto';
		padding: 2rem 5rem 2rem 2rem;
		margin-left: 1.5em;
		@include md {
			margin-top: 2rem;
		}
		&__title {
			font-size: 18px;
			font-weight: bold;
			font-style: italic;
			text-align: center;
			padding-left: 5rem;
		}
		&__btn {
			text-align: center;
			padding-left: 5rem;
		}
	}
	.logincontent {
		display: flex;
		justify-content: space-evenly;
		width: 64%;
	}
	.inputlabel {
		float: right;
		font-size: 14px;
		font-weight: 600;
	}
	input {
		margin-left: 2rem;
		border-radius: 5px;
		//width: 270px;
		width: 28.5vw;
		height: 20px;
		@include md {
			width: 17.5vw;
		}
	}
	table {
		border-spacing: 0 1em;
	}
	.lgnbutton {
		width: 38%;
		background-color: var(--edi-logindetail-background-color);
		color: white;
		font-style: italic;
		font-weight: 600;
		padding: 5px;
		border-style: none;
		cursor: pointer;
	}
	.link {
		display: grid;
		text-align: center;
		font-style: italic;
		color: black;
		padding-top: 1.5em;
		padding-bottom: 2em;
		cursor: pointer;
		margin-left: 5rem;
	}
</style>
