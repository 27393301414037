<svelte:options tag="edi-early-warning" />

<script lang="ts">
	// Headings prop
	export let headings = []

	export let items = []

	export let info: any

	const handleClick = (link: any) => {
		if (link) {
			window.location = link
		}
	}
</script>

<div class="container">
	<div class="grid-container">
		<div class="heading" id="heading">
			{#each headings as heading}
				<div>{heading}</div>
			{/each}
		</div>

		{#if items.length > 0}
			<div class="data">
				{#each items as data}
					<div class={data?.link ? 'clickable' : ''} on:click={() => handleClick(data?.link)}>
						{data?.identification}
					</div>
				{/each}
			</div>
		{:else}
			<div class="default">No Data Available</div>
		{/if}
	</div>

	{#if items.length > 0}
		<div>
			<p>{info}</p>
		</div>
	{/if}
</div>

<style lang="scss">
	.container {
		padding: 10px 10px 10px 10px;
	}

	.heading {
		grid-area: heading;
		display: grid;
		grid-template-columns: 1fr;
	}

	.heading > div {
		padding-top: 5px;
		padding-bottom: 5px;
	}

	#heading {
		font-weight: bold;
		background: #f5f5f5;
		border-radius: 7px;
	}

	.data {
		grid-area: data;
		display: grid;
		grid-template-columns: 1fr;
	}

	.data > div {
		padding-top: 5px;
		padding-bottom: 5px;
		border-bottom: 1px solid #f3f3f3;
	}

	.grid-container {
		width: 100%;
		display: grid;
		grid-template-areas:
			'heading'
			'data';
	}

	.grid-container > div {
		background-color: rgba(255, 255, 255, 0.8);
		text-align: center;
		padding: 1px;
		font-family: 'Roboto';
		font-size: 15px;
	}

	p {
		font-weight: bold;
		color: rgba(77, 159, 225, 0.65);
	}

	.default {
		text-align: center;
		font-family: 'Roboto';
		font-size: 15px;
	}

	.clickable {
		cursor: pointer;
		color: #2757f2;
	}

	.clickable:hover {
		color: #017698;
	}
</style>
