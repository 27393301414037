<svelte:options tag="edi-rti-alerts" />

<script lang="ts">
	// Headings prop
	export let headings = []

	export let items = []
</script>

<div class="container">
	<div class="grid-container">
		<div class="heading" id="heading">
			{#each headings as heading}
				<div>{heading}</div>
			{/each}
		</div>

		{#if items.length > 0}
			<div class="data">
				{#each items as data}
					<div>{data?.type}</div>
					<div>
						{#if data?.count == 0}
							<span>{data?.count}</span>
						{:else}
							<a href={data?.link}>{data?.count}</a>
						{/if}
					</div>
				{/each}
			</div>
		{:else}
			<div class="default">No Data Available</div>
		{/if}
	</div>

	{#if items.length > 0}
		<div>
			<p>*Students may have more than one intervention plan or area of need for a given alert type.</p>
		</div>
	{/if}
</div>

<style lang="scss">
	.container {
		padding: 10px 10px 10px 10px;
	}

	.heading {
		grid-area: heading;
		display: grid;
		grid-template-columns: 10fr 2fr;
	}

	.heading > div {
		padding-top: 5px;
		padding-bottom: 5px;
	}

	#heading {
		font-weight: bold;
		background: #f5f5f5;
		border-radius: 7px;
	}

	.data {
		grid-area: data;
		display: grid;
		grid-template-columns: 10fr 2fr;
	}

	.data > div {
		padding-top: 5px;
		padding-bottom: 5px;
		border-bottom: 1px solid #f3f3f3;
	}

	.grid-container {
		width: 100%;
		display: grid;
		grid-template-areas:
			'heading'
			'data';
	}

	.grid-container > div {
		background-color: rgba(255, 255, 255, 0.8);
		text-align: center;
		padding: 1px;
		font-family: 'Roboto';
		font-size: 15px;
	}

	p {
		color: rgba(86, 118, 145, 0.65);
		font-size: 15px;
		font-weight: bold;
		padding-top: 1em;
		text-align: center;
	}

	a {
		text-decoration: none;
		color: #2757f2;
	}

	a:hover {
		color: #017698;
	}

	.default {
		text-align: center;
		font-family: 'Roboto';
		font-size: 15px;
	}
</style>
