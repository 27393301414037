<svelte:options tag="edi-unread-iep-documents" />

<script lang="ts">
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../../common/dispatch'

	const component = get_current_component()

	// Headings prop
	export let headings = []

	export let items = []

	export let button_link: any

	let selectedHeader = 'studentname'

	// Holds table sort state.  Initialized to reflect table sorted by id column ascending.
	let sortBy = { col: 'studentname', ascending: true }

	const handleClick = (url: any) => {
		window.location = url
	}

	$: sort = (column) => {
		if (sortBy.col == column) {
			sortBy.ascending = !sortBy.ascending
		} else {
			sortBy.col = column
			sortBy.ascending = true
		}

		// Modifier to sorting function for ascending or descending
		let sortModifier = sortBy.ascending ? 1 : -1

		let sort = (a, b) => (a[column] < b[column] ? -1 * sortModifier : a[column] > b[column] ? 1 * sortModifier : 0)

		items = items.sort(sort)

		selectedHeader = column
	}

	const handleFileDownload = (downloadlink: any, downloadArg: any) => {
		// Example
		//downloadlink="/Edinsight/API/StudentLists/DownloadIEPDocuments?StudentId=123456&DisplayStudentId=566778&UserId=2&Filename=filename.pdf&Container=2&WidgetId=2"

		const mainLink = downloadlink.split('&') // Splitting downloadlink using '&' character and assigning into mainLink
		const subLink = mainLink[0].split('?') // Splitting mainLink using '?' character and taking the '0'th item into subLink

		const studentID = subLink[1].split('=')[1] // Splitting the item in '1'st index of subLink array using '=' character and taking its '1'st item of subLink's sub array
		const displayStudentID = mainLink[1].split('=')[1] // Splitting the item in '1'st index of mainLink array using '=' character and taking its '1'st item of mainLink's sub array
		const userID = mainLink[2].split('=')[1]
		const fileName = mainLink[3].split('=')[1]

		dispatch(component, 'edi-unread-iep-documents:pdfdownload', {
			downloadlink,
			studentID,
			displayStudentID,
			userID,
			fileName,
			downloadArg,
		})
	}

	document.addEventListener('unreadiepdocument_update', (e: any) => {
		items = e.detail.datachanged
	})
</script>

<div class="container">
	<table>
		<tr>
			{#each headings as heading}
				<th
					id="clickable"
					class:highlighted={selectedHeader === heading.key && items.length > 0}
					on:click={() => {
						sort(heading.key)
					}}
					>{heading.value}
					{#if heading.key === selectedHeader && items.length > 0}
						<span class="order-icon">
							{@html sortBy.ascending ? '&#9661;' : '&#9651;'}
						</span>
					{/if}
				</th>
			{/each}
		</tr>
		{#if items.length > 0}
			{#each items as data}
				<tr>
					<td>
						<a href={data?.link}>{data?.studentname}</a>
					</td>
					<td class="file" on:click={() => handleFileDownload(data?.downloadlink, data?.downloadArg)}
						>{data?.filename}
					</td>
				</tr>
			{/each}
		{/if}
	</table>

	{#if items.length === 0}
		<div class="default">No Data Available</div>
	{:else}
		<div class="nav">
			{#if button_link}
				<button on:click={() => handleClick(button_link)}>View All Documents</button>
			{/if}
		</div>
	{/if}
</div>

<style lang="scss">
	.container {
		padding: 10px 10px 10px 10px;
	}

	table {
		width: 100%;
		font-family: 'Roboto';
		border-collapse: collapse;
	}

	table th:first-child {
		border-radius: 7px 0 0 7px;
	}

	table th:last-child {
		border-radius: 0 7px 7px 0;
	}

	th {
		background: #f5f5f5;
		padding-top: 8px;
		padding-bottom: 7px;
		font-size: 15px;
		font-weight: 500;
	}

	td {
		padding-top: 7px;
		padding-bottom: 7px;
		font-size: 14px;
		border-bottom: 1px solid #f3f3f3;
	}

	td,
	th {
		text-align: left;
		font-family: 'Roboto';
	}

	a,
	.file {
		text-decoration: none;
		color: #2757f2;
		cursor: pointer;
	}

	a:hover {
		color: #017698;
	}
	.file:hover {
		color: #017698;
	}

	.nav {
		font-style: normal;
		text-align: center;
		margin-top: 10px;

		& button {
			font-weight: bold;
			font-family: 'Roboto';
			background-color: #e7e7e7;
			color: black;
			border: none;
			border-radius: 5px;
			padding: 3px 5px;
			text-align: center;
			font-size: 13px;
			cursor: pointer;
		}
	}

	.default {
		text-align: center;
		font-family: 'Roboto';
		font-size: 15px;
	}

	.highlighted {
		color: hsl(15, 100%, 45%);
	}

	#clickable {
		font-weight: bold;
		cursor: pointer;
	}
</style>
