<svelte:options tag="edi-logo" />

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 147.97 42.13">
	<g id="Layer_2" data-name="Layer 2"
		><g id="Layer_1-2" data-name="Layer 1"
			><path
				class="cls-1"
				d="M60.24,33.1a5.67,5.67,0,0,1-4.33-1.65,6.71,6.71,0,0,1-1.55-4.74v-1.1a7.79,7.79,0,0,1,1.43-5,4.93,4.93,0,0,1,4.08-1.77,4.69,4.69,0,0,1,3.92,1.66,7.91,7.91,0,0,1,1.33,4.92v1.76H58a3.73,3.73,0,0,0,.68,2.25,2.28,2.28,0,0,0,1.84.71,4,4,0,0,0,3-1.24l1.42,2.18A4.78,4.78,0,0,1,63,32.54,6.71,6.71,0,0,1,60.24,33.1ZM58,24.64h3.6v-.33a3.81,3.81,0,0,0-.41-1.87,1.41,1.41,0,0,0-1.32-.64,1.5,1.5,0,0,0-1.37.68A4.56,4.56,0,0,0,58,24.64Z"
			/><path
				class="cls-1"
				d="M66.68,25.54a9,9,0,0,1,1.14-5.06,3.81,3.81,0,0,1,3.34-1.67,3.23,3.23,0,0,1,2.65,1.37v-6.9h3.63V32.85H74.17L74,31.45a3.32,3.32,0,0,1-2.85,1.65,3.78,3.78,0,0,1-3.31-1.65,8.68,8.68,0,0,1-1.16-4.87Zm3.62.9a6.58,6.58,0,0,0,.43,2.85,1.53,1.53,0,0,0,1.46.81,1.75,1.75,0,0,0,1.62-1V22.85a1.71,1.71,0,0,0-1.6-1,1.57,1.57,0,0,0-1.46.8,6.37,6.37,0,0,0-.45,2.85Z"
			/><path class="cls-1" d="M83.21,32.85H80.88V14.3h2.33Z" /><path
				class="cls-1"
				d="M89.11,19.06l.07,1.49a4,4,0,0,1,3.35-1.74c2.43,0,3.67,1.63,3.7,4.88v9.16H94V23.79a3.77,3.77,0,0,0-.54-2.3,2,2,0,0,0-1.62-.68,2.28,2.28,0,0,0-1.51.56,4,4,0,0,0-1.08,1.46v10H87V19.06Z"
			/><path
				class="cls-1"
				d="M106.31,29.32a1.66,1.66,0,0,0-.55-1.25,8.09,8.09,0,0,0-2.11-1.22,13.5,13.5,0,0,1-2.57-1.29A3.53,3.53,0,0,1,100,24.34a3.29,3.29,0,0,1-.37-1.62,3.63,3.63,0,0,1,1.23-2.8,5,5,0,0,1,6.37.06,4,4,0,0,1,1.23,3h-2.25a2.25,2.25,0,0,0-.62-1.59,2.1,2.1,0,0,0-1.59-.65,2.22,2.22,0,0,0-1.56.51,1.76,1.76,0,0,0-.57,1.38,1.51,1.51,0,0,0,.41,1.1,7.43,7.43,0,0,0,2,1.11,9.83,9.83,0,0,1,3.41,1.92,3.3,3.3,0,0,1,.91,2.38A3.65,3.65,0,0,1,107.33,32a5.61,5.61,0,0,1-6.8-.16,4.07,4.07,0,0,1-1.34-3.14h2.27a2.5,2.5,0,0,0,.7,1.8,3.06,3.06,0,0,0,3.56.15A1.68,1.68,0,0,0,106.31,29.32Z"
			/><path
				class="cls-1"
				d="M114.11,15.41a1.44,1.44,0,0,1-.32.95,1.18,1.18,0,0,1-1,.39,1.15,1.15,0,0,1-.95-.39,1.38,1.38,0,0,1-.32-.95,1.46,1.46,0,0,1,.32-1,1.16,1.16,0,0,1,.95-.4,1.14,1.14,0,0,1,1,.41A1.44,1.44,0,0,1,114.11,15.41Zm-.18,17.44h-2.26V19.06h2.26Z"
			/><path
				class="cls-1"
				d="M117,25.33a8.39,8.39,0,0,1,1.18-4.87,3.93,3.93,0,0,1,3.42-1.65,3.52,3.52,0,0,1,3.2,1.8l.12-1.55h2V33a5.46,5.46,0,0,1-1.31,3.93A4.82,4.82,0,0,1,122,38.28a6.2,6.2,0,0,1-2.29-.51,4.8,4.8,0,0,1-1.91-1.24l.92-1.55a4.37,4.37,0,0,0,3.1,1.37c1.88,0,2.84-1,2.89-3.07V31.49a3.55,3.55,0,0,1-3.11,1.61,3.91,3.91,0,0,1-3.34-1.61A7.92,7.92,0,0,1,117,26.86Zm2.26,1.28A6.66,6.66,0,0,0,120,30a2.45,2.45,0,0,0,2.24,1.08,2.69,2.69,0,0,0,2.52-1.71V22.5a2.73,2.73,0,0,0-2.5-1.69A2.45,2.45,0,0,0,120,21.9a6.44,6.44,0,0,0-.7,3.34Z"
			/><path
				class="cls-1"
				d="M132.58,20.48a4,4,0,0,1,3.29-1.67c2.43,0,3.67,1.63,3.7,4.88v9.16h-2.24V23.79a3.77,3.77,0,0,0-.54-2.3,2,2,0,0,0-1.62-.68,2.3,2.3,0,0,0-1.51.56,4,4,0,0,0-1.08,1.46v10h-2.25V13.28h2.25Z"
			/><path
				class="cls-1"
				d="M145.88,15.73v3.33H148v1.83h-2.08v8.56a2.29,2.29,0,0,0,.27,1.23,1,1,0,0,0,.92.42A2.93,2.93,0,0,0,148,31l0,1.9a4.9,4.9,0,0,1-1.56.25,2.37,2.37,0,0,1-2-1,4.49,4.49,0,0,1-.7-2.7V20.89h-2.1V19.06h2.1V15.73Z"
			/><path
				class="cls-1"
				d="M7.61,19.83l15.89,9.5,4.62,12.8h2.1L25.34,28.62l9.71-3.51V23.68l-7.32,2.65,7.32-8.06V15.58l-11,12.06L8.21,18.2c2.3-3.95,6-6.39,10-5.77l0,0h0l0,0h0c4.23-4.59,11.37-3.73,16.74.61V4.4A4.4,4.4,0,0,0,30.65,0H4.4A4.4,4.4,0,0,0,0,4.4V37.73a4.4,4.4,0,0,0,4.4,4.4h8.9C8.16,37.05,3.52,27.82,7.61,19.83Zm12-16.6,3.09,1.41a24.65,24.65,0,0,0-3.65,5.51,9.86,9.86,0,0,0-1.27,1.07h-.09A23.81,23.81,0,0,1,19.64,3.23ZM2.83,4.91c5-1.79,8.63-1.7,11.13-.84a6.73,6.73,0,0,1,3.28,2.67,20.87,20.87,0,0,0-.67,4.46,9.09,9.09,0,0,0-2.75.55l-.07,0A21,21,0,0,1,9,11.16C5.71,10.21,4.34,6.29,2.83,4.91Z"
			/></g
		></g
	></svg
>

<style lang="scss">
	:host {
		--edi-logo-fill: #ffffff;
		--edi-logo-width: 105px;
	}

	svg {
		height: auto;
		width: var(--edi-logo-width);

		fill: var(--edi-logo-fill);
	}
</style>
