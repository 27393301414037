<svelte:options tag="edi-bar-chart" />

<script lang="ts">
	import { Chart, registerables } from 'chart.js'
	import { onMount } from 'svelte'
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../common/dispatch'
	Chart.register(...registerables)

	export let items: any
	export let dropdownitems: any = []
	let canvas: HTMLCanvasElement
	let myChart: any
	let selected

	const component = get_current_component()

	export let charttype: string

	$: createChart(canvas, items, charttype)
	const createChart = (node: HTMLCanvasElement, items, charttype) => {
		if (myChart) {
			myChart.destroy()
		}
		if (items) {
			myChart = new Chart(node, {
				type: charttype,
				data: items,

				options: {
					parsing: {
						xAxisKey: 'months',
						yAxisKey: 'products.sales',
					},

					plugins: {
						legend: {
							position: 'bottom',
						},
					},

					onClick: (e) => {
						const activePoints = myChart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true)
						if (activePoints.length) {
							const firstPoint = activePoints[0]

							const value = myChart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index]

							window.open(value.products.link)
						}
					},
				},
			})
		}
	}

	onMount(() => {
		createChart(canvas, items, charttype)
	})

	const handlechange = async (selectedid) => {
		dispatch(component, 'edi-bar-chart:dropdownchange', {
			widgettype: charttype,
			id: selectedid,
			myChart,
		})
	}
</script>

<div class="select">
	Year:
	<select bind:value={selected} on:change={() => handlechange(selected)}>
		{#each dropdownitems as question}
			<option value={question.id}>
				{question.name}
			</option>
		{/each}
	</select>
</div>

<canvas bind:this={canvas} width="400px" height="400px" />

<style lang="scss">
	@import '../../styles/variables';
	@import '../../styles/mixins';
	@import '@material/elevation/mdc-elevation';

	:host {
		--edi-chart-background-color: var(--edi-dark);
	}
	select {
		border-radius: 6px;
		height: 25px;
	}
	.select {
		text-align: center;
		margin-top: 1em;
		padding-bottom: 0.5em;
	}
</style>
