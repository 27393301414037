<svelte:options tag="edi-login-account-select" />

<script lang="ts">
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../common/dispatch'
	import type ValueOption from './dto/LoginValueOption'
	import './LoginOptions.svelte'

	export let options: ValueOption[] = []
	export let selected: ValueOption

	const component = get_current_component()

	let selectRef
	let open: boolean = false

	let text: string

	$: if (selected) {
		text = selected.title
	}
	// events
	const handleSelection = (option: ValueOption) => {
		selectRef.blur()
		dispatch(component, 'edi-login-account-select:selection-changed', { option })
	}

	const handleTriggerClick = () => {
		open = !open

		if (open) {
			selectRef.focus()
		} else {
			selectRef.blur()
		}
	}
</script>

<div class="container">
	<div bind:this={selectRef} on:blur={() => (open = false)} tabindex="-1" class="select" class:select--open={open}>
		<div on:click={handleTriggerClick} class="trigger" class:mdc-elevation--z4={open}>
			<span>Student Test Administration Login </span>
			<span class="trigger__arrow">
				<svg
					version="1.1"
					id="Layer_1"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
					x="0px"
					y="0px"
					viewBox="0 0 30 30"
					style="enable-background:new 0 0 30 30;"
					xml:space="preserve"
					width="25"
					height="25"
				>
					<style>
						.st0 {
							fill: #ffffff;
						}
					</style>
					<g>
						<polygon class="st0" points="17.5,24.7 3.2,13 7.6,7.5 16.5,14.8 21.9,8.1 27.3,12.4 	" />
					</g>
				</svg>
			</span>
		</div>

		<div class="options mdc-elevation--z4">
			{#each options as option}
				<edi-login-option
					data={option}
					value={option.value}
					selected={option.value === selected?.value}
					on:click={() => handleSelection(option)}
				/>
			{/each}
		</div>
	</div>
</div>

<style lang="scss">
	@import '../../styles/variables';
	@import '../../styles/mixins';
	@import '@material/elevation/mdc-elevation';

	:host {
		--edi-account-select-background-color: var(--edi-dark);
	}

	.container {
		position: relative;
		user-select: none;
		font-family: 'Roboto';
		display: block;
		width: 224px;

		@include lg {
			width: 278px;
		}
	}

	.select {
		position: relative;
		user-select: none;

		&--open {
			.options {
				display: inline-block;
			}
		}
	}

	.options {
		background: var(--edi-account-select-background-color);
		list-style: none;
		padding: 0;
		margin: 0.25rem 0 0 0;
		padding: 0.5rem;
		display: none;
		position: absolute;
		top: 48px;
		left: 93%;
		width: 265px;
		z-index: 510;
		transform: translate(-50%, 0);
		@include md {
			left: 75%;
			//top: 50px;
		}
	}

	.trigger {
		background: var(--edi-account-select-background-color);
		box-sizing: border-box;
		text-align: center;
		width: 272px;
		position: relative;
		left: 71px;
		color: white;
		padding: 1.2em 0 0.5em 1.5em;
		border-radius: 10px;
		font-style: italic;
		font-size: 14px;
		font-weight: bold;
		display: flex;
		justify-content: space-evenly;
		&__arrow {
			position: relative;
			top: -4px;
			padding-left: 5px;
		}
		&:hover {
			@extend .mdc-elevation--z4;
			cursor: pointer;
		}
	}
</style>
