<svelte:options tag="edi-svg-back" />

<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M9.77201 15.4595L8.9297 16.3019C8.57304 16.6585 7.99632 16.6585 7.64345 16.3019L0.267492 8.9297C-0.0891642 8.57304 -0.0891642 7.99632 0.267492 7.64346L7.64345 0.267492C8.00011 -0.0891642 8.57683 -0.0891642 8.9297 0.267492L9.77201 1.10981C10.1325 1.47026 10.1249 2.05836 9.75683 2.41123L5.1848 6.76699H16.0894C16.594 6.76699 17 7.17297 17 7.6776V8.89175C17 9.39638 16.594 9.80237 16.0894 9.80237H5.1848L9.75683 14.1581C10.1287 14.511 10.1363 15.0991 9.77201 15.4595Z"
		fill="#435765"
	/>
</svg>

<style lang="scss">
	svg {
		padding-right: 3px;
	}
</style>
