<svelte:options tag="edi-common-pie-chart" />

<script lang="ts">
	import { Chart, registerables } from 'chart.js'
	import ChartDataLabels from 'chartjs-plugin-datalabels'
	import { onMount } from 'svelte'
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../common/dispatch'

	Chart.register(...registerables)

	let canvas: HTMLCanvasElement

	export let id: any
	export let user_id: any

	export let charttype: string
	export let items: any

	// Dropdown props
	export let dropdownitems: any = []

	export let button_text: any
	export let button_link: any

	export let current_container: any

	// Field to hold values of dropdown details for the api side
	let label: any
	let selectedDropdown: any
	let dropdownID: any
	let isValueChanged: any
	let ddlList: any[] = []

	// Binding values to these variable when selectiing an item from dropdown
	let selected: any = dropdownitems.map((x) => x.dropdown.filter((a) => a.selected))

	const component = get_current_component()

	let myChart: any

	$: createChart(canvas, items, charttype)

	const createChart = (node: HTMLCanvasElement, items, charttype) => {
		if (myChart) {
			myChart.destroy()
		}
		if (items && items.length !== 0) {
			myChart = new Chart(node, {
				type: charttype,
				data: items,

				options: {
					resposnsive: true,
					maintainAspectRatio: false,
					parsing: {
						key: 'sales',
					},
					layout: {
						padding: {
							top: 20,
						},
					},
					plugins: {
						legend: {
							position: 'bottom',
							labels: {
								padding: 20,
								font: {
									size: 14,
									weight: 'bold',
								},
							},
							onHover: (event) => {
								event.native.target.style.cursor = 'pointer'
							},

							onLeave: (event) => {
								event.native.target.style.cursor = 'default'
							},
						},

						tooltip: {
							callbacks: {
								label: function (context) {
									let label = ''
									label = context.raw.tooltip + ' (' + context.raw.percentage + ')'
									return label
								},
							},
						},

						datalabels: {
							align: 'end',
							anchor: 'end',
							padding: 2,
							color: function (context) {
								return context.dataset.backgroundColor
							},
							font: function (context) {
								var w = context.chart.width
								return {
									size: w < 512 ? 12 : 14,
									weight: 'bold',
								}
							},
							formatter: (value, context) => {
								let datavalue = ''

								return datavalue
							},
						},
					},

					onClick: (e) => {
						const activePoints = myChart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true)
						if (activePoints.length) {
							const firstPoint = activePoints[0]
							const value = myChart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index]
							window.location = value.link
						}
					},

					onHover: (event, chartElement) => {
						event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
					},
				},
				plugins: [ChartDataLabels],
			})

			genGradient()
		}
	}

	function genGradient() {
		const ctx = myChart.ctx

		var gradient = {}

		const bgColor = myChart?.config?.data?.datasets[0]?.backgroundColor

		bgColor?.map((itm, index) => {
			if (typeof itm !== 'object') {
				gradient[index] = ctx?.createLinearGradient(0, 0, 0, 600)

				gradient[index]?.addColorStop(0, itm)

				gradient[index]?.addColorStop(1, hexToRgbA(itm))

				if (gradient[index] !== undefined) {
					myChart.config.data.datasets[0].backgroundColor[index] = gradient[index]
					myChart.update()
				}
			}
		})
	}

	// Method to convert hex color code to rgba code
	function hexToRgbA(hex) {
		var c
		if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
			c = hex.substring(1).split('')
			if (c.length == 3) {
				c = [c[0], c[0], c[1], c[1], c[2], c[2]]
			}
			c = '0x' + c.join('')
			return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0)'
		}
		throw new Error('Bad Hex')
	}

	onMount(() => {
		createChart(canvas, items, charttype)
	})

	const handlechange = async (selectedItem: any, dropdownLabel: any) => {
		ddlList = []

		selectedItem.forEach((element, index) => {
			label = dropdownitems[index].label
			selectedDropdown = element?.value ? element?.value : ''
			dropdownID = element?.id ? element?.id : 0

			if (label == dropdownLabel) {
				isValueChanged = true
			} else {
				isValueChanged = false
			}

			ddlList = [...ddlList, { label, selectedDropdown, dropdownID, isValueChanged }]
		})

		dispatch(component, 'edi-common-pie-chart:dropdownchange', {
			id,
			myChart,
			ddlList,
			widgettype: 'pie',
			user_id,
			currentContainer: current_container,
		})
	}

	const handleClick = (url: any) => {
		window.location = url
	}

	document.addEventListener('pie_chart_data_changed', (e: any) => {
		if (e?.detail?.widgetID === id) {
			button_link = e?.detail?.newLink
			button_text = e?.detail?.newButtonText
			items = e?.detail?.datachanged
			dropdownitems = e.detail.dropdownchanged ? e.detail.dropdownchanged : []

			//Reassigning selected items with api result
			selected = []

			dropdownitems.forEach((item) => {
				let selectedDropdown = item.dropdown.find((dropdown) => dropdown.selected === true)

				if (!selectedDropdown) {
					selectedDropdown = item.dropdown[0]
				}

				selected.push(selectedDropdown)
			})

			setTimeout(() => {
				createChart(canvas, items, charttype)
			}, 100)
		}
	})
</script>

<div class="container">
	{#if dropdownitems}
		<div class="select">
			{#each dropdownitems as item, index}
				<div>
					<span>{item?.label}:</span>
					<select bind:value={selected[index]} on:change={() => handlechange(selected, item?.label)}>
						{#each item.dropdown as dropdown}
							<option selected={dropdown?.selected} value={dropdown}>
								{dropdown.value}
							</option>
						{/each}
					</select>
				</div>
				<br />
			{/each}
		</div>
	{/if}
	{#if items}
		<div class="container" style="display:{items?.length === 0 ? 'none' : ''}">
			<div class={items?.labels?.length > 5 ? 'canvas-container' : 'canvas-container-default'}>
				<canvas bind:this={canvas} />
			</div>

			{#if button_link !== ''}
				<div class="nav">
					<button on:click={() => handleClick(button_link)}>{button_text}</button>
				</div>
			{/if}
		</div>
		{#if items?.length === 0}
			<div class="default">No Data Available</div>
		{/if}
	{/if}
</div>

<style lang="scss">
	.container {
		width: 100%;
	}

	.container button {
		margin: auto;
		display: block;
		background-color: White;
		border: 1px solid black;
		cursor: pointer;
	}

	select {
		height: 20px;
		width: 55%;
		margin-right: 10%;
		border: none;
		border-radius: 5px;
		border-color: #f5f5f5;

		background: #f5f5f5;

		font-family: 'Roboto';
		font-style: normal;
	}
	.select {
		text-align: right;

		font-family: 'Roboto';
		font-style: normal;
		font-weight: bold;
		line-height: 19px;
	}

	.canvas-container {
		width: 100%;
		height: 550px;
		padding: 10px 0 10px 0;
	}

	.canvas-container-default {
		width: 100%;
		height: 370px;
		padding: 10px 0 10px 0;
	}

	.nav {
		font-style: normal;
		text-align: center;

		& button {
			font-family: 'Roboto';
			font-weight: bold;
			background-color: #e7e7e7;
			color: black;
			border: none;
			border-radius: 5px;
			padding: 3px 5px;
			text-align: center;
			font-size: 13px;
			cursor: pointer;
		}
	}

	.default {
		text-align: center;
		font-family: 'Roboto';
		font-size: 15px;
	}
</style>
