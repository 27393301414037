/**
 * Constant for our inversion_request listener
 */
export const INVERSION_REQUEST = 'inversion_request';
/**
 * Handles the inversion_request events
 * @param resolve resolver function
 * @returns
 */
const onInversionRequest = (resolve) => (event) => {
    try {
        // try to resolve the token
        const instance = resolve(event.detail.token);
        // missing instance means we should continue
        // to let the event bubble
        if (!instance)
            return;
        // set the event instance
        event.detail.instance = instance;
        // stop the bubbling 
        event.stopPropagation();
        event.preventDefault();
    }
    catch (_a) {
        // okay to do nothing  
        // if resolving fails 
        // event will continue to bubble and be handled elsewhere
        // hopefully... 
    }
};
/**
 * Configures an element to be a inversion container
 * @param node html element to listen to events on
 * @param resolve resolver function for handling injection requests
 * @returns
 */
export const inversion = (node, resolve) => {
    let resolver = onInversionRequest(resolve);
    // add our event listener so we can resolve dependencies
    node.addEventListener(INVERSION_REQUEST, resolver);
    return {
        // need to update our listener with the new resolver
        update(resolve) {
            // remove the old event listen
            node.removeEventListener(INVERSION_REQUEST, resolver);
            // create new resolver
            resolver = onInversionRequest(resolve);
            // register the new event listener/resolver
            node.addEventListener(INVERSION_REQUEST, resolver);
        },
        // clean-up our event listener
        destroy() {
            node.removeEventListener(INVERSION_REQUEST, resolver);
        }
    };
};
