<svelte:options tag="edi-keystone-anchor" />

<script lang="ts">
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../../../common/dispatch'

	const component = get_current_component()

	export let id: any
	export let user_id: any

	export let title: any
	export let items: any = []

	// Dropdown prop
	export let dropdownitems: any = []

	// Headings prop
	export let headings = []

	export let button_text: any
	export let button_link: any

	// Binding values to this variable when selectiing an item from dropdown
	let selected: any = dropdownitems.map((x) => x.dropdown.filter((a) => a.selected))

	// Field to hold values of dropdown details for the api side
	let label: any
	let selectedDropdown: any
	let dropdownID: any
	let ddlList: any[] = []

	const handlechange = (selectedItem) => {
		ddlList = []
		selected.forEach((element, index) => {
			label = dropdownitems[index].label
			selectedDropdown = element.value
			dropdownID = element.id
			ddlList = [...ddlList, { label, selectedDropdown, dropdownID }]
		})

		dispatch(component, 'edi-keystone-anchor:dropdownchange', {
			id,
			user_id,
			widgettype: 'grid',
			ddlList,
			widgettitle: title,
		})
	}

	document.addEventListener('keystone_anchor_data_changed', (e: any) => {
		items = e.detail.datachanged ? e.detail.datachanged : []
		dropdownitems = e.detail.dropdownchanged
		button_link = e?.detail?.newLink
	})
</script>

<div class="container">
	<div class="select">
		{#each dropdownitems as item, index}
			<div>
				<span>{item?.label}:</span>
				<select bind:value={selected[index]} on:change={() => handlechange(selected)}>
					{#each item?.dropdown as dropdown}
						<option selected={dropdown?.selected} value={dropdown}>
							{dropdown?.value}
						</option>
					{/each}
				</select>
			</div>
			<br />
		{/each}
	</div>

	<div class="grid-container">
		<table>
			<tr>
				{#each headings as heading}
					<th>{heading}</th>
				{/each}
			</tr>
			{#if items.length > 0}
				{#each items as data}
					<tr>
						<td>
							<a href={data?.link1} target="_blank">{data?.code}</a>
						</td>
						<td>{data?.pref}</td>
						<td>{data?.targetpref}</td>
						<td>{data?.distance}</td>
					</tr>
				{/each}
			{/if}
		</table>

		{#if items.length === 0}
			<div class="default">There is no data available for the selected parameters</div>
		{/if}
		{#if items.length > 0}
			<div class="report-nav">
				<a href={button_link} target="_blank">{button_text}</a>
			</div>
		{/if}
	</div>
</div>

<style lang="scss">
	.container {
		width: 100%;
	}

	select {
		height: 20px;
		width: 55%;
		margin-right: 10%;
		border: none;
		border-radius: 5px;
		border-color: #f5f5f5;

		background: #f5f5f5;

		font-family: 'Roboto';
		font-style: normal;
	}
	.select {
		text-align: right;

		font-family: 'Roboto';
		font-style: normal;
		font-weight: bold;
		line-height: 19px;
	}

	table {
		width: 100%;
		font-family: 'Roboto';
		border-collapse: collapse;
	}

	table th:first-child {
		border-radius: 7px 0 0 7px;
	}

	table th:last-child {
		border-radius: 0 7px 7px 0;
	}

	tr > th {
		font-weight: bold;
	}

	th {
		background: #f5f5f5;
		padding-top: 8px;
		padding-bottom: 7px;
		font-size: 15px;
		font-weight: 500;
	}

	td {
		padding-top: 7px;
		padding-bottom: 7px;
		font-size: 14px;
		border-bottom: 1px solid #f3f3f3;
	}

	td,
	th {
		padding-right: 8px;
		text-align: center;
		font-family: 'Roboto';
	}

	a {
		text-decoration: none;
		color: #2757f2;
	}

	a:hover {
		color: #017698;
	}

	.default {
		text-align: center;
		font-family: 'Roboto';
		font-size: 15px;

		padding-top: 5px;
	}

	.report-nav {
		text-align: center;
		padding: 10px;
		font-size: 15px;
		font-family: 'Roboto';
	}
</style>
