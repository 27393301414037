<svelte:options tag="edi-ytd-suspensions" />

<script lang="ts">
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../../common/dispatch'

	const component = get_current_component()

	export let id: any
	export let user_id: any

	export let title: any
	export let items: any = []
	export let dropdownitems: any = []

	// Headings prop
	export let headings = []

	// Binding values to these variable when selectiing an item from dropdown
	let selected: any = dropdownitems.map((x) => x.dropdown.filter((a) => a.selected))

	// Field to hold values of dropdown details for the api side
	let label: any
	let selectedDropdown: any
	let dropdownID: any
	let ddlList: any[] = []

	const handlechange = () => {
		ddlList = []
		selected.forEach((element, index) => {
			label = dropdownitems[index].label
			selectedDropdown = element.value
			dropdownID = element.id
			ddlList = [...ddlList, { label, selectedDropdown, dropdownID }]
		})

		dispatch(component, 'edi-ytd-suspensions:dropdownchange', {
			id,
			widgettype: 'grid',
			widgettitle: title,
			ddlList,
			user_id,
		})
	}

	document.addEventListener('ytd_suspension_changed', (e: any) => {
		items = e.detail.datachanged
	})

	const handleClick = (link: any) => {
		window.location = link
	}
</script>

<div class="container">
	{#if dropdownitems}
		<div class="select">
			{#each dropdownitems as item, index}
				<div>
					<select bind:value={selected[index]} on:change={() => handlechange()}>
						{#each item.dropdown as dropdown}
							<option selected={dropdown?.selected} value={dropdown}>
								{dropdown.value}
							</option>
						{/each}
					</select>
				</div>
				<br />
			{/each}
		</div>
	{/if}

	<div class="grid-container">
		<div class="heading" id="heading">
			{#each headings as heading}
				<div>{heading}</div>
			{/each}
		</div>
		{#if items.length > 0}
			<div class="data">
				{#each items as data}
					<div>{data?.name}</div>
					<div>
						<span on:click={() => handleClick(data?.total_link)}>{data?.total}</span>
					</div>
					<div>{data?.total_days}</div>
					<div>
						<span on:click={() => handleClick(data?.students_link)}>{data?.students_contributing}</span>
					</div>
				{/each}
			</div>
		{:else}
			<div class="default">No Data Available</div>
		{/if}
	</div>
</div>

<style lang="scss">
	.container {
		width: 100%;
	}

	select {
		height: 20px;
		width: 80%;
		background: #f5f5f5;
		border: none;
		border-radius: 5px;
		border-color: #f5f5f5;
		color: #818181;

		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 18px;
		text-align: center;
	}
	.select {
		text-align: center;

		font-family: 'Roboto';
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 19px;

		color: #818181;
	}

	.heading {
		grid-area: heading;
		display: grid;
		grid-template-columns: 2fr 1fr 1fr 1.5fr;
		grid-gap: 1px;
	}

	.heading > div {
		padding-top: 8px;
		padding-bottom: 7px;
	}

	#heading {
		font-weight: bold;
		background: #f5f5f5;
		border-radius: 7px;
	}

	.data {
		grid-area: data;
		display: grid;
		grid-template-columns: 2fr 1fr 1fr 1.5fr;
		grid-gap: 1px;
	}

	.data > div {
		padding-top: 5px;
		padding-bottom: 5px;
		border-bottom: 1px solid #f3f3f3;
	}

	.grid-container {
		width: 100%;
		display: grid;
		grid-template-areas:
			'heading'
			'data';
	}

	.grid-container > div {
		background-color: rgba(255, 255, 255, 0.8);
		text-align: center;
		padding: 1px;
		font-family: 'Roboto';
		font-size: 15px;
	}

	span {
		cursor: pointer;
		text-decoration: none;
		color: #2757f2;
	}

	span:hover {
		color: #017698;
	}

	.default {
		text-align: center;
		font-family: 'Roboto';
		font-size: 15px;
	}
</style>
