<svelte:options tag="edi-risk-grid" />

<script lang="ts">
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../../common/dispatch'

	const component = get_current_component()

	// Headings prop
	export let headings = []

	export let items = []

	export let button_link: string

	const handleClick = (link: any) => {
		window.location = link
	}

	const handleRiskScoreCalculation = () => {
		dispatch(component, 'edi-risk-grid:calculateriskscore', {})
	}
</script>

<div class="container">
	<div class="score-calc">
		<span class="risk-score" on:click={() => handleRiskScoreCalculation()}
			>How are these scores being calculated?</span
		>
	</div>

	<div class="grid-container">
		<div class="heading" id="heading">
			{#each headings as heading}
				<div>{heading}</div>
			{/each}
		</div>

		{#if items.length > 0}
			<div class="data">
				{#each items as data}
					<div>
						<a href={data?.student_link}>{data?.name}</a>
					</div>
					<div>
						<a href={data?.link}>{data?.score}</a>
					</div>
				{/each}
			</div>
		{:else}
			<div class="default">No Data Available</div>
		{/if}
	</div>

	{#if items.length > 0}
		<div class="btn">
			<button on:click={() => handleClick(button_link)}>Show More Students</button>
		</div>
	{/if}
</div>

<style lang="scss">
	.container {
		padding: 10px 10px 10px 10px;
		font-family: 'Roboto';
	}

	.score-calc {
		font-size: 15px;
		padding-bottom: 5px;
	}

	.heading {
		grid-area: heading;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 1px;
	}

	#heading {
		font-weight: bold;

		background: #f5f5f5;
		border-radius: 7px;
	}

	.heading > div {
		padding-top: 8px;
		padding-bottom: 7px;
	}

	.data {
		grid-area: data;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 1px;
	}

	.data > div {
		padding-top: 5px;
		padding-bottom: 5px;
		border-bottom: 1px solid #f3f3f3;
	}

	.grid-container {
		width: 100%;
		display: grid;
		grid-template-areas:
			'heading'
			'data';
	}

	.grid-container > div {
		background-color: rgba(255, 255, 255, 0.8);
		text-align: center;
		padding: 1px;
		font-family: 'Roboto';
		font-size: 15px;
	}

	a {
		text-decoration: none;
		color: #2757f2;
	}

	a:hover {
		color: #017698;
	}

	.btn {
		text-align: center;
		padding-top: 10px;
	}

	.btn > button {
		cursor: pointer;
		font-family: 'Roboto';
		font-weight: bold;

		border: none;
		border-radius: 5px;
		background-color: #f5f5f5;
		padding: 5px;
	}

	.default {
		text-align: center;
		font-family: 'Roboto';
		font-size: 15px;
	}

	.risk-score {
		cursor: pointer;
		font-family: 'Roboto';
		color: #2757f2;
	}
</style>
