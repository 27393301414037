<svelte:options tag="edi-app-bar-menu-search" />

<script lang="ts">
	import lunr from 'lunr'
	import _ from 'lodash'
	import type { Item, ItemState } from './utils'
	import { injectable } from '../../common/injectable'
	import { APP_BAR_STATE_ITEMS } from './constants'
	import './AppBarFavoriteStar.svelte'
	import { derived, readable, writable } from 'svelte/store'
	import { get_current_component } from 'svelte/internal'
	import { dispatchAppBarFavorite, dispatchAppBarNavigated } from './utils'

	// search value from input
	let value = writable<string>('')
	// search results from the index
	let searchResults = writable<lunr.Index.Result[]>([])
	// search index results converted to menu items
	let searchResultItems = readable<Item[]>([])
	let loading = writable(false)
	let open = writable(false)
	let expanded = writable(false)

	let component = get_current_component()
	let itemState: ItemState
	let index: lunr.Index
	let timer: any

	/**
	 * injected from the dom
	 */
	$: indexedItems = itemState?.indexedItems || readable<Item[]>([])

	/**
	 * creates an index from all the requested indexed items
	 */
	$: if ($indexedItems) {
		index = lunr(function () {
			/* This removes the stemmer from the indexing pipeline.
			   The stemmer reduces words to their base form, which can improve search results.*/
			this.pipeline.remove(lunr.stemmer)

			/*This adds the trimmer and stopWordFilter to the indexing pipeline. 
			  The trimmer removes leading and trailing whitespace from terms, 
			  while the stopWordFilter removes common words that are unlikely to be useful in a search.*/
			this.pipeline.add(lunr.trimmer, lunr.stopWordFilter)

			this.searchPipeline.remove(lunr.stemmer) // This removes the stemmer from the search pipeline.
			this.searchPipeline.add(lunr.trimmer, lunr.stopWordFilter) // This adds the trimmer and stopWordFilter to the search pipeline.
			this.ref('indexKey')
			this.field('label')

			$indexedItems.forEach((item) => {
				this.add({
					...item,
					indexKey: JSON.stringify(item.key),
				})
			})
		})
	}

	/**
	 * finds the menu items for the search results
	 */
	$: if ($indexedItems) {
		searchResultItems = derived([indexedItems, searchResults], ([$items, $results]) =>
			// besides being a matching index
			// we only want results with a score > 0
			// and we only want the top 5 results
			$results
				.filter(({ score }) => score !== 0)
				.slice(0, 5)
				.map(({ ref }) => $items?.find((item) => _.isEqual(JSON.stringify(item.key), ref)))
				.filter((x) => x)
		)
	}

	/**
	 * debounces the index searching
	 * sets the searchResults
	 */
	$: if (index) {
		loading.set(true)

		clearTimeout(timer)
		timer = setTimeout(() => {
			if ($value && $value.trim()) {
				searchResults.set(index.search($value + '*')) // Appending an asterisk (*) to the value to find all the possible matches.
			} else {
				searchResults.set([])
			}

			loading.set(false)
		}, 500)
	}

	/**
	 * toggles the dropdown open based on the search value
	 */
	$: open.set(!!$value)

	/**
	 * clears the current value.
	 * sets expanded to false
	 */
	const close = () => {
		value.set('')
		expanded.set(false)
	}

	/**
	 * called when the user clicks the "X" icon
	 */
	const handleCleared = () => close()

	/**
	 * expands the search box
	 */
	const handleExpanded = () => expanded.set(true)

	/**
	 * closes the search box and sends a "navigated" event
	 * @param item
	 */
	const handleNavigated = (item: Item) => () => {
		dispatchAppBarNavigated(component, item)()
		close()
	}
</script>

<div
	class="wrapper"
	class:wrapper--expanded={$expanded}
	on:click={handleExpanded}
	use:injectable={APP_BAR_STATE_ITEMS}
	on:injected={({ detail: { instance } }) => (itemState = instance)}
>
	<mwc-icon class="icon icon--left">search</mwc-icon>
	<label>
		<input type="text" placeholder="Search through the menus" bind:value={$value} />
	</label>
	<mwc-icon class="icon icon--right" on:click|stopPropagation={handleCleared}>close</mwc-icon>

	<div class="drawer mdc-elevation--z2" class:drawer--open={$open}>
		<div class="drawer__title">Showing Menu Results</div>
		{#if $loading}
			<div class="skeleton" />
		{:else}
			{#each $searchResultItems as item}
				<div class="drawer__item" on:click|stopPropagation={handleNavigated(item)}>
					<span>{item.label}</span>
					<edi-app-bar-favorite-star
						on:click|stopPropagation={dispatchAppBarFavorite(component, item)}
						variant={item.isFavorite ? 'filled' : 'empty'}
					/>
				</div>
			{:else}
				<div class="drawer__item drawer__item--disabled">
					<span>No Matching Results</span>
				</div>
			{/each}
		{/if}
	</div>
</div>

<style lang="scss">
	@import '../../styles/variables';
	@import '../../styles/mixins';
	@import '@material/elevation/mdc-elevation';

	@include reset;

	@keyframes load {
		from {
			left: -150px;
		}
		to {
			left: 100%;
		}
	}

	* {
		box-sizing: border-box;
	}

	.skeleton {
		position: relative;
		overflow: hidden;
		height: 25px;
		margin-bottom: 1rem;

		&::before {
			content: '';
			display: block;
			position: absolute;
			left: -150px;
			top: 0;
			height: 100%;
			width: 150px;
			background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
			animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
		}
	}

	.wrapper {
		@mixin expanded {
			width: 350px;
			border-bottom: 1px solid rgba(17, 42, 59, 0.25);
			overflow: visible;
			transition: width 0.25s ease-out;
			cursor: default;

			.icon--left {
				cursor: default;

				&:hover {
					background: var(--edi-gray-ultralight);
				}
			}

			label {
				visibility: visible;
			}
		}

		position: relative;
		display: flex;
		align-items: center;
		width: 32px;
		margin: 0 2rem;
		overflow: hidden;
		transition: width 0.25s ease-in;

		&--expanded {
			@include expanded;
		}

		@include lg {
			@include expanded;
		}
	}

	label {
		font-family: 'Roboto';
		flex-grow: 1;

		input[type='text'] {
			appearance: none;
			outline: none;
			line-height: 16px;
			font-size: 16px;
			padding: 9px;
			border: none;
			width: 100%;
		}
	}

	.icon {
		&--left {
			cursor: pointer;
			border-radius: 50%;
			padding: 0.25rem;

			&:hover {
				background: var(--edi-gray-ultralight);
			}
		}

		&--right {
			border-radius: 50%;
			padding: 0.25rem;

			&:hover {
				cursor: pointer;
				background: var(--edi-gray-ultralight);
			}
		}
	}

	.drawer {
		font-family: 'Roboto';
		opacity: 0;
		visibility: hidden;
		position: absolute;
		top: 100%;
		width: 100%;
		background: white;
		margin-top: 3px;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		padding: 1.5rem 0;
		z-index: 500;

		&__item {
			display: flex;
			align-items: center;
			padding: 0.5rem 2rem;

			span {
				flex-grow: 1;
			}

			&:hover {
				cursor: pointer;
				background: rgba(196, 196, 196, 0.19);
			}

			&--disabled {
				opacity: 0.75;

				&:hover {
					cursor: default;
					background: white;
				}
			}
		}

		&__title {
			font-size: 12px;
			font-weight: bold;
			letter-spacing: 0.01em;
			text-transform: uppercase;
			color: var(--edi-gray-light);
			padding: 0 2rem;
			margin-bottom: 1.5rem;
		}

		&--open {
			opacity: 1;
			visibility: visible;
		}
	}
</style>
