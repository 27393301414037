<svelte:options tag="edi-svg-folder-open" />

<script lang="ts">
</script>

<svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M2.89834 6.26721L0 11.2339V2.09125C0 0.937794 0.937794 0 2.09125 0H5.93065C6.48613 0 7.01875 0.218927 7.41086 0.611036L8.27676 1.47694C8.66887 1.86905 9.20149 2.08798 9.75697 2.08798H13.5931C14.7466 2.08798 15.6844 3.02577 15.6844 4.17923V5.22485H4.70531C3.9603 5.22485 3.27411 5.62023 2.89834 6.26394V6.26721ZM3.80019 6.79328C3.98971 6.46979 4.3328 6.27374 4.70531 6.27374H17.7756C18.1514 6.27374 18.4945 6.47306 18.6807 6.79982C18.867 7.12658 18.867 7.52522 18.6774 7.84871L15.0178 14.1225C14.8315 14.4427 14.4884 14.6387 14.1159 14.6387H1.04562C0.669852 14.6387 0.326757 14.4394 0.140506 14.1126C-0.045746 13.7859 -0.045746 13.3872 0.143773 13.0638L3.80346 6.79002L3.80019 6.79328Z"
		fill="#A2B7D4"
	/>
</svg>
