<svelte:options tag="edi-data-depot-chart" />

<script lang="ts">
	import { Chart, registerables } from 'chart.js'
	import ChartDataLabels from 'chartjs-plugin-datalabels'
	import { onMount } from 'svelte'
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../common/dispatch'
	import ChartjsPluginStacked100 from 'chartjs-plugin-stacked100'

	Chart.register(...registerables)
	Chart.register(ChartjsPluginStacked100)

	export let id: any
	export let user_id: any

	export let charttype: string
	export let items: any
	let piechartitems: any

	// Dropdown prop
	export let dropdownitems: any = []

	// Binding values to this variable when selectiing an item from dropdown
	let selected: any = dropdownitems.map((x) => x.dropdown.filter((a) => a.selected))

	// Field to hold values of dropdown details for the api side
	let label: any
	let selectedDropdown: any
	let dropdownID: any
	let ddlList: any[] = []
	let isValueChanged: any

	/* ----- Custom Fields Start ----- */

	// Chart axes title props
	export let x_axis_title: any
	export let y_axis_title: any

	export let button_text: any
	export let button_link: any
	export let widget_heading: any

	export let is_data_available: any
	export let is_pie_chart: false

	/* ----- Custom Fields End ----- */

	const component = get_current_component()

	let canvas: HTMLCanvasElement
	let myChart: any = null

	$: if (!is_pie_chart) {
		createChart(canvas, items, charttype)
	}
	const createChart = (node: HTMLCanvasElement, barchartitems, charttype) => {
		if (barchartitems) {
			if (myChart) {
				myChart.destroy()
			}
			myChart = new Chart(node, {
				type: charttype,
				data: barchartitems,

				options: {
					resposnsive: true,
					maintainAspectRatio: false,
					scales: {
						x: {
							title: {
								display: true,
								text: x_axis_title,
								font: {
									size: 15,
									weight: 'bold',
								},
							},
							ticks: {
								autoSkip: false, // For some charts with large dataset the x-axis labels not showing properly.
								color: 'black',
								font: {
									size: items?.labels?.length > 12 ? 11 : 13,
									weight: 'bold',
								},
							},
						},
						y: {
							ticks: {
								stepSize: 20,
								callback: function (tick) {
									return tick.toString() + '%'
								},
								color: 'black',
								font: {
									weight: 'bold',
								},
							},
							title: {
								display: true,
								text: y_axis_title,
								font: {
									size: 15,
									weight: 'bold',
								},
							},
						},
					},

					plugins: {
						stacked100: { enable: true, replaceTooltipLabel: false },
						legend: {
							position: 'bottom',

							onHover: (event) => {
								event.native.target.style.cursor = 'pointer'
							},

							onLeave: (event) => {
								event.native.target.style.cursor = 'default'
							},

							labels: {
								color: 'black',
								font: {
									size: 14,
									weight: 'bold',
								},
							},
						},
						tooltip: {
							yAlign: 'top',
							callbacks: {
								label: function (context) {
									let label = ''
									label = context.raw.tooltip
									return label
								},
							},
						},
					},

					onClick: (e) => {
						const activePoints = myChart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true)
						if (activePoints.length) {
							const firstPoint = activePoints[0]

							const value = myChart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index]

							window.location = value.link
						}
					},

					onHover: (event, chartElement) => {
						event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
					},
				},
			})
		}
	}

	const createPieChart = (node: HTMLCanvasElement, piechartitems, charttype) => {
		if (myChart) {
			myChart.destroy()
		}
		if (piechartitems) {
			myChart = new Chart(node, {
				type: charttype,
				data: piechartitems,

				options: {
					resposnsive: true,
					maintainAspectRatio: false,
					parsing: {
						key: 'sales',
					},
					layout: {
						padding: 10,
					},
					plugins: {
						legend: {
							position: 'bottom',
							onHover: (event) => {
								event.native.target.style.cursor = 'pointer'
							},

							onLeave: (event) => {
								event.native.target.style.cursor = 'default'
							},

							labels: {
								font: {
									size: 14,
									weight: 'bold',
								},
							},
						},

						tooltip: {
							callbacks: {
								label: function (context) {
									let label = ''
									label = context?.raw?.tooltip + ' (' + context?.raw?.percentage + ')'
									return label
								},
							},
						},

						datalabels: {
							formatter: (value, context) => {
								let datavalue = ''

								return datavalue
							},
						},
					},

					onClick: (e) => {
						const activePoints = myChart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true)
						if (activePoints.length) {
							const firstPoint = activePoints[0]
							const value = myChart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index]
							window.location = value.link
						}
					},

					onHover: (event, chartElement) => {
						event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
					},
				},
				plugins: [ChartDataLabels],
			})

			genGradient()
		}
	}

	function genGradient() {
		const ctx = myChart.ctx

		var gradient = {}

		const bgColor = myChart?.config?.data?.datasets[0]?.backgroundColor

		bgColor?.map((itm, index) => {
			gradient[index] = ctx?.createLinearGradient(0, 0, 0, 600)

			gradient[index]?.addColorStop(0, itm)

			gradient[index]?.addColorStop(1, hexToRgbA(itm))

			if (gradient[index] !== undefined) {
				myChart.config.data.datasets[0].backgroundColor[index] = gradient[index]
				myChart.update()
			}
		})
	}

	// Method to convert hex color code to rgba code
	function hexToRgbA(hex) {
		var c
		if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
			c = hex.substring(1).split('')
			if (c.length == 3) {
				c = [c[0], c[0], c[1], c[1], c[2], c[2]]
			}
			c = '0x' + c.join('')
			return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0)'
		}
		throw new Error('Bad Hex')
	}

	onMount(() => {
		createChart(canvas, items, charttype)
	})

	const handlechange = async (selectedItem: any, dropdownLabel: any) => {
		ddlList = []

		selectedItem.forEach((element, index) => {
			label = dropdownitems[index].label
			selectedDropdown = element?.value ? element?.value : ''
			dropdownID = element?.id ? element?.id : 0

			if (label == dropdownLabel) {
				isValueChanged = true
			} else {
				isValueChanged = false
			}

			ddlList = [...ddlList, { label, selectedDropdown, dropdownID, isValueChanged }]
		})

		dispatch(component, 'edi-data-depot-chart:dropdownchange', {
			id,
			myChart,
			ddlList,
			widgettype: 'stackedbarchart',
			user_id,
		})
	}

	document.addEventListener('stackedbarchart_datachanged', (e: any) => {
		if (e?.detail?.widgetID === id) {
			is_pie_chart = e?.detail?.is_pie_chart
			button_link = e?.detail?.newLink

			dropdownitems = e?.detail?.dropdownchanged

			//Reassigning selected items with api result
			selected = []
			dropdownitems.forEach((item) => {
				let selectedDropdown = item.dropdown.find((dropdown) => dropdown.selected === true)

				if (!selectedDropdown) {
					selectedDropdown = item.dropdown[0]
				}

				selected.push(selectedDropdown)
			})

			if (e?.detail?.is_pie_chart) {
				piechartitems = e?.detail?.datachanged
				widget_heading = e?.detail?.widget_heading ? e?.detail?.widget_heading : ''
				is_data_available = e?.detail?.is_data_available
				if (is_data_available) {
					setTimeout(() => {
						createPieChart(canvas, piechartitems, 'pie')
					}, 100)
				}
			} else {
				items = e?.detail?.datachanged
				widget_heading = e?.detail?.widget_heading ? e?.detail?.widget_heading : ''

				is_data_available = e?.detail?.is_data_available
				if (is_data_available) {
					setTimeout(() => {
						createChart(canvas, items, charttype)
					}, 100)
				}
			}
		}
	})

	const handleClick = (url: any) => {
		window.location = url
	}
</script>

{#if dropdownitems}
	<div class="select">
		{#each dropdownitems as item, index}
			<div>
				<span>{item?.label}:</span>
				<select bind:value={selected[index]} on:change={() => handlechange(selected, item?.label)}>
					{#each item?.dropdown as dropdown}
						<option selected={dropdown?.selected} value={dropdown}>
							{dropdown?.value}
						</option>
					{/each}
				</select>
			</div>
			<br />
		{/each}
	</div>
{/if}
<div class="container" style="display:{!is_data_available ? 'none' : ''}">
	{#if widget_heading !== ''}
		<h4>{widget_heading}</h4>
	{/if}

	<div class="canvas_div">
		<canvas bind:this={canvas} />
	</div>

	{#if button_link !== ''}
		<div class="nav">
			<button on:click={() => handleClick(button_link)}>{button_text}</button>
		</div>
	{/if}
</div>

{#if !is_data_available}
	<div>
		{#if widget_heading !== ''}
			<h4>{widget_heading}</h4>
		{/if}
	</div>

	<div class="default">No Data Available</div>
{/if}

<style lang="scss">
	.container {
		width: 100%;
	}

	select {
		height: 20px;
		width: 55%;
		margin-right: 10%;
		border: none;
		border-radius: 5px;
		border-color: #f5f5f5;

		background: #f5f5f5;
		color: #424040;

		font-family: 'Roboto';
		font-style: normal;
	}
	.select {
		text-align: right;

		font-family: 'Roboto';
		font-style: normal;
		font-weight: bold;
		line-height: 19px;

		color: #424040;
	}

	.canvas_div {
		height: 400px;
	}

	canvas {
		padding-right: 15px;
	}

	.nav {
		font-style: normal;
		text-align: center;

		& button {
			font-family: 'Roboto';
			font-weight: bold;
			background-color: #e7e7e7;
			color: black;
			border: none;
			border-radius: 5px;
			padding: 3px 5px;
			text-align: center;
			font-size: 13px;
			cursor: pointer;
		}
	}

	h4 {
		font-family: 'Roboto';
		text-align: center;
		color: #424040;
	}

	.default {
		text-align: center;
		font-family: 'Roboto';
		font-size: 15px;
	}
</style>
