<svelte:options tag="edi-app-bar-navigation-trigger" />

<script lang="ts">
	export let label = ''
	export let active = false
</script>

<span class:active>
	{label}
	<div class="right">
		<slot name="right"/>
	</div>
</span>

<style lang="scss">
	@import '../../styles/mixins';

	@include reset;

	@keyframes stretchRight {
		0% {
			transform: scaleX(0);
		}
		100% {
			transform: scaleX(1);
		}
	}

	.active {
		background: #ECF0F4;
		box-shadow: inset 0px 3px 0px var(--edi-black);
	}

	.right {
		margin-left: .25rem;
	}

	span {
		position: relative;
		display: inline-flex;
		align-items: center;
		box-sizing: border-box;
		font-family: 'Roboto';
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		letter-spacing: 0.005rem;
		padding: 17px;
		white-space: nowrap;
		
		&:hover {
			background: #ECF0F4;
			cursor: pointer;

			&::before {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				box-shadow: inset 0px 3px 0px var(--edi-black);
				transform: scaleX(0);
				transform-origin: left;
				animation-name: stretchRight;
				animation-delay: 150ms;
				animation-duration: 150ms;
				animation-fill-mode: forwards;
				animation-timing-function: ease-out;
			}
		}
	}
</style>
