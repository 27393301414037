<svelte:options tag="edi-footer" />

<script lang="ts">
	import '../Logo'
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../common/dispatch'

	export let versiontext: string
	export let builddateandcacheddatevalue: string

	const component = get_current_component()

	const handleClickfb = () => dispatch(component, 'edi-footer:facebookclick')
	const handleClicktw = () => dispatch(component, 'edi-footer:twitterclick')
	const handleClickli = () => dispatch(component, 'edi-footer:linkedinclick')
</script>

<footer>
	<div class="footer-content">
		<slot name="start">
			<edi-logoicon />
		</slot>

		<div class="content-start">
			<slot name="menu" />
		</div>
		<div class="content-end">
			<svg
				style="vertical-align: middle;fill: white;overflow: hidden;"
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 28.87 28.87"
				on:click={handleClickfb}
			>
				<g data-name="Layer 2">
					<g data-name="Layer 1">
						<rect width="28.87" height="28.87" fill="#fff" rx="6.48" ry="6.48" />
						<path
							fill="#317fb4"
							fill-rule="evenodd"
							d="M16.86 9.29h1.65V6.76c-.26 0-.53-.06-.8-.08h-1.49a3.65 3.65 0 0 0-2.1.65 3.15 3.15 0 0 0-1.27 2.1 7.39 7.39 0 0 0-.09 1.08v1.91h-2.41v2.81h2.4v7.07h2.93v-7.08h2.39l.37-2.83h-2.78v-2c.05-.83.54-1.08 1.2-1.1z"
						/>
					</g>
				</g>
			</svg>

			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				fill-rule="evenodd"
				clip-rule="evenodd"
				image-rendering="optimizeQuality"
				shape-rendering="geometricPrecision"
				text-rendering="geometricPrecision"
				viewBox="0 0 6.827 6.827"
				on:click={handleClicktw}
			>
				<rect
					width="6.827"
					height="6.827"
					fill="#ffffff"
					rx="1.456"
					ry="1.456"
					class="color0a93e2 svgShape"
				/><path
					fill="#317fb4"
					d="M5.49009 2.3776c-0.147055,0.065189 -0.305406,0.109406 -0.47126,0.129142 0.169453,-0.101665 0.299421,-0.262402 0.361008,-0.453976 -0.158654,0.0938819 -0.334398,0.16228 -0.521224,0.199079 -0.149701,-0.159587 -0.363071,-0.259126 -0.599165,-0.259126 -0.45337,0 -0.820579,0.367598 -0.820579,0.820579 0,0.0642559 0.00711024,0.127079 0.021185,0.187126 -0.681961,-0.0343504 -1.28679,-0.361028 -1.69143,-0.857681 -0.0708268,0.121335 -0.111138,0.262335 -0.111138,0.412661 0,0.284827 0.144882,0.536122 0.364846,0.683177 -0.1345,-0.00420866 -0.260886,-0.041248 -0.371744,-0.102835 0,0.00359843 0,0.0068937 0,0.0104961 0,0.397437 0.28298,0.72915 0.658323,0.804508 -0.0687008,0.0188228 -0.141303,0.0289921 -0.216055,0.0289921 -0.0528504,0 -0.104268,-0.00511811 -0.154535,-0.0146811 0.104657,0.326051 0.407697,0.563295 0.766882,0.569866 -0.280921,0.220244 -0.634752,0.351142 -1.01935,0.351142 -0.0664016,0 -0.131807,-0.00357874 -0.19576,-0.0113228 0.363091,0.232799 0.794343,0.368748 1.2578,0.368748 1.5095,0 2.3352,-1.25067 2.3352,-2.33511 0,-0.035563 -0.000909449,-0.0708465 -0.00236614,-0.10611 0.160413,-0.115673 0.299421,-0.260276 0.40937,-0.424677z"
					class="colorfff svgShape"
				/>
			</svg>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 28.87 28.87"
				width="24"
				height="24"
				on:click={handleClickli}
				><g data-name="Layer 2" fill="#000000" class="color000 svgShape"
					><g data-name="Layer 1" fill="#000000" class="color000 svgShape">
						<rect
							width="28.87"
							height="28.87"
							fill="#ffffff"
							rx="6.48"
							ry="6.48"
							class="color0b86ca svgShape"
						/><path
							fill="#317fb4"
							d="M8 12h3v9.68H8zm1.53-4.81a1.74 1.74 0 11-1.74 1.75 1.74 1.74 0 011.74-1.75M12.92 12h2.89v1.32a3.16 3.16 0 012.85-1.56c3 0 3.61 2 3.61 4.61v5.31h-3V17c0-1.12 0-2.57-1.56-2.57s-1.8 1.22-1.8 2.48v4.79h-3z"
							class="colorfff svgShape"
						/>
					</g></g
				></svg
			>
		</div>

		<div class="content-bottom">
			<div>
				<p>OnHand Schools Copyright 2021. All rights reserved.</p>
			</div>
			<div class="version">
				<p>
					<span>{versiontext}</span>

					<span class="display-date">{builddateandcacheddatevalue}</span>
				</p>
			</div>
		</div>
	</div>
</footer>

<style lang="scss">
	@import '@material/elevation/mdc-elevation';
	@import '../../styles/variables';
	@import '../../styles/mixins';

	:host {
		--edi-footer-background-color: var(--edi-blue);
		--edi-footer-position: fixed;
		--edi-footer-cursor: pointer;
	}
	* {
		box-sizing: border-box;
	}
	.footer-content {
		box-sizing: border-box;

		background: var(--edi-footer-background-color);
		width: 100%;
		padding: 0.75rem;
		color: white;
		font-weight: 100;
		font-size: 12px;
	}
	.content-start {
		color: #f4f4f4;
		position: absolute;
		display: flex;
		-moz-column-gap: 0.75rem;
		column-gap: 0.75rem;
		top: 14px;
		left: 3.5rem;
		font-weight: 100;
		font-size: 14px;
	}
	.content-end {
		color: #f4f4f4;
		display: flex;
		-moz-column-gap: 0.75rem;
		column-gap: 0.75rem;
		float: right;
		top: 15px;
		left: 4rem;
		font-weight: 100;
		font-size: 14px;
		svg {
			&:hover {
				cursor: var(--edi-footer-cursor);
			}
		}
	}
	.copyright {
		font-size: 10px;
	}
	.content-bottom {
		font-size: 10px;
		display: flex;
		justify-content: space-between;
	}
	footer {
		position: var(--edi-footer-position);
		left: 0;
		bottom: 0;
		width: 100%;
	}

	.version {
		&:hover {
			cursor: var(--edi-footer-cursor);
			.display-date {
				display: inline-block;
			}
		}

		.display-date {
			display: none;
		}
	}
</style>
