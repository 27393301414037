<svelte:options tag="edi-truancy-alert" />

<script lang="ts">
	// Headings prop
	export let headings = []

	export let items = []
</script>

<div class="container">
	<div class="grid-container">
		<div class="heading {headings.length === 3 && headings[2] !== '' ? 'headingExtra' : ''}" id="heading">
			{#each headings as heading}
				<div>{heading}</div>
			{/each}
		</div>

		{#if items.length > 0}
			<div class="data {headings.length === 3 && headings[2] !== '' ? 'dataExtra' : ''}">
				{#each items as data}
					<div>
						<span>
							{data?.alert}
						</span>
						<span title={data?.title}>
							<edi-svg-up-help />
						</span>
					</div>
					<div>
						<a href={data?.link}>{data?.students}</a>
					</div>
					<div class="attemptDivHidden {headings.length === 3 && headings[2] !== '' ? 'attemptDiv' : ''}">
						{data?.attempt}
					</div>
				{/each}
			</div>
		{:else}
			<div class="default">No Data Available</div>
		{/if}
	</div>
</div>

<style lang="scss">
	.container {
		padding: 10px 10px 10px 10px;
	}

	.heading,
	.headingExtra {
		grid-area: heading;
		display: grid;
		grid-column-gap: 5px;
	}

	.heading {
		grid-template-columns: 1fr 1fr;
	}

	.headingExtra {
		grid-template-columns: 1fr 1fr 1fr;
	}

	.heading > div {
		padding-top: 5px;
		padding-bottom: 5px;
	}
	.headingExtra > div {
		padding: 5px 15px 5px 5px;
	}

	#heading {
		font-weight: bold;

		background: #f5f5f5;
		border-radius: 7px;
	}

	.data,
	.dataExtra {
		grid-area: data;
		display: grid;
	}

	.data {
		grid-template-columns: 1fr 1fr;
	}

	.dataExtra {
		grid-template-columns: 1fr 1fr 1fr;
	}

	.attemptDivHidden {
		display: none;
	}

	.attemptDiv {
		display: grid;
	}

	.data > div {
		padding-top: 5px;
		padding-bottom: 5px;
		border-bottom: 1px solid #f3f3f3;
	}

	.dataExtra > div {
		padding-top: 5px;
		padding-bottom: 5px;
		border-bottom: 1px solid #f3f3f3;
		color: #818181;
	}

	.grid-container {
		width: 100%;
		display: grid;
		grid-template-areas:
			'heading'
			'data';
	}

	.grid-container > div {
		background-color: rgba(255, 255, 255, 0.8);
		text-align: center;
		font-family: 'Roboto';
		font-size: 15px;
	}

	a {
		text-decoration: none;
		color: #2757f2;
	}

	a:hover {
		color: #017698;
	}

	.default {
		text-align: center;
		font-family: 'Roboto';
		font-size: 15px;
	}
</style>
