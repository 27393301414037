<svelte:options tag="edi-icon-list" />

<script lang="ts">
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../common/dispatch'

	const component = get_current_component()

	/* --- Variables Starts --- */

	let icons = ['Folder', 'Book', 'Calculator', 'Earth', 'Atom', 'Flask', 'Calendar', 'Cubes', 'Reader', 'Language']

	/* --- Variables Ends --- */

	/* --- Methods Starts --- */
	const pickIcon = (selectedIcon: string) => {
		dispatch(component, 'edi-icon-list:icon_selected', {
			selectedIcon,
		})
	}
	/* --- Methods Ends --- */
</script>

<div class="container">
	{#each icons as icon}
		<span on:click={() => pickIcon(icon)}>
			<edi-icon-picker icon_name={icon} />
		</span>
	{/each}
</div>

<style lang="scss">
	.container {
		display: grid;
		grid-template-columns: repeat(10, 1fr);
		gap: 10px;

		& span {
			cursor: pointer;
			color: #a2b7d4;
		}
	}
</style>
