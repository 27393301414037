<svelte:options tag="edi-dashboard-filter" />

<script lang="ts">
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../common/dispatch'
	import '../Overlay'
	import './Button.svelte'

	export let filteropen: boolean = false
	const component = get_current_component()

	const handleClose = () => dispatch(component, 'edi-dashboard-filter:closed')

	export let schooltype: any = []
	export let providerslist = []

	var selectedProviderID: number

	var selectedSchoolType: number

	const handlechange = (type) => {
		dispatch(component, 'edi-dashboard-filter-school:change', type)
	}

	const handlesubmit = () => {
		let filtertdata = {
			selectedProviderID: selectedProviderID ? selectedProviderID : providerslist[0]?.id,
		}
		dispatch(component, 'edi-dashboard-filter:Filter', filtertdata)
	}
</script>

<edi-overlay on:click={handleClose} open={filteropen} />

<div class="drawer mdc-elevation--z4" class:drawer--open={filteropen}>
	<div class="drawer__header">
		<mwc-icon class="drawer__close" on:click={handleClose}>close</mwc-icon>
		<h4>Dashboard Filters:</h4>
		<div class="select">
			School Type:
			<select bind:value={selectedSchoolType} on:change={() => handlechange(selectedSchoolType)}>
				{#each schooltype as school}
					<option value={school?.value}>
						{school?.name}
					</option>
				{/each}
			</select>
		</div>
		<br />
		<div class="select">
			Education Provider:
			<select bind:value={selectedProviderID}>
				{#each providerslist as provider}
					<option value={provider?.id}>
						{provider?.name}
					</option>
				{/each}
			</select>
		</div>
		<br />
	</div>

	<div class="btn">
		<edi-dashboard-button buttontext="Cancel" on:click={handleClose} />
		<edi-dashboard-button buttontext="Apply" on:click={handlesubmit} />
	</div>
</div>

<style lang="scss">
	@import '@material/elevation/mdc-elevation';
	@import '../../styles/variables';
	@import '../../styles/mixins';

	* {
		box-sizing: border-box;
	}
	.btn {
		display: flex;
		justify-content: center;
		padding-bottom: 1em;
	}

	.drawer {
		position: fixed;
		font-family: 'Roboto';
		left: -7em;
		top: 5.3em;
		width: 520px;
		background: white;
		z-index: 1000;
		transform: translateX(105%);
		transition: transform 0.5s ease;
		display: none;

		&--open {
			display: unset;
			transform: translateX(105%);
		}

		&__header {
			position: relative;
			width: 100%;
			padding: 1rem calc(24px + 1rem);
			margin: 10px 0;
		}

		&__close {
			position: absolute;
			top: 0.5rem;
			right: 0.5rem;
			cursor: pointer;
			border-radius: 50%;
			padding: 0.25rem;

			&:hover {
				background: var(--edi-gray-ultralight);
			}
		}
	}

	select {
		height: 22px;
		width: 260px;
		float: right;
		box-shadow: 1px 1px 2px 1px rgb(0 0 0 / 12%);
		border: none !important;
	}
</style>
