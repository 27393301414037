<svelte:options tag="edi-sdd-folder-config" />

<script lang="ts">
	import { onMount } from 'svelte'
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../common/dispatch'

	import { sortFolders } from './utils'

	/* --- Props Starts --- */

	export let show_config: boolean = false
	export let title: string = ''
	export let current_folders: any
	export let parent_folders: any
	export let all_assessments: any = []

	/* --- Props Ends --- */

	/* --- Variables Starts --- */

	const component = get_current_component()

	let icons = [
		{ id: 1, value: 'Folder' },
		{ id: 2, value: 'Book' },
	]

	let selected_parent: any
	let folder_name: any = ''
	let selectedIcon = 'Folder'
	let folder_description: any = ''
	let showFolderConfigWindow = true

	let selected_assessments = []

	let showIconList = false

	let searchKey = ''

	let sortOption = 'Name A-Z'

	let sortOptions = ['Name A-Z', 'Name Z-A', 'Created Date', 'Last Updated']

	let sub_folders = []

	let new_folder_id: any // For Parent Folder
	let new_sub_folder_id: any
	let new_folder_name = ''

	let show_archive_button = false // To show the archive button when editing parent folder details
	let is_archived = false

	let is_edit_parent_folder_clicked = false // To idenify if the parent folder edit button is clicked
	let is_edit_subfolder_clicked = false // To idenify if the subfolder edit button is clicked

	let sub_folder_names: any[] = [] // Array to hold all the subfolder names

	let proceedDelete = true // To check whether the parent folder should be deleted

	/* --- Variables Ends --- */

	/* --- Methods Starts --- */

	onMount(() => {
		setTimeout(() => {}, 1000)
	})

	$: if (current_folders) {
		current_folders = sortFolders(current_folders, sortOption)

		createAllSubfoldersNameList(current_folders)
	}

	// Creating a list of all sub folder names from the 'current_folders' list
	const createAllSubfoldersNameList = (currentFolders) => {
		sub_folder_names = []
		currentFolders.forEach((folder: any) => {
			if (folder.subFolders) {
				folder.subFolders.forEach((subFolder: any) => {
					sub_folder_names.push({ folderName: subFolder?.folderName })
				})
			}
		})
	}

	const handleCreate = (e: CustomEvent) => {
		switch (e?.detail?.form_type) {
			case 'folder_create':
				selected_parent = e?.detail?.selected_parent ?? 'Default'

				handlePageTitleUpdate(e?.detail?.folder_name, '', null)

				dispatch(component, 'edi-sdd-folder-config:createnewfolder', e?.detail)
				break
			case 'sub_folder_create':
				handlePageTitleUpdate(e?.detail?.selected_parent, e?.detail?.folder_name, e?.detail?.folder_id)

				dispatch(component, 'edi-sdd-folder-config:createsubfolder', e?.detail)
				break

			case 'edit_folder_details':
				if (e?.detail?.folder_id && !e?.detail?.sub_folder_id) {
					dispatch(component, 'edi-sdd-folder-config:createnewfolder', e?.detail)
				}

				if (e?.detail?.sub_folder_id) {
					dispatch(component, 'edi-sdd-folder-config:createsubfolder', e?.detail)
				}
				break
		}
	}

	// Recieving the newly created parent folder details from 'server side' for editing and sufolder creation.
	document.addEventListener('new_folder_created', (e: any) => {
		/* Added here because folder name not binding correctly when clicking 'Edit Folder Details' button immediately after creating a folder. */
		showFolderConfigWindow = false // Hiding Folder Config window after a successful folder creation.

		new_folder_id = e?.detail?.newFolderID
		selected_parent = e?.detail?.newFolderName
		folder_description = e?.detail?.newFolderDescription
		selectedIcon = e?.detail?.newFolderIcon
		is_archived = e?.detail?.isArchived

		if (e?.detail?.isFolderEdit) {
			is_edit_parent_folder_clicked = true
			folder_name = e?.detail?.newFolderName
		}
	})

	// Recieving the newly created folder id from server side.
	document.addEventListener('new_sub_folder_created', (e: any) => {
		showFolderConfigWindow = false
		new_folder_id = e?.detail?.parentFolderId
		new_sub_folder_id = e?.detail?.newSubFolderID
		folder_name = e?.detail?.newFolderName
		folder_description = e?.detail?.newFolderDescription
		selectedIcon = e?.detail?.newFolderIcon

		selected_parent = e?.detail?.selectedParent
		is_edit_subfolder_clicked = true
	})

	const handleDeleteFolder = (e: CustomEvent) => {
		dispatch(component, 'edi-sdd-folder-config:deletefolder', e?.detail)
	}

	const handleRedirectionToConfigPage = () => {
		showFolderConfigWindow = true

		// Resetting the form fields and assessment list
		sub_folders = []
		selected_parent = 'Default'
		selectedIcon = 'Folder'
		folder_name = ''
		folder_description = ''
		show_archive_button = false
		is_archived = false
		selected_assessments = []

		searchKey = ''
		is_edit_subfolder_clicked = false
		is_edit_parent_folder_clicked = false

		new_folder_id = null
		new_sub_folder_id = null
	}
	const handleSaveAssessments = (e: CustomEvent) => {
		// showFolderConfigWindow = true // Commened as the part of bug fix
		dispatch(component, 'edi-sdd-folder-config:saveassessments', e?.detail)
	}

	const handleSort = () => {
		current_folders = sortFolders(current_folders, sortOption)
	}

	const editFolder = (folderDetails: any) => {
		showFolderConfigWindow = false
		sub_folders = folderDetails?.subFolders ? sortFolders(folderDetails?.subFolders, 'Name A-Z') : []
		show_archive_button = true
		is_edit_parent_folder_clicked = true

		// Checking if selectedAssessments array has value in folder details
		if (folderDetails?.assessments?.selectedAssessments?.length > 0) {
			// Filtering out the availableAssessments list according to the items in selectedAssessments list
			all_assessments = folderDetails?.assessments?.availableAssessments.filter((assessment) => {
				return !folderDetails?.assessments?.selectedAssessments.some(
					(selectedAssessment) =>
						assessment.assessmentId === selectedAssessment.assessmentId &&
						assessment.assessmentName === selectedAssessment.assessmentName
				)
			})
		} else {
			all_assessments = folderDetails?.assessments?.availableAssessments ?? []
		}

		selected_assessments = folderDetails?.assessments?.selectedAssessments ?? []

		selectedIcon = folderDetails?.folderIcon ?? 'Folder'
		folder_name = folderDetails?.folderName ?? ''
		folder_description = folderDetails?.folderDescription ?? ''
		selected_parent = folderDetails?.selectedParent ?? 'Default'
		is_archived = folderDetails?.isArchived

		new_folder_id = folderDetails?.folderId

		handlePageTitleUpdate(folder_name, '', folderDetails?.folderId)
	}

	const deleteFolder = (folderDetails: any) => {
		proceedDelete = true

		if (folderDetails?.subFolders?.some((subfolder) => subfolder?.assessments?.selectedAssessments?.length > 0)) {
			proceedDelete = false
		}

		if (proceedDelete) {
			const confirmationMessage = 'Are you sure you want to delete ' + folderDetails?.folderName + ' ?'
			if (confirm(confirmationMessage)) {
				dispatch(component, 'edi-sdd-folder-config:deletefolder', { folder_id: folderDetails?.folderId })
			}
		} else {
			const alertMessage =
				'Deletion is not possible. ' + folderDetails?.folderName + ' contains assessments in its subfolder/s.'
			alert(alertMessage)
		}
	}

	/* Listening to the event after the model popup is closed from browser.
	   Event dispatched from server side. */
	document.addEventListener('config_popup_closed', (e: any) => {
		showFolderConfigWindow = true
		resetFormInput()
	})

	// Method to reset user input fields after folder creation, save assessments etc.
	const resetFormInput = () => {
		selected_parent = 'Default'
		selectedIcon = 'Folder'

		searchKey = ''
	}

	// Method to handle drag and drop event
	const handleDragAndDrop = (e: CustomEvent) => {
		dispatch(component, 'edi-sdd-folder-config:assessment_drag_and_drop', e?.detail)
	}

	// method to handle filtering the Current Folders list according to searchKey
	$: filteredFolders = () => {
		return current_folders.filter((folder) => {
			return folder?.folderName?.toLowerCase()?.includes(searchKey?.toLowerCase())
		})
	}

	// handling page title changes form the folder config page
	const handlePageTitleUpdate = (parentFolder: string, subFolder: string, parentFolderId: any) => {
		dispatch(component, 'edi-sdd-folder-config:update_page_title', {
			parentFolder,
			subFolder,
			parent_folder_id: parentFolderId,
		})
	}

	// Dispatching an event to the server side to handle the title changes
	const updatePageTitle = (e: CustomEvent) => {
		dispatch(component, 'edi-sdd-folder-config:update_page_title', {
			parentFolder: e?.detail?.parentFolder,
			subFolder: e?.detail?.subFolder,
			parent_folder_id: e?.detail?.parent_folder_id, // For keeping the folder and subfolder details updated.
		})
	}

	/* --- Methods Ends --- */
</script>

{#if show_config}
	<div class="form-popup">
		<div class="body">
			{#if showFolderConfigWindow}
				<edi-folder-details-form
					{parent_folders}
					selected_icon={selectedIcon}
					{selected_parent}
					form_type="folder_create"
					{sub_folder_names}
					on:edi-folder-details-form:handle_create={handleCreate}
				/>

				<br />
				<div class="folder-info">
					<div class="folder-title">
						<h4>Current Folders <span>{filteredFolders()?.length}</span></h4>

						<div class="form-group-container">
							<label for="">Sort By</label>
							<div class="form-group">
								<select bind:value={sortOption} on:change={() => handleSort()}>
									{#each sortOptions as option}
										<option value={option}>
											{option}
										</option>
									{/each}
								</select>
							</div>
							<div class="form-group">
								<input
									type="text"
									placeholder="Search Folder"
									bind:value={searchKey}
									on:input={filteredFolders}
								/>
							</div>
						</div>
					</div>

					<div class="current-folders">
						{#each filteredFolders() as folder}
							<div class="current-folder">
								<div class="folder-icon">
									<edi-icon-picker icon_name={folder?.folderIcon} />
								</div>
								<div>
									<h5>
										{#if folder?.folderName.length > 15}
											{folder?.folderName.substring(0, 15) + '...'}
										{:else}
											{folder?.folderName}
										{/if}
										{#if folder?.isArchived}
											<edi-svg-flag title="This folder is archived" />
										{/if}
									</h5>
									<p>
										{#if folder?.subFolders?.length > 0}
											<span>Folders: {folder?.subFolders?.length} |</span>
										{/if}
										<span>
											Assessments: {folder?.assessments?.selectedAssessments
												? folder?.assessments?.selectedAssessments?.length
												: 0}
										</span>
									</p>
								</div>
								<div class="btns">
									<button class="edit" on:click={() => editFolder(folder)}><edi-svg-pencil /></button>
									{#if !folder?.assessments?.selectedAssessments || folder?.assessments?.selectedAssessments?.length === 0}
										<button class="delete" on:click={() => deleteFolder(folder)}
											><edi-svg-trash />
										</button>
									{/if}
								</div>
							</div>
						{/each}
					</div>
				</div>
			{:else}
				<edi-folder-config-assessment
					{all_assessments}
					{selected_assessments}
					{icons}
					{selected_parent}
					{parent_folders}
					selected_icon={selectedIcon}
					{sub_folders}
					{folder_name}
					{folder_description}
					{new_folder_id}
					{new_sub_folder_id}
					{new_folder_name}
					{show_archive_button}
					{is_archived}
					{is_edit_parent_folder_clicked}
					{is_edit_subfolder_clicked}
					{sub_folder_names}
					on:edi-folder-config-assessment:go_back_to_config_page={handleRedirectionToConfigPage}
					on:edi-folder-config-assessment:save_assessments={handleSaveAssessments}
					on:edi-folder-config-assessment:handle_create={handleCreate}
					on:edi-folder-config-assessment:delete_folder={handleDeleteFolder}
					on:edi-folder-config-assessment:assessment_drag_and_drop={handleDragAndDrop}
					on:edi-folder-config-assessment:update_page_title={updatePageTitle}
				/>
			{/if}
		</div>
	</div>
{/if}

<style lang="scss">
	@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');

	* {
		box-sizing: border-box;
	}

	.form-popup {
		font-family: 'Poppins', sans-serif;
		font-size: 15px;

		padding: 30px;
		color: #435765;
		max-width: 1200px;
		border: 1px solid #ccc;
		border-radius: 12px;
		margin: 40px auto;
	}

	.form-group-container {
		display: flex;
		gap: 15px;
		align-items: center;
	}

	.form-group {
		margin-bottom: 16px;
	}
	.form-group label {
		color: #435765;
		margin-bottom: 8px;
		display: block;
		margin-bottom: 10px;
	}
	.form-group label small {
		color: #9e9e9e;
	}
	.form-group input,
	.form-group select {
		width: 100%;
		height: 50px;
		padding: 12px;
		background: #eff4f9;
		border: none;
		border-radius: 7px;
		outline: none;
	}

	.folder-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 15px;
	}
	.folder-title h4 {
		font-weight: 500;
		font-size: 18px;
		color: #263b54;
		margin: 0;
	}
	.folder-title h4 span {
		background: #e2f3ff;
		color: #317fb4;
		padding: 5px;
		border-radius: 20px;
		font-size: 13px;
		display: inline-block;
		min-width: 30px;
		margin-left: 7px;
		text-align: center;
	}
	.folder-title .form-group {
		width: 300px;
	}

	.current-folders {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 15px;
	}
	.current-folder {
		display: flex;
		align-items: center;
		border: 1px solid #e2e9f3;
		border-radius: 7px;
		padding: 12px;
		position: relative;
		overflow: hidden;
		-webkit-transition: 0.4s;
		-moz-transition: 0.4s;
		transition: 0.4s;
	}
	.current-folder:hover {
		border-color: #317fb4;
	}
	.current-folder .folder-icon {
		background: rgba(226, 233, 243, 0.6);
		border-radius: 5px;
		padding: 12px;
		width: 45px;
		height: 45px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 15px;
		flex-shrink: 0;
	}
	.current-folder .folder-icon img {
		width: 100%;
	}
	.current-folder h5 {
		font-weight: 500;
		margin: 0 0 5px 0;
	}
	.current-folder p {
		font-size: 13px;
		line-height: 20px;
		color: #969899;
		margin: 0;
	}
	.current-folder .btns {
		display: flex;
		gap: 10px;
		position: absolute;
		height: 100%;
		align-items: center;
		top: 0;
		opacity: 0;
		right: -100%;
		-webkit-transition: 0.4s;
		-moz-transition: 0.4s;
		transition: 0.4s;
	}
	.current-folder:hover .btns {
		opacity: 1;
		right: 12px;
	}
	.current-folder .btns button {
		width: 35px;
		height: 35px;
		padding: 10px;
		border-radius: 35px;
		border: none;
		cursor: pointer;
	}
	.current-folder .btns button img {
		width: 100%;
	}
	.current-folder .btns button.edit {
		background: rgb(79 184 255 / 30%);
		color: #4fb8ff;
	}
	.current-folder .btns button.delete {
		background: rgb(255 102 102 / 30%);
		color: #ff6666;
	}

	@media (max-width: 768px) {
		.create-folder-in,
		.folder-title {
			flex-direction: column;
		}
		.folder-title {
			gap: 15px;
			align-items: flex-start;
		}
		.create-folder-left {
			width: 100%;
			padding-right: 0;
			margin-bottom: 20px;
		}
		.create-folder-right,
		.width-50,
		.folder-title .form-group {
			width: 100%;
		}
		.current-folders {
			grid-template-columns: repeat(1, 1fr);
		}
	}

	.header {
		padding: 10px;
		font-family: 'Roboto';
		font-size: 12pt;
		font-weight: bold;
	}

	.body {
		padding-top: 10px;
	}

	.folder-create {
		display: flex;
	}

	.preview-box {
		box-sizing: border-box;

		position: absolute;

		background: #ffffff;
		border: 1px solid #e2e9f3;
		border-radius: 5px;
	}

	.first-row {
		display: flex;
	}

	.second-row {
		display: flex;
	}
</style>
