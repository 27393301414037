<svelte:options tag="edi-svg-arrow-contract" />

<script lang="ts">
	export let width: string
	export let height: string
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	{width}
	{height}
	fill="currentColor"
	class="bi bi-arrows-angle-contract"
	viewBox="0 0 16 16"
>
	<path
		fill-rule="evenodd"
		d="M.172 15.828a.5.5 0 0 0 .707 0l4.096-4.096V14.5a.5.5 0 1 0 1 0v-3.975a.5.5 0 0 0-.5-.5H1.5a.5.5 0 0 0 0 1h2.768L.172 15.121a.5.5 0 0 0 0 .707zM15.828.172a.5.5 0 0 0-.707 0l-4.096 4.096V1.5a.5.5 0 1 0-1 0v3.975a.5.5 0 0 0 .5.5H14.5a.5.5 0 0 0 0-1h-2.768L15.828.879a.5.5 0 0 0 0-.707z"
	/>
</svg>
