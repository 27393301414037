<svelte:options tag="edi-percentile-breakdown-bar-chart" />

<script lang="ts">
	import { Chart, registerables } from 'chart.js'
	import { onMount } from 'svelte'
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../common/dispatch'

	Chart.register(...registerables)
	let canvas: HTMLCanvasElement

	export let charttype: string
	export let items: any

	// Dropdown props
	export let year = []

	// Binding values to these variable when selectiing an item from dropdown
	let yr: number // For year dropdown

	const component = get_current_component()

	let myChart: any

	$: createChart(canvas, items, charttype)
	const createChart = (node: HTMLCanvasElement, items, charttype) => {
		if (myChart) {
			myChart.destroy()
		}
		if (items) {
			myChart = new Chart(node, {
				type: charttype,
				data: items,

				options: {
					resposnsive: true,
					maintainAspectRatio: false,
					parsing: {
						xAxisKey: 'months',
						yAxisKey: 'products.sales',
					},

					onClick: (e) => {
						const activePoints = myChart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true)
						if (activePoints.length) {
							const firstPoint = activePoints[0]

							const value = myChart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index]

							window.open(value.products.link)
						}
					},
				},
			})
		}
	}

	onMount(() => {
		createChart(canvas, items, charttype)
	})

	const handlechange = async (id: number, selectedItem: string) => {
		dispatch(component, 'edi-percentile-breakdown-bar-chart:dropdownchange', {
			widgettype: charttype,
			id,
			myChart,
			selectedItem,
		})
	}
</script>

<div class="container">
	<div class="select">
		<span>Year :</span>
		<span>
			<select bind:value={yr} on:change={() => handlechange(yr, 'year')}>
				{#each year as item}
					<option value={item.id}>
						{item.value}
					</option>
				{/each}
			</select>
		</span>
	</div>
	<br />

	<div style="width: 100%; height: 400px;">
		<canvas bind:this={canvas} />
	</div>
</div>

<style lang="scss">
	.container {
		width: 100%;
	}
	select {
		width: 55%;
		margin-right: 10%;
	}
	.select {
		text-align: right;
	}
</style>
