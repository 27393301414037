<svelte:options tag="edi-app-bar" />

<script lang="ts">
	import { derived, writable } from 'svelte/store'
	import { get_current_component } from 'svelte/internal'
	import _ from 'lodash'
	import '@material/mwc-icon'
	import '@material/mwc-icon-button'
	import { asValue, createContainer } from 'awilix'
	import { APP_BAR_NAVIGATED, APP_BAR_STATE_ITEMS, APP_BAR_STATE_STUDENT_SEARCH } from './constants'
	import type { Item, ItemState } from './utils'
	import dispatch from '../../common/dispatch'
	import { inversion } from '../../common/inversion'
	import '../IconButton'
	import './AppBarAction.svelte'
	import './AppBarSecondaryNav.svelte'
	import './AppBarDrawer.svelte'
	import '../AccountSelect'
	import '../StudentSearch'
	import '../IconButton'
	import '../Drawer'
	import '../AppBarDivider'
	import '../Logo'
	import '../Tooltip'

	// props
	export let items: Item[] = []
	export let elevation: number = 0

	export let current_folders: any = []
	export let favorites: any = []

	export let sdd_config_params: any // For the button to navigate to the config page

	let component = get_current_component()
	const studentSearchState = writable({ open: false })
	const itemsStore: ItemState['items'] = writable([])
	const selectedKeyStore: ItemState['selectedKey'] = writable(null)

	/**
	 * Every navigation item flattened into a single array
	 */
	const flattenedItemsStore = derived(itemsStore, ($items) => {
		const flatten = (items: Item[]) =>
			items.reduce<Item[]>((result, current) => {
				if (current.items) {
					result.push(...flatten(current.items))
				}

				result.push(current)

				return result
			}, [])

		return flatten($items || [])
	})

	/**
	 * Creates a menu item that has only favorited items
	 */
	const favoritedItemStore = derived([flattenedItemsStore], ([$items]) => {
		// find all favorited items
		const favorites = $items.filter((x) => x.isFavorite)

		// don't do extra work
		if (favorites.length === 0) return null as Item

		// need to groupings of the items
		// 10 items per group (magic number, can be changed if needed)
		const groups = []
		for (let i = 0; i < favorites.length; i += 10) {
			groups.push(favorites.slice(i, i + 10))
		}

		const [group1, ...rest] = groups

		// create our favorite item
		return {
			label: 'Favorites',
			key: '__favorites__',
			designClass: 'navigation-drawer__body',
			items: [
				{
					label: 'My Favorites Menu',
					designClass: 'mega-item__title',
					items: group1,
				},
				...rest.map((x) => ({ items: x })),
			],
		} as Item
	})

	/**
	 * The seleted menu item found based on the selected key
	 */
	const selectedItemStore = derived(
		[flattenedItemsStore, selectedKeyStore, favoritedItemStore],
		([$items, $selectedKey, $favoritedItem]) => {
			// selected item could include the favorited item
			return ($favoritedItem ? [$favoritedItem, ...$items] : $items).find(({ key }) =>
				_.isEqual(key, $selectedKey)
			)
		}
	)

	/**
	 * List of all items that are set as indexed
	 */
	const indexedItemsStore = derived(flattenedItemsStore, ($items) => $items.filter((item) => item?.indexed))

	// setup our IOC container
	const container = createContainer()

	// register the ItemState
	container.register(
		APP_BAR_STATE_ITEMS,
		asValue<ItemState>({
			items: itemsStore,
			indexedItems: indexedItemsStore,
			selectedKey: selectedKeyStore,
			selectedItem: selectedItemStore,
			favoriteItem: favoritedItemStore,
		})
	)

	// register our student search state
	container.register(APP_BAR_STATE_STUDENT_SEARCH, asValue(studentSearchState))

	// if items are updated we need to reset the item store
	$: itemsStore.set(items)

	// the drawer should be open when the selected item has subitems
	$: open = $selectedItemStore?.items

	/* when the selected item has NO subitems
	 * we need to send a navigated event
	 *
	 * selected item can sometimes be null (represents no selected item)
	 * we need to not send the event when selected item is null
	 */
	$: if ($selectedItemStore && !$selectedItemStore.items) {
		dispatch(component, APP_BAR_NAVIGATED, {
			item: $selectedItemStore,
		})
	}

	/**
	 * handles closing the app bar drawer by deselecting the
	 * selected menu item
	 */
	const handleDrawerClosed = () => selectedKeyStore.set(null)
</script>

<nav use:inversion={container.resolve.bind(container)} class="mdc-elevation--z2">
	<div class="primary-navigation">
		<slot name="start">
			<edi-logo />
		</slot>

		<div class="student-search">
			<slot name="student-search" />
		</div>

		<div class="account-select" class:account-select--hide={$studentSearchState.open}>
			<slot name="account-select" />
		</div>

		<slot name="actions" />

		<slot name="end" />
	</div>

	<edi-app-bar-secondary-nav />

	<edi-app-bar-drawer
		on:edi-app-bar:navigated={handleDrawerClosed}
		on:edi-app-bar-drawer:closed|stopPropagation={handleDrawerClosed}
		{open}
		{current_folders}
		{favorites}
		{sdd_config_params}
	/>
</nav>

<style lang="scss">
	@import '@material/elevation/mdc-elevation';
	@import '../../styles/variables';
	@import '../../styles/mixins';

	@include reset;

	:host {
		--edi-app-bar-background-color: var(--edi-blue);
		--edi-app-bar-position: absolute;
	}

	mwc-icon {
		color: white;
	}

	.student-search {
		flex-grow: 1;
	}

	.account-select {
		&--hide {
			display: none;
		}
	}

	nav {
		position: var(--edi-app-bar-position);
		top: 0;
		left: 0;
		width: 100vw;
		max-width: 100%;
	}

	.primary-navigation {
		box-sizing: border-box;
		background: var(--edi-app-bar-background-color);
		width: 100%;
		padding: 0.75rem;
		display: flex;
		column-gap: 0.75rem;
		align-items: center;
		justify-content: space-between;
	}

	.icon {
		&:hover {
			cursor: pointer;
		}
	}
</style>
