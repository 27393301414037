<svelte:options tag="edi-drawer-item" />

<script lang="ts">
	export let icon: string;
	export let text: string;
</script>

<div class="item">
	<mwc-icon>{icon}</mwc-icon>
	<span>{text}</span>
</div>

<style lang="scss">
	@import '../../styles/variables';

	* {
		box-sizing: border-box;
	}

	:host {
		--edi-drawer-item-font-color: var(--edi-dark);
		--edi-drawer-item-hover-bg: var(--edi-gray-ultralight);
	}

	div {
		width: 100%;
		color: var(--edi-drawer-item-font-color);
		font-family: 'Roboto';
		font-weight: 600;
		display: flex;
		align-items: center;
		padding: .75rem 1.5rem;
		cursor: pointer;

		mwc-icon {
			margin-right: 1rem;
		}

		&:hover {
			background: var(--edi-drawer-item-hover-bg);
		}
	}
</style>
