<svelte:options tag="edi-tooltip" />

<script lang="ts">
	import { onMount, tick } from 'svelte'
	import { writable } from 'svelte/store'
	import { createPopper } from '@popperjs/core'
	import type { Placement, Instance, OptionsGeneric } from '@popperjs/core'

	export let text: string
	export let position: Placement = 'auto'

	const store = writable<any>({})
	let previousTargetEl: Element
	let targetEl: Element
	let slotRef: HTMLSlotElement
	let tooltipRef: HTMLDivElement
	let arrowRef: HTMLDivElement
	let show = false
	let popper: Instance


	onMount(() => {
		targetEl = slotRef.assignedElements()[0]

		if (!targetEl) return;

		const mouseenter = () => show = true;
		const mouseleave = () => show = false;

		// setup event listeners for tooltip
		// handles showing and hiding the tooltip
		targetEl.addEventListener('mouseenter', mouseenter)
		targetEl.addEventListener('mouseleave', mouseleave)

		return () => {
			targetEl.removeEventListener('mouseenter', mouseenter)
			targetEl.removeEventListener('mouseleave', mouseleave)
		}
	})

	/**
	 * Options used to pass to popper
	 */
	$: options = {
		placement: position,
		modifiers: [
			{
				name: "updateStore",
				enabled: true,
				phase: "write",
				fn: ({ state }) => store.set(state)
			},
			{
				name: 'offset',
				options: {
					offset: [0, 8]
				}
			},
			{
				name: 'arrow',
				options: {
					element: arrowRef,
					padding: 4
				},
			},
		]
	} as OptionsGeneric<any>

	/**
	 * Dynamic statement used for updating the placement class
	*/
	$: placementClass = `tooltip--${$store.placement || position}`

	/**
	 * Apply the options to the popper instance if it exists
	*/
	$: popper?.setOptions(options)

	/**
	 * Create a new popper anytime the target element changes
	*/
	$: (async () => {
		if (previousTargetEl === targetEl) return;

		// wait for update
		await tick()

		// destroy popper
		popper?.destroy();

		// check for target element
		if (!targetEl) return;

		// create our popper instance
		previousTargetEl = targetEl
		popper = createPopper(targetEl, tooltipRef, options)
	})();

	/**
	 * Force popper to update so the tooltip is in the proper place after being shown
	*/
	$: if (show && popper) {
		popper.update()
	}
</script>


<slot bind:this={slotRef}></slot>

<div
	bind:this={tooltipRef}
	class="{placementClass} tooltip mdc-elevation--z4"
	class:tooltip--show={show}
>
	<div bind:this={arrowRef} class="arrow mdc-elevation--z4"></div>
	{text}
</div>


<style lang="scss">
	@import '@material/elevation/mdc-elevation';
	@import '../../styles/variables';

	* {
		box-sizing: border-box;
	}

	.fade-out {
		visibility: hidden;
		opacity: 0;
		transition:
			opacity .25s ease-in,
			visibility .25s ease-in;
		transition-delay: .25s;
	}

	.fade-in {
		visibility: visible;
		opacity: 1;
		transition:
			opacity .25s ease-out,
			visibility .25s ease-out;
		transition-delay: .25s;
	}


	.tooltip {
		@extend .fade-out;
		display: inline-block;
		color: white;
		background: var(--edi-black);
		padding: .5rem 1rem;
		border-radius: 4px;
		font-family: 'Roboto';
		font-size: 12px;
		z-index: 600;

		&[class^='tooltip--top'] .arrow {
			bottom: -4px;
		}

		&[class^='tooltip--bottom'] .arrow {
			top: -4px;
		}

		&[class^='tooltip--left'] .arrow {
			right: -4px;
		}

		&[class^='tooltip--right'] .arrow {
			left: -4px;
		}

		&--show {
			@extend .fade-in;

			.arrow:before {
				@extend .fade-in;
			}
		}
	}

	.arrow {
		visibility: hidden;
		position: absolute;
		width: 8px;
		height: 8px;
		background: inherit;

		&:before {
			@extend .fade-out;
			position: absolute;
			width: 8px;
			height: 8px;
			background: inherit;
			content: '';
			transform: rotate(45deg);
		}
	}
</style>
