import { INVERSION_REQUEST } from './inversion';
import { tick } from 'svelte';
/**
 * Request injection for a given element + token
 * @param node element to send events from
 * @param token the token to request an instance for
 * @returns
 */
const inject = (node, token) => {
    // dispatch the request for the token
    const event = new CustomEvent(INVERSION_REQUEST, {
        bubbles: true,
        cancelable: true,
        composed: true,
        detail: {
            token
        }
    });
    /* Note:
     * could use setTimeout(() => {}, 0) instead of tick() below
     * this would be useful if we wanted to make this method "vanilla"
     */
    // we need this to happen after all pending UI changes
    (async () => {
        // wait for pending UI changes
        await tick();
        node.dispatchEvent(event);
        // no instance to "return"
        if (!event.detail.instance)
            return;
        // dispatch a injected event
        node.dispatchEvent(new CustomEvent("injected", {
            bubbles: true,
            cancelable: true,
            composed: true,
            detail: event.detail
        }));
    })();
};
export const injectable = (node, token) => {
    // initial injection
    inject(node, token);
    return {
        // updates to the token should trigger a new injection request
        update(token) {
            inject(node, token);
        }
    };
};
