import dispatch from '../../common/dispatch';
import { APP_BAR_NAVIGATED, APP_BAR_UNFAVORITED, APP_BAR_FAVORITED } from './constants';
/**
 * creates an event dispatcher for dispatching
 * edi-app-bar:navigated events
 */
export const dispatchAppBarNavigated = (element, item) => () => {
    dispatch(element, APP_BAR_NAVIGATED, { item });
};
/**
 * creates an event dispatcher for dispatching
 * the following events
 *  - edi-app-bar:favorited
 *  - edi-app-bar:unfavorited
 */
export const dispatchAppBarFavorite = (element, item) => () => {
    if (item.isFavorite) {
        return dispatch(element, APP_BAR_UNFAVORITED, { item });
    }
    dispatch(element, APP_BAR_FAVORITED, { item });
};
