<svelte:options tag="edi-icon-picker" />

<script lang="ts">
	export let icon_name: any
</script>

{#if icon_name === 'Folder'}
	<edi-svg-folder-open />
{:else if icon_name === 'Book'}
	<edi-svg-book />
{:else if icon_name === 'Pencil'}
	<edi-svg-pencil />
{:else if icon_name === 'Calculator'}
	<edi-svg-calculator />
{:else if icon_name === 'Earth'}
	<edi-svg-earth />
{:else if icon_name === 'Atom'}
	<edi-svg-atom />
{:else if icon_name === 'Flask'}
	<edi-svg-flask />
{:else if icon_name === 'Calendar'}
	<edi-svg-calendar />
{:else if icon_name === 'Cubes'}
	<edi-svg-cubes />
{:else if icon_name === 'Reader'}
	<edi-svg-reader />
{:else if icon_name === 'Language'}
	<edi-svg-language />
{/if}
