<svelte:options tag="edi-dashboard-container" />

<script lang="ts">
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../common/dispatch'

	const component = get_current_component()

	import './AddWidget.svelte'
	import './DashboardFilter.svelte'

	let hidden = true

	export let open: any
	export let widgetlist: any = []

	export let filteropen: any
	export let schooltype: any = []
	export let providerslist: any = []

	// When the user scrolls down 20px from the top of the document, show the button
	function scrollFunction() {
		if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
			hidden = false
		} else {
			hidden = true
		}
	}

	// When the user clicks on the button, scroll to the top of the document
	function topFunction() {
		document.body.scrollTop = 0
		document.documentElement.scrollTop = 0
	}

	const handleClose = () => {
		dispatch(component, 'edi-dashboard-container:closed')
	}
	const handleAdd = (e) => {}

	const handleFilterClose = () => {
		dispatch(component, 'edi-dashboard-container-filter:closed')
	}

	const handleFilter = (e) => {}

	const handleSchoolTypeChange = (e) => dispatch(component, 'edi-dashboard-container-filter-school:change', e)
</script>

<!-- <svelte:window on:scroll={scrollFunction} />
<button on:click={topFunction} id="myBtn" title="Go to top" class="myBtn" class:hidden>Top</button> -->

<div>
	<div class="header">
		<slot name="header" />
	</div>

	<div>
		<edi-dashboard-addwidget
			{open}
			{widgetlist}
			on:edi-dashboard-addwidget:closed={handleClose}
			on:edi-dashboard-addwidget:Add={handleAdd}
		/>
	</div>

	<div>
		<edi-dashboard-filter
			{filteropen}
			{schooltype}
			{providerslist}
			on:edi-dashboard-filter:closed={handleFilterClose}
			on:edi-dashboard-filter:Filter={handleFilter}
			on:edi-dashboard-filter-school:change={handleSchoolTypeChange}
		/>
	</div>

	<slot name="content" />
</div>

<style lang="scss">
	.header {
		display: flex;
		justify-content: flex-start;
	}

	.myBtn {
		position: fixed;
		bottom: 20px;
		right: 30px;
		z-index: 99;
		font-size: 18px;
		border: none;
		outline: none;
		background-color: rgba(114, 188, 210, 0.9);
		color: white;
		cursor: pointer;
		padding: 15px;
		border-radius: 4px;
	}

	.myBtn:hover {
		background: linear-gradient(60.82deg, #42d3d3 5.98%, #9ee2e2 51.19%);
	}

	.myBtn.hidden {
		opacity: 0;
		visibility: hidden;
	}
</style>
