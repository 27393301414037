<svelte:options tag="edi-drawer-divider" />

<div />

<style lang="scss">
	div {
		width: 100%;
		border-bottom: 1px solid #DBDBDB;
		margin: 10px 0;
	}
</style>
