<svelte:options tag="edi-svg-pencil" />

<script lang="ts">
</script>

<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M16.5981 5.28321L15.1323 6.74902C14.9828 6.89846 14.7412 6.89846 14.5917 6.74902L11.0624 3.21963C10.9129 3.07018 10.9129 2.82853 11.0624 2.67909L12.5282 1.21328C13.1228 0.618686 14.0894 0.618686 14.6871 1.21328L16.5981 3.12424C17.1959 3.71883 17.1959 4.68544 16.5981 5.28321ZM9.80322 3.93822L1.4535 12.2879L0.779421 16.1512C0.687212 16.6726 1.1419 17.1242 1.66336 17.0351L5.52661 16.3579L13.8763 8.00815C14.0258 7.85871 14.0258 7.61706 13.8763 7.46761L10.3469 3.93822C10.1943 3.78878 9.95266 3.78878 9.80322 3.93822ZM4.71263 11.5725C4.53775 11.3976 4.53775 11.1178 4.71263 10.943L9.60926 6.04632C9.78414 5.87144 10.0639 5.87144 10.2388 6.04632C10.4137 6.2212 10.4137 6.50101 10.2388 6.67589L5.34219 11.5725C5.16731 11.7474 4.88751 11.7474 4.71263 11.5725ZM3.56478 14.2466H5.091V15.4008L3.04014 15.7601L2.05127 14.7712L2.41057 12.7204H3.56478V14.2466Z"
		fill="#4FB8FF"
	/>
</svg>
