<svelte:options tag="edi-linechart" />

<script lang="ts">
	import { Chart, registerables } from 'chart.js'
	import { onMount } from 'svelte'
	Chart.register(...registerables)

	let canvas: HTMLCanvasElement
	let myChart: any

	export let charttype: string
	export let items: any

	// Chart axes title props
	export let x_axis_title: any
	export let y_axis_title: any

	// Chart tooltip label prop
	export let tooltip_label: any

	$: createChart(canvas, items, charttype)
	const createChart = (node: HTMLCanvasElement, items, charttype) => {
		if (myChart) {
			myChart.destroy()
		}
		if (items) {
			myChart = new Chart(node, {
				type: charttype,
				data: items,

				options: {
					resposnsive: true,
					maintainAspectRatio: false,
					parsing: {
						xAxisKey: 'months',
						yAxisKey: 'sales',
					},
					plugins: {
						legend: {
							position: 'bottom',
							onHover: (event) => {
								event.native.target.style.cursor = 'pointer'
							},

							onLeave: (event) => {
								event.native.target.style.cursor = 'default'
							},

							labels: {
								font: {
									size: 14,
									weight: 'bold',
								},
							},
						},

						tooltip: {
							callbacks: {
								label: function (context) {
									let label = ''

									if (context.parsed.y !== null) {
										label = context.parsed.y
										label += tooltip_label
									}
									return label
								},
							},
						},
					},

					scales: {
						yAxes: {
							title: {
								display: true,
								text: x_axis_title,
								font: {
									size: 15,
									weight: 'bold',
								},
							},
							ticks: {
								precision: 0,
								font: {
									weight: 'bold',
								},
							},
							grid: {
								color: 'rgba(242, 242, 242, 0.5)',
							},
						},
						xAxes: {
							offset: true, // If true, extra space is added to the both edges and the axis is scaled to fit into the chart area.
							title: {
								display: true,
								text: y_axis_title,
								font: {
									size: 15,
									weight: 'bold',
								},
							},
							ticks: {
								font: {
									weight: 'bold',
								},
							},
							grid: {
								color: 'rgba(242, 242, 242, 0.5)',
							},
						},
					},

					onClick: (e) => {
						const activePoints = myChart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true)
						if (activePoints.length) {
							const firstPoint = activePoints[0]

							const value = myChart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index]

							// window.open(value.link)
							window.location = value.link
						}
					},

					onHover: (event, chartElement) => {
						event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
					},
				},
			})
		}
	}

	onMount(() => {
		createChart(canvas, items, charttype)
	})
</script>

{#if items}
	{#if items?.length === 0}
		<div class="default">No Data Available</div>
	{:else}
		<div style="width: 100%; height: 400px;">
			<canvas bind:this={canvas} />
		</div>
	{/if}
{/if}

<style lang="scss">
	.default {
		text-align: center;
		font-family: 'Roboto';
		font-size: 15px;
	}
</style>
