<svelte:options tag="edi-drawer" />

<script lang="ts">
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../common/dispatch'
	import './DrawerItem.svelte'
	import './DrawerDivider.svelte'
	import '../Overlay'

	export let open: boolean = false

	const component = get_current_component()

	const handleClose = () => dispatch(component, 'edi-drawer:closed')
</script>

<edi-overlay {open} on:click={handleClose} />

<div
	class="drawer mdc-elevation--z4"
	class:drawer--open={open}
>
	<div class="drawer__header">
		<mwc-icon class="drawer__close" on:click={handleClose}>close</mwc-icon>
		<slot name="header"></slot>
	</div>

	<edi-drawer-divider />

	<div class="drawer__body">
		<slot name="body"></slot>
	</div>
</div>

<style lang="scss">
	@import '@material/elevation/mdc-elevation';
	@import '../../styles/variables';
	@import '../../styles/mixins';

	* {
		box-sizing: border-box;
	}

	.drawer {
		position: fixed;
		right: 0;
		top: 0;
		width: 301px;
		height: 100vh; // use vh instead of percent.
		background: white;
		z-index: 1000;
		transform: translateX(105%);
		transition: transform 0.5s ease;

		&--open {
			transform: translateX(0%);
		}

		&__header {
			position: relative;
			width: 100%;
			padding: 1rem calc(24px + 1rem);
			margin: 10px 0;
		}

		&__close {
			position: absolute;
			top: .5rem;
			right: .5rem;
			cursor: pointer;
			border-radius: 50%;
			padding: .25rem;

			&:hover {
				background: var(--edi-gray-ultralight);
			}
		}
	}
</style>
