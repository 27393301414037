<svelte:options tag="edi-dashboard-addwidget" />

<script lang="ts">
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../common/dispatch'
	import '../Overlay'
	import './Button.svelte'

	export let open: boolean = false
	const component = get_current_component()

	const handleClose = () => dispatch(component, 'edi-dashboard-addwidget:closed')

	let Selected = []

	export let widgetlist: any = []

	const handlesubmit = () => {
		dispatch(component, 'edi-dashboard-addwidget:Add', Selected)
		Selected = []
	}
</script>

<edi-overlay on:click={handleClose} {open} />

<div class="drawer mdc-elevation--z4" class:drawer--open={open}>
	<div class="drawer__header">
		<mwc-icon class="drawer__close" on:click={handleClose}>close</mwc-icon>
		<h4>choose widgets to add to your dashboard:</h4>

		{#each widgetlist as widget}
			<div>
				{#if widget?.list?.length !== 0}
					{#if widget?.showTitle}
						<p>{widget?.title}</p>
					{/if}

					{#each widget?.list as list}
						{#if list?.isActive}
							<label>
								<input type="checkbox" bind:group={Selected} value={list.id} />
								{list.value} <br />
							</label>
						{/if}
					{/each}
				{/if}
			</div>
		{/each}
	</div>

	<div class="btn">
		<edi-dashboard-button buttontext="Add widget" on:click={handlesubmit} />
	</div>
</div>

<style lang="scss">
	@import '@material/elevation/mdc-elevation';
	@import '../../styles/variables';
	@import '../../styles/mixins';

	* {
		box-sizing: border-box;
	}
	.btn {
		float: right;
		padding-bottom: 1em;
	}

	.drawer {
		position: fixed;
		font-family: 'Roboto';
		left: -7em;
		top: 5.3em;
		width: 301px;
		background: white;
		z-index: 1000;
		transform: translateX(105%);
		transition: transform 0.5s ease;
		display: none;

		&--open {
			display: unset;
			transform: translateX(105%);
		}

		&__header {
			position: relative;
			width: 100%;
			padding: 1rem calc(24px + 1rem);
			margin: 10px 0;
		}

		&__close {
			position: absolute;
			top: 0.5rem;
			right: 0.5rem;
			cursor: pointer;
			border-radius: 50%;
			padding: 0.25rem;

			&:hover {
				background: var(--edi-gray-ultralight);
			}
		}
	}
</style>
