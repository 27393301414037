<svelte:options tag="edi-widget-container" />

<script lang="ts">
	import Sortable from 'sortablejs'
	import { onMount } from 'svelte'
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../common/dispatch'

	const component = get_current_component()

	// Props
	export let data = []
	export let ids = []
	export let iscustomdash: any

	// Variables used for setting the widget container min-height dynamically
	let container
	let height = 500

	let container1, container2, container3, container4

	let containerList = [
		{
			name: 'container1',
			instance: container1,
			sortInstance: '',
		},
		{
			name: 'container2',
			instance: container2,
			sortInstance: '',
		},
		{
			name: 'container3',
			instance: container3,
			sortInstance: '',
		},
		{
			name: 'container4',
			instance: container4,
			sortInstance: '',
		},
	]

	// Variables for toggling full screen and normal widget mode
	let isFullScreen = false
	let clickedWidgetID: any

	onMount(async function () {
		containerList.forEach((item, index) => {
			inItContainers(item.name, item.instance, index)
		})
	})

	// Method to intialize containers
	const inItContainers = (containerName: string, containerInstance: any, ind: any) => {
		containerList[ind]['sortInstance'] = Sortable.create(containerInstance, {
			group: {
				name: containerName,
				put: !iscustomdash ? true : false, // Disables drag and drop if iscustomdash is true
			},
			animation: 200,
			sort: !iscustomdash ? true : false, // Disables widget sorting if iscustomdash is true

			// Element dragging started
			onStart: function (/**Event*/ evt) {
				updateContainerHeight('drag_started', '')
			},
			// Changed sorting within list
			onUpdate: function (/**Event*/ evt) {
				let itemOldIndex = evt.oldIndex
				let itemNewIndex = evt.newIndex

				let currentContainer = containerName

				let draggedElementID = evt.item.id // dragged HTMLElement

				dispatch(component, 'edi-widget-container:widgetsort', {
					itemOldIndex,
					itemNewIndex,
					currentContainer,
					draggedElementID,
				})
			},
			// Element is dropped into the list from another list
			onAdd: function (/**Event*/ evt) {
				let draggedElementID = evt.item.id // dragged HTMLElement

				let targetListID = evt.to.id // target list

				let itemOldIndex = evt.oldIndex
				let itemNewIndex = evt.newIndex

				updateContainerHeight('widget_dropped', draggedElementID)

				dispatch(component, 'edi-widget-container:widgetadd', {
					draggedElementID,
					targetListID,
					itemOldIndex,
					itemNewIndex,
				})
			},
		})
	}

	// Method to handle the widget closing
	const handleClose = (id: any, containerId: any) => {
		data = data.filter((item) => item.body.id !== id)

		ids = ids.filter((item) => item.id !== id)

		updateContainerHeight('widget_removed', id)

		// Implemented the dispatcher for handling api side widget closing
		dispatch(component, 'edi-widget-container:widgetclose', { widgetid: id })
	}

	const handleToggle = (id: any) => {
		const findex = data.findIndex((y) => y.id === id)

		// If the 'id' is found in the 'data' array
		if (findex !== -1) {
			data[findex].body.showcontent = !data[findex].body.showcontent
		}
	}

	const dropdownchange = (e: CustomEvent) => {
		dispatch(component, 'edi-widget-container:dropdownchange', e.detail)
	}

	const pagechange = (e: CustomEvent) => {
		dispatch(component, 'edi-widget-container:pagechange', e.detail)
	}

	const pdfdownload = (e: CustomEvent) => {
		dispatch(component, 'edi-widget-container:pdfdownload', e.detail)
	}

	const calculateriskscore = (e: CustomEvent) => {
		dispatch(component, 'edi-widget-container:calculateriskscore', e.detail)
	}

	// To handle the date change event for Today's Lesson widget
	const datechange = (e: CustomEvent) => {
		dispatch(component, 'edi-widget-container:datechange', e.detail)
	}

	// For toggling the structure of Today's Lessons widget
	const viewchange = (e: CustomEvent) => {
		dispatch(component, 'edi-widget-container:viewchange', e.detail)
	}

	// For handling MTSS redirection on the backend side - Anandu 05/29/2024
	const mtssnavigation = (e: CustomEvent) => {
		dispatch(component, 'edi-widget-container:mtssnavigation', e.detail)
	}

	const getWidgetIdOrders = (param) => {
		return param.sort((a, b) => {
			return a.orderId - b.orderId
		})
	}
	const filterContainers = (arr, itm) => {
		return arr.filter((x) => x.container === itm.name)
	}

	const fdata = (data, idList) => {
		return data[data.findIndex((x) => idList.id === x.id)]
	}

	const isdata = (data, idList) => {
		return data.findIndex((x) => idList.id === x.id)
	}

	const handleFullScreenToggle = (id: any) => {
		clickedWidgetID = id
		isFullScreen = !isFullScreen
	}

	$: if (!iscustomdash) {
		containerList.map((itm: any) => {
			if (itm?.sortInstance) {
				itm.sortInstance.options.disabled = false
			}
		})
	} else {
		containerList.map((itm: any) => {
			if (itm?.sortInstance) {
				itm.sortInstance.options.disabled = true
			}
		})
	}

	// Method to update the widget container height
	const updateContainerHeight = (event: string, widgetID: any) => {
		if (event === 'drag_started') {
			const element1 = container?.querySelector('#container1')
			const element2 = container?.querySelector('#container2')
			const element3 = container.querySelector('#container3')

			// Finding the largest value among the above element's clientHeight and assigning it into 'height' field.
			height = Math.max(element1?.clientHeight, element2?.clientHeight, element3?.clientHeight) ?? 500
		} else {
			/* Reducing the widget container(1,2 and 3)'s height after a widget is dropped or removed.
			Finding the clientHeight of dragged/removed element using widgetID and reduce the clientHeight value from 'height'. */

			const element1 = container?.querySelectorAll('.widget')

			element1.forEach((element) => {
				if (element?.id == widgetID) {
					height = height - element?.clientHeight
				}
			})
		}
	}
</script>

<div class="container" bind:this={container}>
	{#each containerList as itm, j}
		<section
			id={itm.name}
			bind:this={itm.instance}
			style={itm.name !== 'container4' ? `min-height: ${height}px;` : `min-height: 500px;`}
		>
			{#each getWidgetIdOrders(filterContainers(ids, itm)) as idList, i}
				{#if isdata(data, idList) === -1}
					<edi-skeleton-loader />
				{/if}

				{#if fdata(data, idList)?.body}
					<div
						class={clickedWidgetID === fdata(data, idList)?.body?.id && isFullScreen
							? 'fullscreen'
							: 'widget'}
						id={fdata(data, idList)?.body?.id}
					>
						<div class="header">
							<span>{fdata(data, idList)?.body?.title}</span>
							{#if !iscustomdash}
								<span style="float: right; margin-left: 5px;">
									{#if !isFullScreen}
										<div
											on:click={() => handleClose(fdata(data, idList)?.body?.id, itm?.name)}
											style="cursor: pointer;"
											class="rm-btn"
										>
											<edi-svg-close width="16" height="16" />
										</div>
									{/if}
								</span>
								<span style="float: right;">
									{#if fdata(data, idList)?.body?.showcontent && !isFullScreen}
										<div
											on:click={() => handleToggle(fdata(data, idList)?.body?.id)}
											style="cursor: pointer;"
										>
											<edi-svg-up-fill width="16" height="16" />
										</div>
									{/if}
									{#if !fdata(data, idList)?.body?.showcontent && !isFullScreen}
										<div
											on:click={() => handleToggle(fdata(data, idList)?.body?.id)}
											style="cursor: pointer;"
										>
											<edi-svg-down-fill width="16" height="16" />
										</div>
									{/if}
								</span>

								<span style="float: right; padding-right: 5px">
									{#if !isFullScreen}
										<div
											title="Go full screen"
											on:click={() => handleFullScreenToggle(fdata(data, idList)?.body?.id)}
											style="cursor: pointer;"
										>
											<edi-svg-arrow-expand width="16" height="16" />
										</div>
									{:else}
										<div
											title="Exit full screen"
											on:click={() => handleFullScreenToggle(fdata(data, idList)?.body?.id)}
											style="cursor: pointer;"
										>
											<edi-svg-arrow-contract width="16" height="16" />
										</div>
									{/if}
								</span>
							{/if}
						</div>
						{#if fdata(data, idList)?.body?.showcontent}
							<div class="content">
								{#if fdata(data, idList)?.body?.type === 'linechart'}
									<edi-linechart
										charttype={fdata(data, idList)?.body?.widgetdata?.type}
										items={fdata(data, idList)?.body?.widgetdata?.data}
										x_axis_title={fdata(data, idList)?.body?.widgetdata?.custom_fields?.x_axis_title
											? fdata(data, idList)?.body?.widgetdata?.custom_fields?.x_axis_title
											: ''}
										y_axis_title={fdata(data, idList)?.body?.widgetdata?.custom_fields?.y_axis_title
											? fdata(data, idList)?.body?.widgetdata?.custom_fields?.y_axis_title
											: ''}
										tooltip_label={fdata(data, idList)?.body?.widgetdata?.custom_fields
											?.tooltip_label
											? fdata(data, idList)?.body?.widgetdata?.custom_fields?.tooltip_label
											: ''}
									/>
								{/if}

								{#if fdata(data, idList)?.body?.type === 'barchart'}
									<edi-barchartm
										id={fdata(data, idList)?.body?.id}
										user_id={fdata(data, idList)?.body?.userId}
										title={fdata(data, idList)?.body?.title}
										charttype={fdata(data, idList)?.body?.widgetdata?.type}
										items={fdata(data, idList)?.body?.widgetdata?.data}
										dropdownitems={fdata(data, idList)?.body?.widgetdata?.dropdowndata
											? fdata(data, idList)?.body?.widgetdata?.dropdowndata
											: []}
										button_link={fdata(data, idList)?.body?.widgetdata?.custom_fields?.button_link}
										x_axis_title={fdata(data, idList)?.body?.widgetdata?.custom_fields
											?.x_axis_title}
										y_axis_title={fdata(data, idList)?.body?.widgetdata?.custom_fields
											?.y_axis_title}
										tooltip_label={fdata(data, idList)?.body?.widgetdata?.custom_fields
											?.tooltip_label}
										button_text={fdata(data, idList)?.body?.widgetdata?.custom_fields?.button_text}
										show_legend={fdata(data, idList)?.body?.widgetdata?.custom_fields?.show_legend}
										widget_heading={fdata(data, idList)?.body?.widgetdata?.custom_fields
											?.widget_heading}
										primary_heading={fdata(data, idList)?.body?.widgetdata?.custom_fields
											?.primary_heading
											? fdata(data, idList)?.body?.widgetdata?.custom_fields?.primary_heading
											: ''}
										on:edi-barchartm:dropdownchange={dropdownchange}
									/>
								{/if}

								{#if fdata(data, idList)?.body?.type == 'piechart'}
									<edi-common-pie-chart
										id={fdata(data, idList)?.body?.id}
										user_id={fdata(data, idList)?.body?.userId}
										charttype={fdata(data, idList)?.body?.widgetdata?.type}
										items={fdata(data, idList)?.body?.widgetdata?.data}
										dropdownitems={fdata(data, idList)?.body?.widgetdata?.dropdowndata
											? fdata(data, idList)?.body?.widgetdata?.dropdowndata
											: []}
										tooltip_label={fdata(data, idList)?.body?.widgetdata?.custom_fields
											.tooltip_label}
										button_text={fdata(data, idList)?.body?.widgetdata?.custom_fields.button_text}
										button_link={fdata(data, idList)?.body?.widgetdata?.custom_fields.button_link}
										current_container={fdata(data, idList)?.body?.currentcontainer}
										on:edi-common-pie-chart:dropdownchange={dropdownchange}
									/>
								{/if}

								{#if fdata(data, idList)?.body?.type === 'grid'}
									{#if fdata(data, idList)?.body?.title === 'Truancy Alert'}
										<edi-truancy-alert
											headings={fdata(data, idList)?.body?.widgetdata?.headings}
											items={fdata(data, idList)?.body?.widgetdata?.data}
										/>
									{/if}

									{#if fdata(data, idList)?.body?.title === 'Demographic Subgroup Breakdown'}
										<edi-demographic-subgroup
											headings={fdata(data, idList)?.body?.widgetdata?.headings}
											items={fdata(data, idList)?.body?.widgetdata?.data}
										/>
									{/if}

									{#if fdata(data, idList)?.body?.title === 'YTD Misconduct'}
										<edi-ytd-misconduct
											id={fdata(data, idList)?.body?.id}
											title={fdata(data, idList)?.body?.title}
											user_id={fdata(data, idList)?.body?.userId}
											headings={fdata(data, idList)?.body?.widgetdata?.headings}
											items={fdata(data, idList)?.body?.widgetdata?.data}
											current_page={fdata(data, idList)?.body?.widgetdata?.pagination
												?.current_page}
											from={fdata(data, idList)?.body?.widgetdata?.pagination?.from}
											to={fdata(data, idList)?.body?.widgetdata?.pagination?.to}
											per_page={fdata(data, idList)?.body?.widgetdata?.pagination?.per_page}
											last_page={fdata(data, idList)?.body?.widgetdata?.pagination?.last_page}
											total={fdata(data, idList)?.body?.widgetdata?.pagination?.total}
											page_sizes={fdata(data, idList)?.body?.widgetdata?.pagination?.page_sizes}
											max_left={fdata(data, idList)?.body?.widgetdata?.pagination?.max_left}
											max_right={fdata(data, idList)?.body?.widgetdata?.pagination?.max_right}
											max_buttons={fdata(data, idList)?.body?.widgetdata?.pagination?.max_buttons}
											on:edi-ytd-misconduct:pagechange={pagechange}
										/>
									{/if}

									{#if fdata(data, idList)?.body?.title === 'YTD Suspensions'}
										<edi-ytd-suspensions
											id={fdata(data, idList)?.body?.id}
											user_id={fdata(data, idList)?.body?.userId}
											headings={fdata(data, idList)?.body?.widgetdata?.headings}
											title={fdata(data, idList)?.body?.title}
											items={fdata(data, idList)?.body?.widgetdata?.data}
											dropdownitems={fdata(data, idList)?.body?.widgetdata?.dropdowndata
												? fdata(data, idList)?.body?.widgetdata?.dropdowndata
												: []}
											on:edi-ytd-suspensions:dropdownchange={dropdownchange}
										/>
									{/if}

									{#if fdata(data, idList)?.body?.title === 'Top 20 Referred Students'}
										<edi-top-referred-students
											headings={fdata(data, idList)?.body?.widgetdata?.headings}
											items={fdata(data, idList)?.body?.widgetdata?.data}
										/>
									{/if}

									{#if fdata(data, idList)?.body?.title.includes('Summary Grid')}
										<edi-rti-summary
											items={fdata(data, idList)?.body?.widgetdata?.data}
											total={fdata(data, idList)?.body?.widgetdata?.total}
										/>
									{/if}

									{#if fdata(data, idList)?.body?.title.includes('Students Facilitating')}
										<edi-rti-students-facilitating
											items={fdata(data, idList)?.body?.widgetdata?.data}
											widget_details={fdata(data, idList)?.body}
											on:edi-rti-students-facilitating:mtssnavigation={mtssnavigation}
										/>
									{/if}

									{#if fdata(data, idList)?.body?.title.includes('Alerts')}
										<edi-rti-alerts
											headings={fdata(data, idList)?.body?.widgetdata?.headings}
											items={fdata(data, idList)?.body?.widgetdata?.data}
										/>
									{/if}

									{#if fdata(data, idList)?.body?.title.includes('Students Responsible for')}
										<edi-rti-students-responsible
											items={fdata(data, idList)?.body?.widgetdata?.data}
											widget_details={fdata(data, idList)?.body}
											on:edi-rti-students-responsible:mtssnavigation={mtssnavigation}
										/>
									{/if}

									{#if fdata(data, idList)?.body?.title === 'Early Warning Widget'}
										<edi-early-warning
											headings={fdata(data, idList)?.body?.widgetdata?.headings}
											items={fdata(data, idList)?.body?.widgetdata?.data}
											info={fdata(data, idList)?.body?.widgetdata?.custom_fields?.info}
										/>
									{/if}

									{#if fdata(data, idList)?.body?.title === "This Year's Roster"}
										<edi-this-years-roster
											id={fdata(data, idList)?.body?.id}
											title={fdata(data, idList)?.body?.title}
											user_id={fdata(data, idList)?.body?.userId}
											studentlist={fdata(data, idList)?.body?.widgetdata?.studentlist}
											grid_data={fdata(data, idList)?.body?.widgetdata?.data
												? fdata(data, idList)?.body?.widgetdata?.data
												: []}
											headings={fdata(data, idList)?.body?.widgetdata?.headings}
											show_detailed={fdata(data, idList)?.body?.widgetdata?.show_detailed}
											dropdowntype={fdata(data, idList)?.body?.dropdowntype}
											selected_list={fdata(data, idList)?.body?.widgetdata?.selected_list}
											show_current_groups={fdata(data, idList)?.body?.widgetdata
												?.show_current_groups}
											current_groups={fdata(data, idList)?.body?.widgetdata?.current_groups
												? fdata(data, idList)?.body?.widgetdata?.current_groups
												: []}
											on:edi-this-years-roster:dropdownchange={dropdownchange}
										/>
									{/if}

									{#if fdata(data, idList)?.body?.title === 'Student Groups'}
										<edi-student-groups
											id={fdata(data, idList)?.body?.id}
											title={fdata(data, idList)?.body?.title}
											user_id={fdata(data, idList)?.body?.userId}
											headings={fdata(data, idList)?.body?.widgetdata?.headings}
											items={fdata(data, idList)?.body?.widgetdata?.data
												? fdata(data, idList)?.body?.widgetdata?.data
												: []}
											studentlist={fdata(data, idList)?.body?.widgetdata?.studentlist}
											selected_list={fdata(data, idList)?.body?.widgetdata?.selected_list}
											on:edi-student-groups:dropdownchange={dropdownchange}
										/>
									{/if}

									{#if fdata(data, idList)?.body?.title === 'Unread IEP Documents'}
										<edi-unread-iep-documents
											headings={fdata(data, idList)?.body?.widgetdata?.headings}
											items={fdata(data, idList)?.body?.widgetdata?.data
												? fdata(data, idList)?.body?.widgetdata?.data
												: []}
											button_link={fdata(data, idList)?.body?.widgetdata?.button_link}
											on:edi-unread-iep-documents:pdfdownload={pdfdownload}
										/>
									{/if}

									{#if fdata(data, idList)?.body?.title === 'Top Ten At Risk Students'}
										<edi-risk-grid
											headings={fdata(data, idList)?.body?.widgetdata?.headings}
											items={fdata(data, idList)?.body?.widgetdata?.data
												? fdata(data, idList)?.body?.widgetdata?.data
												: []}
											button_link={fdata(data, idList)?.body?.widgetdata?.button_link}
											on:edi-risk-grid:calculateriskscore={calculateriskscore}
										/>
									{/if}

									{#if fdata(data, idList)?.body?.title == "Today's Lessons"}
										<edi-todays-lesson
											id={fdata(data, idList)?.body?.id}
											user_id={fdata(data, idList)?.body?.userId}
											title={fdata(data, idList)?.body?.title}
											griddata={fdata(data, idList)?.body?.widgetdata?.data?.gridData}
											timeslots={fdata(data, idList)?.body?.widgetdata?.timeslots
												? fdata(data, idList)?.body?.widgetdata?.timeslots
												: []}
											showtimeline={fdata(data, idList)?.body?.widgetdata?.showtimeline}
											scheduler1={fdata(data, idList)?.body?.widgetdata?.data?.scheduler1
												? fdata(data, idList)?.body?.widgetdata?.data?.scheduler1
												: []}
											scheduler2={fdata(data, idList)?.body?.widgetdata?.data?.scheduler2
												? fdata(data, idList)?.body?.widgetdata?.data?.scheduler2
												: []}
											current_container={fdata(data, idList)?.body?.currentcontainer}
											on:edi-todays-lesson:dropdownchange={dropdownchange}
											on:edi-todays-lesson:datechange={datechange}
											on:edi-todays-lesson:viewchange={viewchange}
										/>
									{/if}

									{#if fdata(data, idList)?.body?.title == 'Lesson Plan Teacher List'}
										<edi-lesson-plan-teacher-list
											items={fdata(data, idList)?.body?.widgetdata?.data
												? fdata(data, idList)?.body?.widgetdata?.data
												: []}
										/>
									{/if}

									{#if fdata(data, idList)?.body?.title == 'Curriculum Status'}
										<edi-curriculum-status
											id={fdata(data, idList)?.body?.id}
											user_id={fdata(data, idList)?.body?.userId}
											title={fdata(data, idList)?.body?.title}
											items={fdata(data, idList)?.body?.widgetdata?.data
												? fdata(data, idList)?.body?.widgetdata?.data
												: []}
											headings={fdata(data, idList)?.body?.widgetdata?.headings}
											dropdownitems={fdata(data, idList)?.body?.widgetdata?.dropdowndata
												? fdata(data, idList)?.body?.widgetdata?.dropdowndata
												: []}
											on:edi-curriculam-status:dropdownchange={dropdownchange}
										/>
									{/if}

									{#if fdata(data, idList)?.body?.title == 'School Performance Score'}
										<edi-school-performance-score
											items={fdata(data, idList)?.body?.widgetdata?.data
												? fdata(data, idList)?.body?.widgetdata?.data
												: []}
										/>
									{/if}

									{#if fdata(data, idList)?.body?.title === 'PSSA Anchor Breakdown'}
										<edi-pssa-anchor
											id={fdata(data, idList)?.body?.id}
											user_id={fdata(data, idList)?.body?.userId}
											title={fdata(data, idList)?.body?.title}
											items={fdata(data, idList)?.body?.widgetdata?.data
												? fdata(data, idList)?.body?.widgetdata?.data
												: []}
											headings={fdata(data, idList)?.body?.widgetdata?.headings}
											dropdownitems={fdata(data, idList)?.body?.widgetdata?.dropdowndata}
											button_text={fdata(data, idList)?.body?.widgetdata?.custom_fields
												.button_text}
											button_link={fdata(data, idList)?.body?.widgetdata?.custom_fields
												.button_link}
											on:edi-pssa-anchor:dropdownchange={dropdownchange}
										/>
									{/if}

									{#if fdata(data, idList)?.body?.title == 'Keystone Anchor Breakdown'}
										<edi-keystone-anchor
											id={fdata(data, idList)?.body?.id}
											user_id={fdata(data, idList)?.body?.userId}
											title={fdata(data, idList)?.body?.title}
											items={fdata(data, idList)?.body?.widgetdata?.data
												? fdata(data, idList)?.body?.widgetdata?.data
												: []}
											headings={fdata(data, idList)?.body?.widgetdata?.headings}
											dropdownitems={fdata(data, idList)?.body?.widgetdata?.dropdowndata
												? fdata(data, idList)?.body?.widgetdata?.dropdowndata
												: []}
											button_text={fdata(data, idList)?.body?.widgetdata?.custom_fields
												.button_text}
											button_link={fdata(data, idList)?.body?.widgetdata?.custom_fields
												.button_link}
											on:edi-keystone-anchor:dropdownchange={dropdownchange}
										/>
									{/if}
								{/if}

								{#if fdata(data, idList)?.body?.type === 'table'}
									{#if fdata(data, idList)?.body?.title === 'YTD Attendance'}
										<edi-ytd-attendance
											id={fdata(data, idList)?.body?.id}
											title={fdata(data, idList)?.body?.title}
											user_id={fdata(data, idList)?.body?.userId}
											headings={fdata(data, idList)?.body?.widgetdata?.headings}
											items={fdata(data, idList)?.body?.widgetdata?.data}
											dropdownitems={fdata(data, idList)?.body?.widgetdata?.dropdowndata}
											current_page={fdata(data, idList)?.body?.widgetdata?.pagination
												?.current_page}
											from={fdata(data, idList)?.body?.widgetdata?.pagination?.from}
											to={fdata(data, idList)?.body?.widgetdata?.pagination?.to}
											per_page={fdata(data, idList)?.body?.widgetdata?.pagination?.per_page}
											last_page={fdata(data, idList)?.body?.widgetdata?.pagination?.last_page}
											total={fdata(data, idList)?.body?.widgetdata?.pagination?.total}
											page_sizes={fdata(data, idList)?.body?.widgetdata?.pagination?.page_sizes}
											max_left={fdata(data, idList)?.body?.widgetdata?.pagination?.max_left}
											max_right={fdata(data, idList)?.body?.widgetdata?.pagination?.max_right}
											max_buttons={fdata(data, idList)?.body?.widgetdata?.pagination?.max_buttons}
											on:edi-ytd-attendance:pagechange={pagechange}
										/>
									{/if}
								{/if}

								{#if fdata(data, idList)?.body?.type == 'stackedbarchart'}
									<edi-data-depot-chart
										id={fdata(data, idList)?.body?.id}
										user_id={fdata(data, idList)?.body?.userId}
										charttype={fdata(data, idList)?.body?.widgetdata?.type}
										items={fdata(data, idList)?.body?.widgetdata?.data}
										dropdownitems={fdata(data, idList)?.body?.widgetdata?.dropdowndata
											? fdata(data, idList)?.body?.widgetdata?.dropdowndata
											: []}
										button_text={fdata(data, idList)?.body?.widgetdata?.custom_fields?.button_text}
										button_link={fdata(data, idList)?.body?.widgetdata?.custom_fields?.button_link}
										x_axis_title={fdata(data, idList)?.body?.widgetdata?.custom_fields
											?.x_axis_title}
										y_axis_title={fdata(data, idList)?.body?.widgetdata?.custom_fields
											?.y_axis_title}
										widget_heading={fdata(data, idList)?.body?.widgetdata?.custom_fields
											?.widget_heading}
										is_data_available={fdata(data, idList)?.body?.widgetdata?.custom_fields
											?.is_data_available}
										on:edi-data-depot-chart:dropdownchange={dropdownchange}
									/>
								{/if}

								{#if fdata(data, idList)?.body?.type === 'default'}
									<edi-welcome-message message={fdata(data, idList)?.body?.widgetdata?.message} />
								{/if}
							</div>
						{/if}
					</div>
				{/if}
				<!-- {/if} -->
			{/each}
		</section>
	{/each}
</div>

<style lang="scss">
	.container > * {
		display: inline-block;
		min-height: 500px;
	}

	.container #container4 {
		display: block;
		width: 95%;
		min-height: 500px;
	}

	section {
		vertical-align: top;
		margin: 10px 16px 10px 10px;

		width: 30%;
		border-color: #aabfd6;
		border-radius: 10px;
	}

	.widget {
		margin-bottom: 5px;
		border-color: #aabfd6;
		width: 100%;

		background: #ffffff;
		box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.05);
		border-radius: 10px;
	}

	.header {
		padding: 10px;
		font-family: 'Roboto';
		font-size: 12pt;
		font-weight: bold;

		border-bottom: 1px solid #eeeeee;
	}

	.content {
		padding: 5px;
		font-family: Tahoma, Verdana, Helvetica, sans-serif;
		font-size: 10pt;
	}

	.fullscreen {
		position: fixed;
		top: 0;
		left: 0;
		width: 99vw;
		height: 100vh;
		z-index: 2;

		margin-bottom: 5px;
		border-color: #aabfd6;

		background: #ffffff;
		box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.05);
		border-radius: 10px;
	}
</style>
