<svelte:options tag="edi-svg-book" />

<script lang="ts">
</script>

<svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		opacity="0.7"
		d="M9.5 0.917322V12.6673L3 11.4173V1.0017C3 0.382947 3.55625 -0.0889283 4.16563 0.0141967L9.5 0.917322ZM2.80312 12.5767L10 14.0173L17.1969 12.5767C17.6656 12.4829 18 12.0736 18 11.5954V0.817322L18.8031 0.657947C19.4219 0.532947 20 1.00482 20 1.63607V13.0986C20 13.5767 19.6625 13.9861 19.1969 14.0798L10 15.9173L0.803125 14.0767C0.3375 13.9861 0 13.5736 0 13.0986V1.63607C0 1.00482 0.578125 0.532947 1.19687 0.657947L2 0.817322V11.5986C2 12.0767 2.3375 12.4861 2.80312 12.5798V12.5767ZM10.5 12.6673V0.917322L15.8344 0.0141967C16.4438 -0.0889283 17 0.382947 17 1.0017V11.4173L10.5 12.6673Z"
		fill="#B5BDC3"
	/>
</svg>
