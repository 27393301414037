<svelte:options tag="edi-app-bar-divider" />

<div />

<style lang="scss">
	div {
		width: 2px;
		height: 30px;
		background: rgba(255, 255, 255, 0.2);
	}
</style>
