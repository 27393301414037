<svelte:options tag="edi-sdd-submenu" />

<script lang="ts">
	import { onMount } from 'svelte'
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../../common/dispatch'

	import { handleToggleFavorite, sortAssessments } from '../utils'

	/* --- Props Starts --- */

	export let folder_id: any
	export let subfolders: any = []
	export let assessments: any = []

	// For creating a dropdown of all folder names
	export let folders_names: any = []
	export let selected_folder: any

	export let is_all_assessments = false

	export let search_key = ''

	export let sort_option = ''

	/* --- Props Ends --- */

	/* --- Variables Starts --- */

	const component = get_current_component()

	let subfolder_id: any

	let isSubfolderSelected = false

	let sub_folders_names = []
	let selected_subfolder = ''

	let subfoldersTem = []
	let assessmentsTemp = []

	/* --- Variables Ends --- */

	/* --- Methods Starts --- */

	onMount(() => {
		subfoldersTem = subfolders
		assessmentsTemp = assessments
	})

	const handlesubfolderSelect = (subfolderDetails: any) => {
		isSubfolderSelected = true
		subfolder_id = subfolderDetails?.folderId
		assessments = subfolderDetails?.assessments?.selectedAssessments ?? []

		sub_folders_names = subfolders.map((folder) => folder.folderName)
		selected_subfolder = subfolderDetails?.folderName
	}

	document.addEventListener('favorite_updated', (e: any) => {
		if (is_all_assessments) {
			assessments = assessments.map((item) => {
				if (item.assessmentId === e?.detail?.assessmentId) {
					item.isFavorite = !item.isFavorite
					return item
				}
				return item
			})
		} else {
			if (!isSubfolderSelected) {
				// assessments = e?.detail?.updatedArray?.assessments?.selectedAssessments

				// const index = assessments.findIndex((assessment) => assessment.assessmentId === e?.detail?.assessmentId)
				// if (index !== -1) {
				// 	assessments[index].isFavorite = e?.detail?.isFavorite
				// }

				assessments = assessments.map((item) => {
					if (item.assessmentId === e?.detail?.assessmentId) {
						item.isFavorite = !e?.detail?.isFavorite
						return item
					}
					return item
				})
			} else {
				// const subfolderToUpdate = e?.detail?.updatedArray?.subFolders?.find(
				// 	(subfolder) => subfolder.folderId === subfolder_id
				// )
				// assessments = subfolderToUpdate?.assessments?.selectedAssessments

				assessments = assessments.map((item) => {
					if (item.assessmentId === e?.detail?.assessmentId) {
						item.isFavorite = !e?.detail?.isFavorite
						return item
					}
					return item
				})
			}
		}
	})

	const handleBack = () => {
		if (selected_subfolder !== '') {
			selected_subfolder = ''
			sub_folders_names = []
			isSubfolderSelected = false
			subfolders = subfoldersTem
			assessments = assessmentsTemp
			dispatch(component, 'edi-sdd-submenu:handle_back', { redirectToMainMenu: false })
		} else {
			selected_folder = ''
			dispatch(component, 'edi-sdd-submenu:handle_back', { redirectToMainMenu: true })
		}
	}

	// Method to  handle folder selection(dropdown/bread crumbs)
	const handleFolderSelect = (selected_folder: any, selected_subfolder: any) => () => {
		dispatch(component, 'edi-sdd-submenu:handlefolderselect', { selected_folder, selected_subfolder })
	}

	document.addEventListener('update_selected_folder', (e: any) => {
		if (selected_folder !== 'All') {
			is_all_assessments = false
		} else {
			is_all_assessments = true
		}

		subfolders = e?.detail?.subFolders ?? []
		assessments = e?.detail?.assessments ?? []

		if (subfolders?.length > 0) {
			subfoldersTem = subfolders
		}

		if (assessments?.length > 0) {
			assessmentsTemp = assessments
		}

		folder_id = e?.detail?.folder_id ?? ''
		subfolder_id = e?.detail?.subfolder_id ?? ''
	})

	// Method to handle click event on assessment name
	const handleClick = (details: any) => () => {
		window.location = details?.link
	}

	$: if (search_key) {
		filteredAssessments()
	}

	// Method to handle filtering and searching the 'assessment' list according to the 'search_key'
	$: filteredAssessments = () => {
		return assessments?.filter((item) => {
			return item?.assessmentName?.toLowerCase()?.includes(search_key?.toLowerCase())
		})
	}

	$: if (sort_option) {
		assessments = sortAssessments(assessments, sort_option)
	}

	/* --- Methods Ends --- */
</script>

<div class="folders">
	<div class="folder-header">
		<div class="folder-title">
			<button on:click={() => handleBack()}><edi-svg-back /></button>
			<h3>Folders</h3>
			{#if selected_folder !== ''}
				<span class={selected_subfolder === '' ? 'disabled' : 'active'} on:click={() => handleBack()}
					><edi-svg-right /> {selected_folder}</span
				>
			{/if}
			{#if selected_subfolder !== ''}
				<span class="disabled"><edi-svg-right /> {selected_subfolder}</span>
			{/if}
		</div>

		<div>
			{#if folders_names?.length > 0 && sub_folders_names?.length === 0}
				<select bind:value={selected_folder} on:change={handleFolderSelect(selected_folder, '')}>
					<option value="All">
						All{#if selected_folder === 'All'}&#x2713;{/if}
					</option>
					{#each folders_names as name}
						<option value={name}>
							{name}
							{#if selected_folder === name}&#x2713;{/if}
						</option>
					{/each}
				</select>
			{/if}

			{#if sub_folders_names?.length > 0}
				<select
					bind:value={selected_subfolder}
					on:change={handleFolderSelect(selected_folder, selected_subfolder)}
				>
					{#each sub_folders_names as name}
						<option value={name}>
							{name}
							{#if selected_subfolder === name}&#x2713;{/if}
						</option>
					{/each}
				</select>
			{/if}
		</div>
	</div>
	<div class="folders-in">
		{#if subfolders?.length > 0 && !isSubfolderSelected}
			{#each subfolders as folder}
				<div on:click={() => handlesubfolderSelect(folder)}>
					<span><edi-icon-picker icon_name={folder?.folderIcon} /></span>
					<strong>
						<h3>
							{folder?.folderName}
						</h3>
					</strong>
					{#if folder?.folderDescription}
						{folder?.folderDescription}
					{/if}
				</div>
			{/each}
		{/if}
	</div>
	<div class="folders-in folders-in-2">
		{#if filteredAssessments()?.length > 0}
			{#if !is_all_assessments}
				{#each filteredAssessments() as item}
					<div on:click={handleClick(item)}>
						{item?.assessmentName}
						<span>
							<edi-sdd-favorite-star
								on:click|stopPropagation={handleToggleFavorite(
									component,
									item,
									folder_id,
									subfolder_id
								)}
								class="star"
								variant={item?.isFavorite ? 'filled' : 'empty'}
							/>
						</span>
					</div>
				{/each}
			{:else}
				{#each filteredAssessments() as item}
					<div on:click={handleClick(item)}>
						{item?.assessmentName}
						<span>
							<edi-sdd-favorite-star
								on:click|stopPropagation={handleToggleFavorite(
									component,
									item,
									item?.folderId,
									item?.subfolderId
								)}
								class="star"
								variant={item?.isFavorite ? 'filled' : 'empty'}
							/>
						</span>
					</div>
				{/each}
			{/if}
		{/if}
	</div>
</div>

<style lang="scss">
	* {
		box-sizing: border-box;
	}
	.folders {
		font-family: 'Poppins', sans-serif;
		font-size: 15px;
		margin: 0;

		padding-left: 30px;
		width: 100%;
	}

	.folder-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 15px;
		margin-bottom: 15px;
	}
	.folder-header .folder-title {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 10px;
	}
	.folder-header .folder-title button {
		width: 16px;
		background: none;
		outline: none;
		border: none;
		padding: 0;
		display: flex;
		flex-shrink: 0;
		cursor: pointer;
	}
	.folder-header .folder-title button img {
		width: 100%;
	}
	.folder-header h3 {
		font-weight: 600;
		font-size: 17px;
		color: #263b54;
		margin: 0;
	}
	.folder-header .folder-title span {
		font-size: 17px;
		font-weight: 400;
		position: relative;
		display: inline-block;
		padding: 0 0 0 15px;
		background: url(../images/chevron-right.svg) no-repeat;
		background-size: 6px auto;
		background-position: 0;
	}

	.active {
		cursor: pointer;
	}

	.disabled {
		pointer-events: none;
		color: #317fb4;
	}
	.folder-header .folder-title span.current {
		color: #317fb4;
	}
	.folder-header select {
		width: 160px;
		height: 45px;
		padding: 10px;
		font-size: 15px;
		background: rgba(255, 255, 255, 0.8);
		border-radius: 7px;
		color: #435765;
		border: none;
		outline: none;
	}

	.folders-in {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 15px;
	}
	.folders-in div {
		cursor: pointer;
		border: 1px solid #e2e9f3;
		border-radius: 10px;
		padding: 15px;
		font-size: 13px;
		line-height: 24px;
		color: #727c89;
		display: flex;
		text-align: center;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		-webkit-transition: 0.4s;
		-moz-transition: 0.4s;
		transition: 0.4s;
	}
	.folders-in div:hover {
		border-color: #317fb4;
	}
	.folders-in div h4 {
		display: block;
		color: #263b54;
		font-size: 15px;
		font-weight: 500;
		margin: 0 0 5px 0;
		-webkit-transition: 0.4s;
		-moz-transition: 0.4s;
		transition: 0.4s;
	}
	.folders-in div:hover h4 {
		color: #317fb4;
	}
	.folders-in div span {
		background: rgba(226, 233, 243, 0.6);
		border-radius: 5px;
		width: 40px;
		height: 40px;
		font-size: 20px;
		margin-bottom: 10px;
		color: #a2b7d4;
		padding: 12px;
		display: flex;
		justify-content: center;
		align-items: center;
		-webkit-transition: 0.4s;
		-moz-transition: 0.4s;
		transition: 0.4s;
	}
	.folders-in div span img {
		width: 100%;
	}
	.folders-in div:hover span {
		background: #317fb4;
	}
	.folders-in div:hover span img {
		filter: brightness(0) invert(1);
	}
	.folders-in-2 {
		border-top: 1px dashed #e2e9f3;
		padding-top: 15px;
		margin-top: 15px;
	}
	.folders-in-2 div {
		font-size: 15px;
		line-height: 18px;
		color: #435765;
		flex-direction: row;
		justify-content: flex-start;
		padding-right: 40px;
		border-radius: 7px;
		position: relative;
		text-align: left;
	}
	.folders-in-2 div:hover {
		color: #317fb4;
	}
	.folders-in-2 div span {
		position: absolute;
		background: none !important;
		top: 3px;
		right: 15px;
		width: 13px;
		padding: 0;
		opacity: 0;
		margin: 0;
	}
	.folders-in-2 div:hover span {
		opacity: 1;
	}
	.folders-in-2 div:hover span img {
		filter: none;
	}
	.folders-in-2 div span .filled {
		display: none;
	}
	.folders-in-2 div span:hover .filled {
		display: block;
	}
	.folders-in-2 div span:hover .outlined {
		display: none;
	}

	@media (max-width: 1200px) {
		.folders-in {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	@media (max-width: 992px) {
		.folders-in {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	@media (max-width: 768px) {
		.folders {
			padding: 30px 0 0 0;
		}
	}
	@media (max-width: 576px) {
		.folders-in {
			grid-template-columns: repeat(1, 1fr);
		}
	}
</style>
