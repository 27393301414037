<svelte:options tag="edi-app-bar-drawer" />

<script lang="ts">
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../common/dispatch'
	import { injectable } from '../../common/injectable'
	import type { ItemState } from './utils'
	import { APP_BAR_DRAWER_CLOSED, APP_BAR_STATE_ITEMS } from './constants'
	import { dispatchAppBarFavorite, dispatchAppBarNavigated } from './utils'

	export let open: boolean = false

	export let current_folders: any = []
	export let favorites: any = []

	export let sdd_config_params: any // For the button to navigate to the config page

	let component = get_current_component()
	let itemState: ItemState

	// handlers
	const handleClose = () => dispatch(component, APP_BAR_DRAWER_CLOSED)

	// reactive props
	$: selectedItem = itemState?.selectedItem

	/* Methods for new SDD menu */
	const toggleFavorite = (e: CustomEvent) => {
		dispatch(component, 'edi-app-bar:toggle_favorite', e?.detail)
	}

	const handleRemoveFavorited = (e: CustomEvent) => {
		dispatch(component, 'edi-app-bar:remove_favorited', e?.detail)
	}

	const handleFolderSelect = (e: CustomEvent) => {
		dispatch(component, 'edi-app-bar:handlefolderselect', e?.detail)
	}
</script>

{#if $selectedItem?.label !== 'Data Depot'}
	<div
		use:injectable={APP_BAR_STATE_ITEMS}
		on:injected={({ detail: { instance } }) => (itemState = instance)}
		class="navigation-drawer mdc-elevation--z2"
		class:navigation-drawer--show={open}
	>
		<div class="navigation-drawer__header">
			<span class="navigation-drawer__title">{$selectedItem?.label}</span>
			<div on:click={handleClose} class="navigation-drawer__close">
				<mwc-icon>close</mwc-icon>
			</div>
		</div>
		<div class={$selectedItem?.designClass}>
			{#each $selectedItem?.items || [] as group}
				<div class="mega-item">
					{#if group.label}
						{#if group.isEDoctrina}
							<div on:click={dispatchAppBarNavigated(component, group.items[0])}>
								{@html group?.label
									?.split(group?.deliminator?.symbol)
									.map((x, ind) => {
										const dclass = group?.deliminator?.position.find((el) => el === ind)
										return `<span class="${group?.designClass}${
											dclass !== undefined ? dclass : ''
										}">${x}</span>`
									})
									.join(group?.deliminator?.symbol)}
							</div>
						{:else}
							<div class={group.designClass}>{group.label}</div>
						{/if}
					{/if}

					<ul class="mega-item__subitems">
						{#each group.items as subitem, index}
							{#if group.isEDoctrina}
								{#if index === 0}
									<li class="mega-item__subitem">
										{subitem.label}

										<edi-app-bar-favorite-star
											on:click|stopPropagation={dispatchAppBarFavorite(component, subitem)}
											class="star"
											class:star--show={subitem.isFavorite}
											variant={subitem.isFavorite ? 'filled' : 'empty'}
										/>
									</li>
								{:else}
									<li
										class="mega-item__subitem"
										on:click={dispatchAppBarNavigated(component, subitem)}
									>
										{subitem.label}

										<edi-app-bar-favorite-star
											on:click|stopPropagation={dispatchAppBarFavorite(component, subitem)}
											class="star"
											class:star--show={subitem.isFavorite}
											variant={subitem.isFavorite ? 'filled' : 'empty'}
										/>
									</li>
								{/if}
							{:else}
								<li class="mega-item__subitem" on:click={dispatchAppBarNavigated(component, subitem)}>
									{subitem.label}

									<edi-app-bar-favorite-star
										on:click|stopPropagation={dispatchAppBarFavorite(component, subitem)}
										class="star"
										class:star--show={subitem.isFavorite}
										variant={subitem.isFavorite ? 'filled' : 'empty'}
									/>
								</li>
							{/if}
						{/each}
					</ul>
				</div>
			{/each}
		</div>
	</div>
{/if}

<!-- Custom Menu Component -->
{#if $selectedItem?.label === 'Data Depot'}
	<edi-sdd-menu
		{current_folders}
		{favorites}
		{sdd_config_params}
		on:edi-sdd-menu:closed={handleClose}
		on:edi-sdd-menu:toggle_favorite={toggleFavorite}
		on:edi-sdd-menu:remove_favorited={handleRemoveFavorited}
		on:edi-sdd-menu:handlefolderselect={handleFolderSelect}
	/>
{/if}

<style lang="scss">
	@import '@material/elevation/mdc-elevation';
	@import '../../styles/variables';
	@import '../../styles/mixins';

	@keyframes stretchRight {
		0% {
			transform: scaleX(0);
		}
		100% {
			transform: scaleX(0.25);
		}
	}

	.navigation-drawer {
		font-family: Roboto;
		background-color: white;
		background: linear-gradient(180deg, #e9eef5 0%, white 45.48%);
		max-height: calc(100vh - 100%);
		overflow: auto;
		width: 100%;
		position: absolute;
		left: 0;
		top: 100%;
		padding: 1rem 3rem;
		box-sizing: border-box;
		border-top: 1px solid rgba(17, 42, 59, 0.25);
		visibility: hidden;
		z-index: 450;

		&__close {
			display: inline-flex;
			cursor: pointer;
			border-radius: 50%;
			padding: 0.25rem;

			&:hover {
				background: var(--edi-gray-light);
			}
		}

		&__header {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			padding: 1rem 0;
			border-bottom: 1px solid rgba(17, 42, 59, 0.25);
			margin-bottom: 1rem;
		}

		&__title {
			color: var(--edi-dark);
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			letter-spacing: -0.03em;
		}

		&__body {
			display: inline-block;
			columns: 3;
			column-gap: 3rem;

			@include md {
				columns: 4;
			}

			@include lg {
				columns: 5;
			}
		}

		&--show {
			visibility: visible;
		}

		.custom_body {
			columns: 3;
			@include lg {
				columns: 3;
			}

			& .mega-item {
				&:nth-of-type(1) {
					padding-left: 0;
					min-height: 260px;
					top: -3px;
					position: relative;
					& .mega-item__subitem {
						&:nth-of-type(1) {
							cursor: auto;
							font-style: italic;
							max-width: 325px;
							margin-top: 5px;
							&:before,
							& .star {
								display: none;
							}
						}
					}

					& div {
						cursor: pointer;
					}
				}
			}
		}
	}

	.mega-item {
		break-inside: avoid;
		margin-bottom: 2rem;
		padding: 0 1rem;

		&__title {
			color: #112a3b;
			font-family: Roboto;
			font-style: normal;
			font-weight: bold;
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.08em;
			text-transform: uppercase;
			margin-bottom: 1rem;
		}

		& .custom_menu {
			text-transform: none;
			margin-bottom: 7px;
		}

		// Dynamic Class
		& .custom_menu1 {
			text-transform: none;
			margin-bottom: 7px;
			color: var(--edi-blue);
		}

		&__subitems {
			padding: 0;
			margin: 0;
			list-style: none;
		}

		&__subitem {
			@mixin showStar {
				opacity: 1;
				visibility: visible;
			}

			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: #435765;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: -0.01em;
			margin-bottom: 0.5rem;

			.star {
				@include showStar;
				display: block;
				margin-left: 2rem;

				@include md {
					opacity: 0;
					visibility: hidden;
				}

				&--show {
					@include showStar;
				}
			}

			&::before {
				content: '';
				position: absolute;
				width: 100%;
				height: 4px;
				left: 0;
				top: calc(100% - 4px);
				box-shadow: inset -1px -3px 0px var(--edi-black);
				transform: scaleX(0);
			}

			&:hover {
				cursor: pointer;

				&::before {
					transform-origin: left;
					animation-name: stretchRight;
					animation-delay: 150ms;
					animation-duration: 150ms;
					animation-fill-mode: forwards;
					animation-timing-function: ease-out;
				}

				.star {
					@include showStar;
				}
			}
		}
	}

	::-webkit-scrollbar {
		width: 7px;
		height: 7px;
	}

	::-webkit-scrollbar-thumb {
		background: var(--edi-gray-light);
		border-radius: 7px;
		position: relative;

		&:hover {
			background: var(--edi-gray-medium);
		}
	}
</style>
