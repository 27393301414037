<svelte:options tag="edi-folder-config-assessment" />

<script lang="ts">
	import { onMount } from 'svelte'
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../common/dispatch'

	import Sortable from 'sortablejs'
	import { sortFolders } from './utils'

	/* --- Props Starts --- */

	export let all_assessments: any = []
	export let selected_assessments: any = []

	export let icons: any = []
	export let parent_folder: any
	export let parent_folders: any = []

	export let selected_icon: any
	export let folder_name: any = ''
	export let folder_description: any = ''
	export let selected_parent: any

	export let sub_folders: any = []

	export let new_folder_id: any // To recieve the newly created parent folder id
	export let new_sub_folder_id: any // To recieve the newly created sub folder id
	export let new_folder_name = ''

	// For archive button
	export let show_archive_button = false
	export let is_archived = false

	export let is_edit_parent_folder_clicked = false // To idenify if the parent folder edit button is clicked

	/* To idenify if the subfolder edit button is clicked or the subfolder created directly from the SDD Config page */
	export let is_edit_subfolder_clicked = false

	export let sub_folder_names: any = [] // List of all subfolder names

	/* --- Props Ends --- */

	/* --- Variables Starts --- */

	const component = get_current_component()

	let showSubfolderCreateForm = false
	let showEditFolderDetailsForm = false

	let showSubFoldersList = true

	// For parent folder details backup
	let allParentAssessments = []
	let selectedParentAssessments = []
	let parentSelectedIcon = ''
	let selectedParent = ''
	let parentFolderName = ''
	let parentFolderDescription = ''
	let showArchiveButton = false
	let isArchived = false

	let container1, container2

	let searchKeyAllAssessments = ''
	let searchKeySelectedAssessments = ''

	let allSubfolderAssessments = []
	let selectedSubfolderAssessments = []

	$: all_assessments_temp = all_assessments
	$: selected_assessments_temp = selected_assessments

	let disableSave = true // To disable Save button for assessments

	let formType = ''

	let availableSortable
	let selectedSortable

	/* --- Variables Ends --- */

	/* --- Methods Starts --- */

	onMount(() => {
		setTimeout(() => {
			inItContainers()
		}, 1000)

		// showSubfolderCreateForm = selected_parent === 'Default' ? true : false

		all_assessments_temp = sortAssessmentList(all_assessments)
		selected_assessments_temp = sortAssessmentList(selected_assessments)

		// Taking a copy of parent folder details for backup
		allParentAssessments = all_assessments_temp
		selectedParentAssessments = selected_assessments_temp
		parentSelectedIcon = selected_icon
		selectedParent = selected_parent
		parentFolderName = folder_name
		parentFolderDescription = folder_description
		showArchiveButton = show_archive_button
		isArchived = is_archived
	})

	// Method to intialize the drgabble containers
	const inItContainers = () => {
		availableSortable = Sortable.create(container1, {
			group: {
				name: 'container1',
				put: true,
			},
			animation: 200,
			// sort: true,

			// Element dragging started
			onStart: function () {
				selectedSortable.options.disabled = false // Enabling drag and drop for 'selectedSortable' container
			},

			// Element is dropped into the list from another list
			onAdd: function (/**Event*/ evt) {
				let draggedElementID = evt.item.id // dragged HTMLElement

				let targetListID = evt.to.id // target list

				dispatch(component, 'edi-folder-config-assessment:assessment_drag_and_drop', {
					draggedElementID,
					from: 'container2',
					to: targetListID,
					all_assessments,
					selected_assessments,
				})
			},
		})

		selectedSortable = Sortable.create(container2, {
			group: {
				name: 'container2',
				put: true,
			},
			animation: 200,
			// sort: true,

			// Element is dropped into the list from another list
			onAdd: function (/**Event*/ evt) {
				let draggedElementID = evt.item.id // dragged HTMLElement

				let targetListID = evt.to.id // target list

				// addAssessment(draggedElementID)

				dispatch(component, 'edi-folder-config-assessment:assessment_drag_and_drop', {
					draggedElementID,
					from: 'container1',
					to: targetListID,
					all_assessments,
					selected_assessments,
				})
				// handleSort(evt)
			},
		})

		// Disabling the drag and drop for the 'container2' to avoid the dragging of contents inside the <p> tag.
		if (selected_assessments.length === 0) {
			selectedSortable.options.disabled = true
		}
	}

	/* Dynamically setting  showSubFoldersList into false when 'is_edit_subfolder_clicked = true' */
	$: if (is_edit_subfolder_clicked) {
		showSubFoldersList = false
	}

	const handleBack = () => {
		showSubfolderCreateForm = false
		showEditFolderDetailsForm = false

		searchKeyAllAssessments = ''
		searchKeySelectedAssessments = ''

		if (showSubFoldersList) {
			dispatch(component, 'edi-folder-config-assessment:go_back_to_config_page', {})

			// Updating config page title at server side
			updatePageTitle('', '', null)
		} else {
			showSubFoldersList = true
			is_edit_subfolder_clicked = false

			all_assessments = allParentAssessments
			selected_assessments = selectedParentAssessments
			selected_icon = parentSelectedIcon
			selected_parent = selectedParent
			folder_name = parentFolderName
			folder_description = parentFolderDescription
			show_archive_button = showArchiveButton
			is_archived = isArchived
			new_sub_folder_id = null

			formType = ''

			// Updating config page title at server side
			updatePageTitle(selected_parent !== 'Default' ? selected_parent : folder_name, '', new_folder_id)
		}
	}

	const handleSave = () => {
		disableSave = true
		let folder_id = null
		if (showSubFoldersList) {
			folder_id = new_folder_id
		} else {
			folder_id = new_sub_folder_id
		}

		dispatch(component, 'edi-folder-config-assessment:save_assessments', {
			folder_id,
			selected_assessments,
		})
	}

	const handleCreateSubfolderClick = () => {
		// showSubFoldersList = false
		showSubfolderCreateForm = !showSubfolderCreateForm
		showEditFolderDetailsForm = false

		selected_parent = folder_name

		searchKeyAllAssessments = ''
		searchKeySelectedAssessments = ''

		selected_icon = 'Folder'

		/* -------------------------------------------------------*/

		if (!is_edit_parent_folder_clicked) {
			selected_parent = selectedParent // Binding selectedParent(backup) value as parent for subfolder
			// selected_icon = 'Folder' // Setting default icon for subfolder
		}
	}

	const handleEditFolderDetailsClick = () => {
		showEditFolderDetailsForm = !showEditFolderDetailsForm
		showSubfolderCreateForm = false

		disableSave = true

		if (!showSubfolderCreateForm && !is_edit_subfolder_clicked) {
			selected_parent = 'Default'
		}

		/* For editing parent folder details. Binding the default value as parent folder dropdown. 
		   Setting selectedParent(backup) value as current folder name */
		if (!is_edit_subfolder_clicked && !is_edit_parent_folder_clicked) {
			selected_parent = 'Default'
			folder_name = selectedParent
		}

		if (!is_edit_subfolder_clicked) {
			new_sub_folder_id = null
		}
	}

	const handleCreate = (e: CustomEvent) => {
		showSubfolderCreateForm = false
		showEditFolderDetailsForm = false

		selectedParent = e?.detail?.selected_parent

		is_edit_subfolder_clicked = e?.detail?.selected_parent !== 'Default' ? true : false

		formType = e?.detail?.form_type

		if (e?.detail?.form_type === 'folder_create') {
			showSubFoldersList = true
		}

		// For form_type is edit_folder_details
		/* * Setting 'folder_name' value from event detail as 'parentFolder' paramter if selected_parent value is 'Default'.
			 If not setting 'selected_parent' value form event detail as 'parentFolder' paramter.
		   * Setting 'folder_name' value from event detail as 'subFolder' paramter if selected_parent value is not 'Default'.
			 Otherwise 'subFolder' paramter is empty.
		*/
		updatePageTitle(
			e?.detail?.selected_parent !== 'Default' ? e?.detail?.selected_parent : e?.detail?.folder_name,
			e?.detail?.selected_parent !== 'Default' ? e?.detail?.folder_name : '',
			e?.detail?.folder_id
		)

		if (e?.detail?.form_type === 'sub_folder_create') {
			disableSave = true

			/* Resetting the selected assessments list to default.*/
			selected_assessments?.forEach((element) => {
				all_assessments = sortAssessmentList([...all_assessments, element])
			})
			selected_assessments = []

			showSubFoldersList = false // Hiding subfolders list
			is_edit_subfolder_clicked = true
			show_archive_button = false

			updatePageTitle(e?.detail?.selected_parent, e?.detail?.folder_name, e?.detail?.folder_id)
		}

		dispatch(component, 'edi-folder-config-assessment:handle_create', e?.detail)
	}

	const handleCancel = (e: CustomEvent) => {
		showSubfolderCreateForm = false
		showEditFolderDetailsForm = false

		searchKeyAllAssessments = ''
		searchKeySelectedAssessments = ''
	}

	const addAssessment = (assessmentName: any) => {
		const index = all_assessments.findIndex((item) => item?.assessmentName === assessmentName)

		if (index !== -1) {
			let addedAssessment = all_assessments[index]
			all_assessments = all_assessments.filter((item) => item?.assessmentName !== assessmentName)
			selected_assessments = sortAssessmentList([...selected_assessments, addedAssessment])

			// Destroying the draggable containers and reinitializing them
			if (availableSortable && selectedSortable) {
				setTimeout(() => {
					availableSortable.destroy()
					selectedSortable.destroy()
					inItContainers()
				}, 2)
			}

			// Creating a copy of all available and selected assessments for the parent folders
			updateAssessmentList(all_assessments, selected_assessments, showSubFoldersList)

			disableSave = false
		}
	}

	const removeAssessment = (assessmentName: any) => {
		const index = selected_assessments.findIndex((item) => item?.assessmentName === assessmentName)

		if (index !== -1) {
			let removedAssessment = selected_assessments[index]
			selected_assessments = selected_assessments.filter((item) => item?.assessmentName !== assessmentName)
			all_assessments = sortAssessmentList([...all_assessments, removedAssessment])

			// Destroying the draggable containers and reinitializing them
			if (availableSortable && selectedSortable) {
				setTimeout(() => {
					availableSortable.destroy()
					selectedSortable.destroy()
					inItContainers()
				}, 2)
			}

			// Creating a copy of all available and selected assessments for the parent folders
			updateAssessmentList(all_assessments, selected_assessments, showSubFoldersList)

			disableSave = false
		}
	}

	const sortAssessmentList = (assessments) => {
		assessments.sort((a, b) => a.assessmentName.localeCompare(b.assessmentName))

		return assessments
	}

	const editSubFolder = (folderDetails: any) => {
		showSubFoldersList = false
		show_archive_button = false
		is_edit_subfolder_clicked = true

		showSubfolderCreateForm = false
		showEditFolderDetailsForm = false

		searchKeyAllAssessments = ''
		searchKeySelectedAssessments = ''

		disableSave = true

		sub_folders = []

		// Checking if selectedAssessments array has value in sub folder details
		if (folderDetails?.assessments?.selectedAssessments?.length > 0) {
			// Filtering out the availableAssessments list according to the items in selectedAssessments list
			all_assessments = folderDetails?.assessments?.availableAssessments.filter((assessment) => {
				return !folderDetails?.assessments?.selectedAssessments.some(
					(selectedAssessment) =>
						assessment.assessmentId === selectedAssessment.assessmentId &&
						assessment.assessmentName === selectedAssessment.assessmentName
				)
			})
		} else {
			all_assessments = folderDetails?.assessments?.availableAssessments
				? folderDetails?.assessments?.availableAssessments
				: []
		}

		selected_assessments = folderDetails?.assessments?.selectedAssessments
			? folderDetails?.assessments?.selectedAssessments
			: []

		// Creating a copy of all available and selected assessments for the sub folders
		updateAssessmentList(all_assessments, selected_assessments, showSubFoldersList)

		selected_parent = folder_name // Assigning current folder name as parent folder name
		selected_icon = folderDetails?.folderIcon ?? 'Folder'
		folder_name = folderDetails?.folderName ?? '' // Subfolder name
		folder_description = folderDetails?.folderDescription ?? ''

		new_sub_folder_id = folderDetails?.folderId

		// Updating config page title at server side
		updatePageTitle(selected_parent, folder_name, null)
	}

	const deleteSubFolder = (folderDetails: any) => {
		if (confirm('Are you sure you want to delete ' + folderDetails?.folderName + ' ?')) {
			sub_folders = sub_folders?.filter((item) => item?.folderId !== folderDetails?.folderId)
			dispatch(component, 'edi-folder-config-assessment:delete_folder', { folder_id: folderDetails?.folderId })
		}
	}

	/* Method to backing up the assessment list according to isSubfolderSelected(showSubFoldersList) flag
	   If showSubFoldersList is true it means the assessments listed are for parent folder. Otherwise it is for subfolders. */
	const updateAssessmentList = (allAssessments, selectedAssessments, isParent: boolean) => {
		if (isParent) {
			allParentAssessments = allAssessments
			selectedParentAssessments = selectedAssessments
		} else {
			allSubfolderAssessments = allAssessments
			selectedSubfolderAssessments = selectedAssessments
		}
	}

	// Listening to the event from server side after assessment is dragged from one list(container) to other.
	document.addEventListener('update_assessments_list', (e: any) => {
		all_assessments = []
		selected_assessments = []

		setTimeout(() => {
			all_assessments = sortAssessmentList([...e?.detail?.allAssessments])
			selected_assessments = sortAssessmentList([...e?.detail?.selectedAssessments])
		}, 1)

		// Destroying the draggable containers and reinitializing them
		if (availableSortable && selectedSortable) {
			setTimeout(() => {
				availableSortable.destroy()
				selectedSortable.destroy()
				inItContainers()
			}, 2)
		}

		updateAssessmentList(all_assessments, selected_assessments, showSubFoldersList)
	})

	// Method to handle filtering for All Assessments list
	$: filteredAllAssessments = () => {
		return all_assessments.filter((assessment) => {
			return assessment?.assessmentName?.toLowerCase()?.includes(searchKeyAllAssessments?.toLowerCase())
		})
	}

	// Method to handle filtering for Selected Assessments list
	$: filteredSelectedAssessments = () => {
		return selected_assessments.filter((assessment) => {
			return assessment?.assessmentName?.toLowerCase()?.includes(searchKeySelectedAssessments?.toLowerCase())
		})
	}

	// Dispatching an event to the server side to handle the title changes
	const updatePageTitle = (parentFolder: string, subFolder: string, parentFolderId: any) => {
		dispatch(component, 'edi-folder-config-assessment:update_page_title', {
			parentFolder,
			subFolder,
			parent_folder_id: parentFolderId, // For keeping the folder and subfolder details updated.
		})
	}

	/*  */
	document.addEventListener('folder_details_updated', (e: any) => {
		/* Already resetting the assessment list inside 'handleCreate' method when a subfolder is created.
		   Setting disableSave to true inside the same method. */

		if (formType !== 'sub_folder_create' && !is_edit_subfolder_clicked) {
			selected_parent = e?.detail?.selectedParent ?? 'Default'
			folder_name = e?.detail?.folderName ?? ''
			folder_description = e?.detail?.folderDescription ?? ''
			selected_icon = e?.detail?.selectedIcon ?? 'Folder'

			sub_folders = e?.detail?.subFolders ? sortFolders(e?.detail?.subFolders, 'Name A-Z') : []

			selected_assessments = e?.detail?.selectedAssessments ?? []

			// Checking if selectedAssessments array has value in folder details
			if (e?.detail?.selectedAssessments?.length > 0) {
				// Filtering out the availableAssessments list according to the items in selectedAssessments list
				all_assessments = e?.detail?.availableAssessments.filter((assessment) => {
					return !e?.detail?.selectedAssessments.some(
						(selectedAssessment) =>
							assessment.assessmentId === selectedAssessment.assessmentId &&
							assessment.assessmentName === selectedAssessment.assessmentName
					)
				})
			} else {
				all_assessments = e?.detail?.availableAssessments ?? []
			}
		}
	})

	/* --- Methods Ends --- */
</script>

<div>
	<div class="section1">
		<button on:click={() => handleEditFolderDetailsClick()}>
			<edi-svg-edit />{!is_edit_subfolder_clicked ? ' Edit Folder Details' : ' Edit Subfolder Details'}
		</button>
		{#if !is_edit_subfolder_clicked}
			<button on:click={() => handleCreateSubfolderClick()}>
				<edi-svg-folder-plus />Create Subfolder
			</button>
		{:else}
			<button on:click={() => handleBack()}>
				<edi-svg-back /> Back To Parent Folder
			</button>
		{/if}
	</div>

	{#if showSubfolderCreateForm}
		<div class="sub-section">
			<edi-folder-details-form
				{parent_folders}
				{selected_icon}
				{selected_parent}
				{new_folder_id}
				{new_sub_folder_id}
				{new_folder_name}
				form_type="sub_folder_create"
				{sub_folder_names}
				disable_dropdown={true}
				{is_edit_parent_folder_clicked}
				{is_edit_subfolder_clicked}
				on:edi-folder-details-form:handle_create={handleCreate}
				on:edi-folder-details-form:handle_cancel={handleCancel}
			/>
		</div>
	{/if}

	{#if showEditFolderDetailsForm}
		<div class="sub-section">
			<edi-folder-details-form
				{parent_folders}
				{selected_icon}
				{folder_name}
				{folder_description}
				{selected_parent}
				{show_archive_button}
				checked={is_archived}
				{new_folder_id}
				{new_sub_folder_id}
				{new_folder_name}
				{sub_folder_names}
				disable_dropdown={true}
				form_type="edit_folder_details"
				{is_edit_parent_folder_clicked}
				{is_edit_subfolder_clicked}
				on:edi-folder-details-form:handle_create={handleCreate}
				on:edi-folder-details-form:handle_cancel={handleCancel}
			/>
		</div>
	{/if}

	{#if sub_folders?.length > 0 && showSubFoldersList}
		<div class="sub-folder-title">
			<h4>Subfolders <span>{sub_folders?.length}</span></h4>
		</div>
		<div class="sub-folders">
			{#each sub_folders as folder}
				<div class="sub-folder">
					<div class="folder-icon">
						<edi-icon-picker icon_name={folder?.folderIcon} />
					</div>
					<div>
						<h5>
							{#if folder?.folderName.length > 15}
								{folder?.folderName.substring(0, 15) + '...'}
							{:else}
								{folder?.folderName}
							{/if}
						</h5>
						<span>
							Assessments: {folder?.assessments?.selectedAssessments
								? folder?.assessments?.selectedAssessments?.length
								: 0}
						</span>
					</div>
					<div class="btns">
						<button class="edit" on:click={() => editSubFolder(folder)}><edi-svg-pencil /></button>
						{#if !folder?.assessments?.selectedAssessments || folder?.assessments?.selectedAssessments?.length === 0}
							<button class="delete" on:click={() => deleteSubFolder(folder)}><edi-svg-trash /> </button>
						{/if}
					</div>
				</div>
			{/each}
		</div>
	{/if}

	<div class="section2">
		<div class="add-assignments-box">
			<h4>All Assessments <span>{filteredAllAssessments()?.length}</span></h4>
			<div class="form-group search">
				<input
					type="text"
					placeholder="Search"
					bind:value={searchKeyAllAssessments}
					on:input={() => filteredAllAssessments()}
				/>
				<span>
					<edi-svg-search />
				</span>
			</div>

			<div class="assignments-list">
				<section id="container1" bind:this={container1}>
					{#if all_assessments_temp?.length > 0}
						{#each filteredAllAssessments() as assessment}
							<div id={assessment?.assessmentName}>
								{assessment?.assessmentName}
								<button on:click={() => addAssessment(assessment?.assessmentName)}>
									<edi-svg-add />
								</button>
							</div>
						{/each}
					{/if}
				</section>
			</div>
		</div>

		<div class="add-assignments-box">
			<h4>Selected Assessments <span>{filteredSelectedAssessments()?.length}</span></h4>
			<div class="form-group search">
				<input
					type="text"
					placeholder="Search"
					bind:value={searchKeySelectedAssessments}
					on:input={() => filteredSelectedAssessments()}
				/>
				<span>
					<edi-svg-search />
				</span>
			</div>
			<div class="assignments-list">
				<section id="container2" bind:this={container2}>
					{#if selected_assessments_temp?.length == 0}
						<div class="empty">
							<p>
								<strong>Add assessments to the folder.</strong>
								You can drag and drop assessments from all assessments.
							</p>
						</div>
					{:else}
						{#each filteredSelectedAssessments() as assessment}
							<div id={assessment?.assessmentName}>
								{assessment?.assessmentName}
								<button on:click={() => removeAssessment(assessment?.assessmentName)}>
									<edi-svg-remove />
								</button>
							</div>
						{/each}
					{/if}
				</section>
			</div>
		</div>
	</div>

	<div class="section3">
		<button class="back-btn" on:click={() => handleBack()}>Back</button>
		<button
			class={!disableSave ? 'save-btn' : 'save-btn-disabled'}
			disabled={disableSave}
			on:click={() => handleSave()}
		>
			Save
		</button>
	</div>
</div>

<style lang="scss">
	* {
		box-sizing: border-box;
	}
	.section1 {
		border: 1px solid #e2e9f3;
		border-radius: 12px;
		padding: 20px;
		margin-bottom: 20px;
		display: flex;
		flex-wrap: wrap;
		gap: 15px;
		justify-content: space-between;
	}
	.section1 button {
		display: inline-flex;
		align-items: center;
		background: none;
		border: none;
		outline: none;
		cursor: pointer;
		padding: 0;
		font-weight: 600;
		color: #317fb4;
	}
	.section1 button:last-child {
		color: #435765;
	}
	.section1 button img {
		width: 16px;
		margin-right: 7px;
	}
	.section2 {
		display: flex;
		gap: 20px;
	}
	.add-assignments-box {
		width: 50%;
		border: 1px solid #e2e9f3;
		border-radius: 12px;
		padding: 20px;
		height: calc(100vh - 320px);
		display: flex;
		flex-direction: column;
	}
	.add-assignments-box h4 {
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		color: #435765;
		margin: 0 0 15px 0;
	}
	.add-assignments-box h4 span {
		background: #e2f3ff;
		color: #317fb4;
		padding: 5px;
		border-radius: 20px;
		font-size: 13px;
		display: inline-block;
		min-width: 30px;
		margin-left: 7px;
		text-align: center;
	}
	.form-group.search {
		position: relative;
	}
	.form-group.search span {
		position: absolute;
		top: 15px;
		left: 10px;
		width: 16px;
	}
	.form-group.search input {
		padding-left: 37px;
	}

	.form-group input {
		width: 100%;
		height: 50px;
		padding: 12px;
		background: #eff4f9;
		border: none;
		border-radius: 7px;
		outline: none;
	}
	.assignments-list {
		flex: 1;
		overflow: auto;
	}
	.assignments-list div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px dashed #e2e9f3;
		padding: 10px 0;
		font-weight: 400;
		color: #8e9396;
		width: 100%;
	}
	.assignments-list div button {
		margin-left: 15px;
		margin-right: 10px;
		width: 30px;
		height: 30px;
		padding: 0;
		background: none;
		outline: none;
		border: none;
		opacity: 0;
		flex-shrink: 0;
		cursor: pointer;
		-webkit-transition: 0.4s;
		-moz-transition: 0.4s;
		transition: 0.4s;
	}
	.assignments-list div button img {
		width: 100%;
	}
	.assignments-list div:hover button {
		opacity: 1;
	}

	.assignments-list div.empty {
		flex-direction: column;
		justify-content: center;
		text-align: center;
		border: none;
		height: 100%;
	}
	.assignments-list div.empty img {
		width: 60px;
		margin-bottom: 15px;
	}
	.assignments-list div.empty p {
		margin: 0;
		line-height: 28px;
	}
	.assignments-list div.empty p strong {
		display: block;
		font-weight: 600;
	}

	/* Drag and drop container */
	section {
		height: 100%;
	}

	.section3 {
		margin-top: 20px;
		display: flex;
		justify-content: flex-end;
		gap: 20px;
		flex-wrap: wrap;
	}

	.back-btn,
	.save-btn {
		border-radius: 7px;
		padding: 0 25px;
		height: 45px;
		border: none;
		outline: none;
		cursor: pointer;
	}
	.back-btn {
		background: #f2f2f2;
		color: #767676;
	}

	.save-btn {
		background: #317fb4;
		color: #fff;
	}

	.save-btn-disabled {
		border-radius: 7px;
		padding: 0 25px;
		height: 45px;
		border: none;
		outline: none;
		background: #f2f2f2;
	}

	// Sub folders

	.sub-folder-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 15px;
	}

	.sub-folder-title h4 {
		color: #263b54;
		margin: 0;
	}
	.sub-folder-title h4 span {
		background: #e2f3ff;
		color: #317fb4;
		padding: 5px;
		border-radius: 20px;
		font-size: 13px;
		display: inline-block;
		min-width: 30px;
		margin-left: 7px;
		text-align: center;
	}
	.sub-folder-title .form-group {
		width: 300px;
	}

	.sub-folders {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 15px;
		margin-bottom: 15px;
	}
	.sub-folder {
		display: flex;
		align-items: center;
		border: 1px solid #e2e9f3;
		border-radius: 7px;
		padding: 12px;
		position: relative;
		overflow: hidden;
		-webkit-transition: 0.4s;
		-moz-transition: 0.4s;
		transition: 0.4s;
	}
	.sub-folder:hover {
		border-color: #317fb4;
	}
	.sub-folder .folder-icon {
		background: rgba(226, 233, 243, 0.6);
		border-radius: 5px;
		padding: 12px;
		width: 45px;
		height: 45px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 15px;
		flex-shrink: 0;
	}
	.sub-folder .folder-icon img {
		width: 100%;
	}
	.sub-folder h5 {
		font-weight: 500;
		margin: 0 0 5px 0;
	}
	.sub-folder p {
		font-size: 13px;
		line-height: 20px;
		color: #969899;
		margin: 0;
	}
	.sub-folder .btns {
		display: flex;
		gap: 10px;
		position: absolute;
		height: 100%;
		align-items: center;
		top: 0;
		opacity: 0;
		right: -100%;
		-webkit-transition: 0.4s;
		-moz-transition: 0.4s;
		transition: 0.4s;
	}
	.sub-folder:hover .btns {
		opacity: 1;
		right: 12px;
	}
	.sub-folder .btns button {
		width: 35px;
		height: 35px;
		padding: 10px;
		border-radius: 35px;
		border: none;
		cursor: pointer;
	}
	.sub-folder .btns button img {
		width: 100%;
	}
	.sub-folder .btns button.edit {
		background: rgb(79 184 255 / 30%);
		color: #4fb8ff;
	}
	.sub-folder .btns button.delete {
		background: rgb(255 102 102 / 30%);
		color: #ff6666;
	}

	@media (max-width: 768px) {
		.section2 {
			flex-direction: column;
		}
		.add-assignments-box {
			width: 100%;
			height: auto;
			max-height: 400px;
		}

		.sub-folder-title {
			flex-direction: column;
		}
		.sub-folder-title {
			gap: 15px;
			align-items: flex-start;
		}

		.sub-folder-title .form-group {
			width: 100%;
		}
		.sub-folders {
			grid-template-columns: repeat(1, 1fr);
		}
	}
</style>
