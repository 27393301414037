<svelte:options tag="edi-stacked-chart" />

<script lang="ts">
	import { Chart, registerables } from 'chart.js'
	import { onMount } from 'svelte'
	Chart.register(...registerables)

	export let items: any

	let canvas: HTMLCanvasElement
	let myChart: any
	export let charttype: string
	$: createChart(canvas, items, charttype)
	const createChart = (node: HTMLCanvasElement, items, charttype) => {
		if (myChart) {
			myChart.destroy()
		}
		if (items) {
			myChart = new Chart(node, {
				type: charttype,
				data: items,

				options: {
					parsing: {
						xAxisKey: 'months',
						yAxisKey: 'products.sales',
					},
					scales: {
						x: {
							stacked: true,
						},
						y: {
							stacked: true,
						},
					},

					onClick: (e) => {
						const activePoints = myChart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true)
						if (activePoints.length) {
							const firstPoint = activePoints[0]

							const value = myChart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index]

							window.open(value.products.link)
						}
					},
				},
			})
		}
	}

	onMount(() => {
		createChart(canvas, items, charttype)
	})
</script>

<canvas bind:this={canvas} width="400px" height="400px" />
