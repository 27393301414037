<svelte:options tag="edi-pie-chart" />

<script lang="ts">
	import { Chart, registerables } from 'chart.js'
	import ChartDataLabels from 'chartjs-plugin-datalabels'
	import { onMount } from 'svelte'
	Chart.register(...registerables)

	export let items: any
	let canvas: HTMLCanvasElement
	let myChart: any
	export let charttype: string

	$: createChart(canvas, items, charttype)
	const createChart = (node: HTMLCanvasElement, items, charttype) => {
		if (myChart) {
			myChart.destroy()
		}
		if (items) {
			myChart = new Chart(node, {
				type: charttype,
				data: items,

				options: {
					parsing: {
						key: 'products.sales',
					},
					plugins: {
						datalabels: {
							formatter: (value, context) => {
								let datavalue = value.percentage

								return datavalue
							},
						},
					},

					onClick: (e) => {
						const activePoints = myChart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true)
						if (activePoints.length) {
							const firstPoint = activePoints[0]
							const value = myChart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index]
							window.open(value.products.link)
						}
					},
				},
				plugins: [ChartDataLabels],
			})
		}
	}

	onMount(() => {
		createChart(canvas, items, charttype)
	})
</script>

<canvas bind:this={canvas} width="400px" height="400px" />

<style lang="scss">
	@import '../../styles/variables';
	@import '../../styles/mixins';
	@import '@material/elevation/mdc-elevation';

	:host {
		--edi-account-select-background-color: var(--edi-dark);
	}
	select {
		border-radius: 6px;
		height: 25px;
	}
	.select {
		text-align: center;
		margin-top: 1em;
		padding-bottom: 0.5em;
	}
</style>
