<svelte:options tag="edi-custom-dashboard-dropdown" />

<script lang="ts">
	import { get_current_component } from 'svelte/internal'
	import dispatch from '../../common/dispatch'

	const component = get_current_component()

	export let customdashboard = []

	let selected: any = customdashboard.map((x) => x.dropdown.filter((a) => a.selected))

	// Fields to hold values of custom dashboard dropdown details for the api side
	let label: any
	let selectedDropdown: any
	let dropdownID: any
	let ddlList: any[] = []

	const handlechange = () => {
		ddlList = []

		selected?.forEach((element, index) => {
			label = customdashboard[index]?.label
			selectedDropdown = element?.value
			dropdownID = element?.id
			ddlList = [...ddlList, { label, selectedDropdown, dropdownID }]
		})

		dispatch(component, 'edi-custom-dashboard-dropdown:customdashboardchange', {
			ddlList,
		})
	}
</script>

{#if customdashboard}
	<div class="select">
		{#each customdashboard as item, index}
			<div>
				<span>{item.label}:</span>
				<select bind:value={selected[index]} on:change={() => handlechange()}>
					{#each item.dropdown as dropdown}
						<option selected={dropdown?.selected} value={dropdown}>
							{dropdown.value}
						</option>
					{/each}
				</select>
			</div>
		{/each}
	</div>
{/if}

<style lang="scss">
	select {
		width: 20%;
		border-radius: 5px;
		border-color: #f5f5f5;

		background: #f5f5f5;
		color: #818181;

		font-family: 'Roboto';
		font-style: normal;
	}
	.select {
		text-align: left;

		font-family: 'Roboto';
		font-style: normal;
		font-weight: 500;
		line-height: 19px;

		color: #818181;
	}
</style>
